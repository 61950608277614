/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { PROFILE_PATH } from 'src/constants/routePaths';
import { useScrollToElementByHash } from 'src/hooks/Path';
import { AccountType, Brand, Country } from '../../../types';
import {
  FAQComponentItem,
  FAQComponentItemChildList,
  FAQComponentItemImage,
  FAQComponentItemList,
  FAQComponentItemParagraph,
  FAQComponentItemParagraphWithLinks,
  FAQComponentItemParagraphWithTitle,
  FAQComponentItemSubTitle,
  FAQComponentItemTextWithLinks,
  FAQComponentProps,
  FAQComponentSection,
  LinkType,
  resourceKey,
} from './FAQComponent';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
// @ts-ignore
import achAudiCanada from '../../../assets/pdfs/faq/ACHAgreement-Audi-Canada.pdf';

// @ts-ignore
import achAudiCanadaFr from '../../../assets/pdfs/faq/ACHAgreement-Audi-Canada-Fr.pdf';
// @ts-ignore
import achVWCanadaFr from '../../../assets/pdfs/faq/ACHAgreement-VW-Canada-Fr.pdf';

// @ts-ignore
import achVWCanada from '../../../assets/pdfs/faq/ACHAgreement-VW-Canada.pdf';
// @ts-ignore
import inspectionReportAudiCanada from '../../../assets/pdfs/faq/Inspection-Report-Audi-Canada.pdf';
// @ts-ignore
import inspectionReportAudiCanadaFr from '../../../assets/pdfs/faq/Inspection-Report-Audi-Canada-Fr.pdf';

// @ts-ignore
import inspectionReportVWCanada from '../../../assets/pdfs/faq/Inspection-Report-VW-Canada.pdf';
// @ts-ignore
import inspectionReportVWCanadaFr from '../../../assets/pdfs/faq/Inspection-Report-VW-Canada-Fr.pdf';

// @ts-ignore
import earlyTerminationInvoiceCalculation from '../../../assets/images/faq/early_termination_invoice_calculation.png';
import earlyTerminationInvoiceCalculation_fr from '../../../assets/images/faq/early_termination_invoice_calculation_fr.png';
import globalConstants from '../../../constants/globalConstants';

const FAQComponentCanada: React.FC<FAQComponentProps> = ({ brand, accountType }: FAQComponentProps) => {
  // NOSONAR
  const storedProps = localStorage.getItem('appProps');
  const appProps = storedProps ? JSON.parse(storedProps) : {};
  const styleBrand = brand === Brand.AUDI ? Brand.AUDI : Brand.VW;
  const languageCode = appProps?.languageCode;
  let lng = (languageCode ?? globalConstants.EN_CA) as string;
  lng = lng.replace('-', '');
  const tncPortalUrl = `${process.env.REACT_APP_TNC_PORTAL_URL}/tnc/can/${styleBrand.toLowerCase()}?lng=${lng}`;
  const findDealerAudi = 'https://www.audi.ca/ca/web/en/dealer-search.html?q=&partner_id=';
  const findDealerVW =
    'https://www.vw.ca/app/dccsearch/vw-ca/en/Volkswagen%20Dealer%20Search/+/54.44073635000004/-93.75185435/4/+/+/+/+';

  useScrollToElementByHash(brand);

  return (
    <div className='c-faq' role='main'>
      <h1 className='header'>{i18n.t(rk.FAQ_TITLE_CANADA)}</h1>
      <div>
        <FAQComponentSection value={i18n.t(rk.FAQ_MOVING_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_MOVING_QUESTION1_CANADA)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_MOVING_ANSWER1', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_MOVING_ANSWER1_LINK_CANADA),
                  value: PROFILE_PATH,
                  type: LinkType.NavLink,
                },
              ]}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_MOVING_QUESTION2_CANADA)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_MOVING_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_MOVING_ANSWER2_LINK_CANADA),
                  value: PROFILE_PATH,
                  type: LinkType.NavLink,
                },
              ]}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_MOVING_ANSWER2_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_MOVING_ANSWER2_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_MOVING_ANSWER2_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_MOVING_ANSWER2_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_PAYMENTOPTIONS_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION1_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3', styleBrand, Country.Canada)}
            />
            {/** <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER1_LINK1_CANADA),
                  type: LinkType.Nav,
                },
              ]}
            />* */}
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION2_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION3_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK1_CANADA),
                  value:
                    languageCode === 'fr-CA'
                      ? brand === Brand.AUDI
                        ? achAudiCanadaFr
                        : achVWCanadaFr
                      : brand === Brand.AUDI
                      ? achAudiCanada
                      : achVWCanada,
                  type: LinkType.Pdf,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH3', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH4', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK2_CANADA),
                  value: 'https://get.adobe.com/es/reader/',
                  type: LinkType.Link,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH5', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_CONTENT', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK3_CANADA),
                  value: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK3_CANADA),
                  type: LinkType.Email,
                },
              ]}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH9', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION4_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION5_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH2', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LINK1_CANADA),
                  value:
                    languageCode === 'fr-CA'
                      ? brand === Brand.AUDI
                        ? achAudiCanadaFr
                        : achVWCanadaFr
                      : brand === Brand.AUDI
                      ? achAudiCanada
                      : achVWCanada,
                  type: LinkType.Pdf,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH3', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH4', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LINK2_CANADA),
                  value: 'https://get.adobe.com/es/reader/',
                  type: LinkType.Link,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH5', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_CONTENT', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK3_CANADA),
                  value: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK3_CANADA),
                  type: LinkType.Email,
                },
              ]}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH9', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_USAGEINTHEUS_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_USAGEINTHEUS_QUESTION1_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_USAGEINTHEUS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_PAYOFFQUOTES_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYOFFQUOTES_QUESTION1_CANADA)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYOFFQUOTES_ANSWER1_LINK1_CANADA),
                  type: LinkType.Nav,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH5', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYOFFQUOTES_ANSWER1_LINK2_CANADA),
                  value: PROFILE_PATH,
                  type: LinkType.Nav,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH6', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH7', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH8', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYOFFQUOTES_QUESTION2_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_ACCIDENT_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_ACCIDENT_QUESTION1_CANADA)}>
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemChildList
              items={[
                resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM1', styleBrand, Country.Canada),
                resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM2', styleBrand, Country.Canada),
                <FAQComponentItemTextWithLinks
                  value={resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM3', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_ACCIDENT_ANSWER1_LINK1_CANADA),
                      type: LinkType.Link,
                    },
                  ]}
                />,
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH3', styleBrand, Country.Canada)}
            />
            <FAQComponentItemList
              items={[
                resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM4', styleBrand, Country.Canada),
                resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM5', styleBrand, Country.Canada),
                resourceKey('FAQ_ACCIDENT_ANSWER1_LISTITEM6', styleBrand, Country.Canada),
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_NAMECHANGE_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_NAMECHANGE_QUESTION1_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(
                    brand === Brand.AUDI
                      ? rk.FAQ_NAMECHANGE_ANSWER1_LINK1_AUDI_CANADA
                      : rk.FAQ_NAMECHANGE_ANSWER1_LINK1_VW_CANADA
                  ),
                  value: i18n.t(
                    brand === Brand.AUDI
                      ? rk.FAQ_NAMECHANGE_ANSWER1_LINK1_AUDI_CANADA
                      : rk.FAQ_NAMECHANGE_ANSWER1_LINK1_VW_CANADA
                  ),
                  type: LinkType.Email,
                },
              ]}
            />
            <FAQComponentItemSubTitle
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_TITLE', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_CONTENT', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_INSURANCEREQUIREMENTS_CANADA)}>
          <FAQComponentItem
            value={i18n.t(
              brand === Brand.AUDI
                ? rk.FAQ_INSURANCEREQUIREMENTS_QUESTION1_AUDI_CANADA
                : rk.FAQ_INSURANCEREQUIREMENTS_QUESTION1_VW_CANADA
            )}
          >
            {languageCode === 'fr-CA' ? (
              <FAQComponentItemParagraph
                value={resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH_FR', styleBrand, Country.Canada)}
              />
            ) : null}
            <FAQComponentItemList
              items={[
                resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM1', styleBrand, Country.Canada),
                resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM2', styleBrand, Country.Canada),
                resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM3', styleBrand, Country.Canada),
                resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM4', styleBrand, Country.Canada),
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_WEBSITESECURITY_CANADA)}>
          <FAQComponentItem
            value={i18n.t(
              brand === Brand.AUDI
                ? rk.FAQ_WEBSITESECURITY_QUESTION1_AUDI_CANADA
                : rk.FAQ_WEBSITESECURITY_QUESTION1_VW_CANADA
            )}
          >
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_WEBSITESECURITY_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
              links={[
                {
                  key: i18n.t(rk.FAQ_WEBSITESECURITY_ANSWER1_LINK1_CANADA),
                  value: tncPortalUrl,
                  type: LinkType.Link,
                },
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_WEBSITEPROBLEMS_CANADA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_WEBSITEPROBLEMS_QUESTION1_CANADA)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_WEBSITEPROBLEMS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        {accountType === AccountType.Lease && (
          <>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_GENERAL_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION1_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH3', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH4', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH5', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH6', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH7', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH8', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH9', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH10', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION3_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION4_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER4_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION5_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER5_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_GENERAL_ANSWER5_LINK1_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? inspectionReportAudiCanadaFr
                            : inspectionReportVWCanadaFr
                          : brand === Brand.AUDI
                          ? inspectionReportAudiCanada
                          : inspectionReportVWCanada,
                      type: LinkType.Pdf,
                    },
                    {
                      key: i18n.t(rk.FAQ_LEASE_GENERAL_ANSWER5_LINK2_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? 'https://www.audi.ca/ca/web/en.html?container=page'
                            : 'https://www.vw.ca/fr.html'
                          : brand === Brand.AUDI
                          ? 'https://www.audi.ca/ca/web/en.html?container=page'
                          : 'https://www.vw.ca/en/shopping_tools/volkswagen_finance/wear_tear.html',
                      type: LinkType.Link,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION6_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION7_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION8_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH2', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION9_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER9_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION10_CANADA)}>
                {languageCode === 'fr-CA' ? (
                  <FAQComponentItemParagraph
                    value={resourceKey('FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH_FR', styleBrand, Country.Canada)}
                  />
                ) : null}
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION11_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER11_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_TURNIN_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNIN_QUESTION1_CANADA)}>
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                {languageCode === 'fr-CA' ? (
                  <FAQComponentItemParagraphWithTitle
                    value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                  >
                    <FAQComponentItemParagraphWithLinks
                      value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                      links={[
                        {
                          key: i18n.t(rk.FAQ_LEASE_TURNIN_ANSWER1_LINK1_CANADA),
                          value:
                            languageCode === 'fr-CA'
                              ? brand === Brand.AUDI
                                ? 'https://www.audi.ca/ca/web/en.html?container=page'
                                : 'https://www.vw.ca/fr.html'
                              : brand === Brand.AUDI
                              ? 'https://www.audi.ca/ca/web/en.html?container=page'
                              : 'https://www.vw.ca/en/shopping_tools/volkswagen_finance/wear_tear.html',
                          type: LinkType.Pdf,
                        },
                      ]}
                    />
                  </FAQComponentItemParagraphWithTitle>
                ) : (
                  <FAQComponentItemParagraphWithTitle
                    value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                  >
                    {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                  </FAQComponentItemParagraphWithTitle>
                )}

                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNIN_QUESTION2_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_TURNIN_ANSWER2_LINK1_CANADA),
                      value: brand === Brand.AUDI ? findDealerAudi : findDealerVW,
                      type: LinkType.Link,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_LEASE_TURNIN_QUESTION3_AUDI_CANADA
                    : rk.FAQ_LEASE_TURNIN_QUESTION3_VW_CANADA
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_EARLYTERMINATION_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_EARLYTERMINATION_QUESTION1_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_EARLYTERMINATION_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_EARLYTERMINATION_QUESTION3_CANADA)}>
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemImage
                  value={
                    languageCode === 'fr-CA'
                      ? earlyTerminationInvoiceCalculation_fr
                      : earlyTerminationInvoiceCalculation
                  }
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH3', styleBrand, Country.Canada)}
                />
                {languageCode === 'fr-CA' && brand !== Brand.AUDI ? (
                  <FAQComponentItemList
                    items={[
                      resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH4', styleBrand, Country.Canada),
                      resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH5', styleBrand, Country.Canada),
                      resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH6', styleBrand, Country.Canada),
                      resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH7', styleBrand, Country.Canada),
                    ]}
                  />
                ) : (
                  <>
                    <FAQComponentItemParagraph
                      value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH4', styleBrand, Country.Canada)}
                    />
                    <FAQComponentItemParagraph
                      value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH5', styleBrand, Country.Canada)}
                    />
                    <FAQComponentItemParagraph
                      value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH6', styleBrand, Country.Canada)}
                    />
                    <FAQComponentItemParagraph
                      value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH7', styleBrand, Country.Canada)}
                    />
                  </>
                )}
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_PURCHASING_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION1_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION3_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION4_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER4_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION1_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK1_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? 'https://www.audi.ca/ca/web/en.html?container=page'
                            : 'https://www.vw.ca/fr.html'
                          : brand === Brand.AUDI
                          ? 'https://www.audi.ca/ca/web/en.html?container=page'
                          : 'https://www.vw.ca/en/shopping_tools/volkswagen_finance/wear_tear.html',
                      type: LinkType.Link,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey(
                    'FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                >
                  {resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION3_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION4_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER4_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION5_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER5_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER5_LINK1_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? 'https://www.audi.ca/ca/web/en.html?container=page'
                            : 'https://www.vw.ca/fr.html'
                          : brand === Brand.AUDI
                          ? 'https://www.audi.ca/ca/web/en.html?container=page'
                          : 'https://www.vw.ca/en/shopping_tools/volkswagen_finance/wear_tear.html',
                      type: LinkType.Link,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION6_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER6_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER6_LINK1_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? inspectionReportAudiCanadaFr
                            : inspectionReportVWCanadaFr
                          : brand === Brand.AUDI
                          ? inspectionReportAudiCanada
                          : inspectionReportVWCanada,
                      type: LinkType.Pdf,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION7_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_VEHICLEREPAIRS_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEREPAIRS_QUESTION1_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_LINK1_CANADA),
                      value: brand === Brand.AUDI ? findDealerAudi : findDealerVW,
                      type: LinkType.Link,
                    },
                  ]}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION1_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH1',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH2',
                    styleBrand,
                    Country.Canada
                  )}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LINK1_CANADA),
                      value:
                        languageCode === 'fr-CA'
                          ? brand === Brand.AUDI
                            ? 'https://www.audi.ca/ca/web/en.html?container=page'
                            : 'https://www.vw.ca/fr.html'
                          : brand === Brand.AUDI
                          ? 'https://www.audi.ca/ca/web/en.html?container=page'
                          : 'https://www.vw.ca/en/shopping_tools/volkswagen_finance/wear_tear.html',
                      type: LinkType.Link,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH3',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemList
                  items={[
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM1', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM2', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM3', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM4', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM5', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM6', styleBrand, Country.Canada),
                    resourceKey('FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM7', styleBrand, Country.Canada),
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                >
                  {resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemList
                  items={
                    languageCode === 'fr-CA'
                      ? [
                          resourceKey(
                            'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM8',
                            styleBrand,
                            Country.Canada
                          ),
                          resourceKey(
                            'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM9',
                            styleBrand,
                            Country.Canada
                          ),
                          resourceKey(
                            'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM10',
                            styleBrand,
                            Country.Canada
                          ),
                        ]
                      : [
                          resourceKey(
                            'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM8',
                            styleBrand,
                            Country.Canada
                          ),
                          resourceKey(
                            'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM9',
                            styleBrand,
                            Country.Canada
                          ),
                        ]
                  }
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH5',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH8',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH1',
                    styleBrand,
                    Country.Canada
                  )}
                />
                {languageCode === 'fr-CA' && brand !== Brand.AUDI ? null : (
                  <FAQComponentItemSubTitle
                    value={resourceKey(
                      'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_TITLE',
                      styleBrand,
                      Country.Canada
                    )}
                  />
                )}

                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH3',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION3_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER3_PARAGRAPH1',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION4_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH1',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_TITLE',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_CONTENT',
                    styleBrand,
                    Country.Canada
                  )}
                />
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH4',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION5_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey(
                    'FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER5_PARAGRAPH1',
                    styleBrand,
                    Country.Canada
                  )}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_SECURITYDEPOSIT_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_SECURITYDEPOSIT_QUESTION1_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_SECURITYDEPOSIT_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_SECURITYDEPOSIT_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_SECURITYDEPOSIT_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
          </>
        )}
        {accountType === AccountType.Balloon && (
          <>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_GENERAL_CANADA)}>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_GENERAL_QUESTION1_AUDI_CANADA
                    : rk.FAQ_BALLOON_GENERAL_QUESTION1_VW_CANADA
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_TITLE', styleBrand, Country.Canada)}
                >
                  {resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_CONTENT', styleBrand, Country.Canada)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH5', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_GENERAL_QUESTION2_AUDI_CANADA
                    : rk.FAQ_BALLOON_GENERAL_QUESTION2_VW_CANADA
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_GENERAL_QUESTION3_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_PAYOFF_CANADA)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PAYOFF_QUESTION1_CANADA)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH1', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_PAYOFF_QUESTION1_LINK1_CANADA),
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH2', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH3', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH4', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH5', styleBrand, Country.Canada)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_PAYOFF_QUESTION1_LINK2_CANADA),
                      value: PROFILE_PATH,
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH6', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH7', styleBrand, Country.Canada)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH8', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PAYOFF_QUESTION2_CANADA)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PAYOFF_ANSWER2_PARAGRAPH1', styleBrand, Country.Canada)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
          </>
        )}
      </div>
    </div>
  );
};

export default FAQComponentCanada;
