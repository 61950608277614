/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
// TODO: Verify eslint error "Curly braces are unnecessary here"
/* eslint-disable react/jsx-curly-brace-presence */
import React, { ReactElement, ReactNode } from 'react';
import { createGetChild } from '../../../../utility/react-utils';
import './UploadFileInput.scss';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';

interface IUploadFileInput {
  id: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  accept?: string;
  required?: boolean;
  children?: ReactNode;
}

const UploadFileTitle = ({ children }: { children: ReactNode }) => {
  return <div className='c-upload__description-text  upload-text-container'>{children}</div>;
};

const UploadFileDescription = ({ children, status = '', ...props }: { children: ReactNode; status?: string }) => {
  return (
    <div {...props} className={`c-upload__description-supplementary-text  upload-text-container ${status}`}>
      {children}
    </div>
  );
};

const UploadFileButton = ({ children }: { children: ReactNode }) => {
  if (!(children as ReactElement)?.type) return <>{children}</>;

  return (
    <span className='c-upload__actions upload-text-container'>
      {React.cloneElement(children as ReactElement, {
        className: `${(children as ReactElement)?.props?.className ?? ''} c-upload__button`,
      })}
    </span>
  );
};

const UploadFileTooltip = ({ children, ...props }: { children: ReactNode }) => {
  if (!(children as ReactElement)?.type) return <>{children}</>;

  return <>{React.cloneElement(children as ReactElement, props)}</>;
};

const UploadFileIcon = ({ children, hidden }: { children?: ReactNode; hidden?: boolean }) => {
  if (hidden) return <></>;
  if (!(children as ReactElement)?.type) return <>{children}</>;

  return (
    <>
      {React.cloneElement(children as ReactElement, {
        className: `${(children as ReactElement)?.props?.className ?? ''} u-mb-xsmall c-upload-icon`,
        'data-testid': 'upload-file-icon',
      })}
    </>
  );
};

// we're using a props similar as html attributes.
const UploadFileInput = ({
  id,
  disabled,
  onChange,
  loading,
  accept,
  required,
  children,
}: IUploadFileInput): ReactElement => {
  const getChildWithType = createGetChild(children);
  const title = getChildWithType(UploadFileTitle);
  const description = getChildWithType(UploadFileDescription);
  const button = getChildWithType(UploadFileButton);
  const icon = getChildWithType(UploadFileIcon);
  const tooltip = getChildWithType(UploadFileTooltip);

  const className = `c-upload ${loading ? 'is-loading' : ''}`;

  return (
    <label className={className} htmlFor={id}>
      {disabled === true ? (
        ''
      ) : (
        <input
          id={id}
          type='file'
          aria-label='file-upload-input'
          disabled={disabled}
          name={id}
          className='c-upload__input'
          onChange={disabled || loading ? () => undefined : onChange}
          required={required}
          // multiple
          accept={accept}
        />
      )}
      {tooltip ? (
        <div className='c-upload_container--flex'>
          <div className='c-upload_container--flex_end c-upload_container-split_7-12  c-upload_container_flex_column'>
            <div style={{ width: 'fit-content' }} className='upload-container-section'>
              <div className='c-upload__description upload-text-container'>
                {icon ?? <i className='c-upload__icon' />}
                {title}
                {description}
              </div>
              {button}
            </div>
          </div>
          <div className='c-upload_container-split_half c-upload_container-split_5-12 c-upload_container--flex_end'>
            {tooltip}
          </div>
        </div>
      ) : (
        <>
          <div className={disabled ? '' : 'success-upload-container-section'}>
            <div className='c-upload__description upload-text-container'>
              {icon ?? <i className='c-upload__icon' />}
              {title}
              {description}
            </div>
            {button}
          </div>
        </>
      )}
      {disabled ? '' : <p className='tooltip-description'>{i18n.t(rk.UPLOAD_TOOLTIP_CONTENT)}</p>}
      <span className='c-upload__loader'>
        <div className='c-spinner  c-spinner--center' aria-live='polite' aria-busy='true'>
          <div className='c-spinner__loader'>
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
            <span className='c-spinner__element' />
          </div>
        </div>

        <span className='c-upload__loader-text'>{i18n.t(rk.UPLOAD_FILE_PROCESSING_TEXT)}</span>
      </span>
    </label>
  );
};

UploadFileInput.Title = UploadFileTitle;
UploadFileInput.Description = UploadFileDescription;
UploadFileInput.Button = UploadFileButton;
UploadFileInput.Icon = UploadFileIcon;
UploadFileInput.Tooltip = UploadFileTooltip;

export default UploadFileInput;
