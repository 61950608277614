import React, { useEffect, useState } from 'react';
import { getDocumentListService } from 'src/services/message-center/get-documents';
import globalConstants from 'src/constants/globalConstants';
import { useAuthContext } from './AuthContext';
import { useLoader } from './LoadingContext';
import {
  IFilters,
  IDocumentContext,
  IStatements,
  ILoanAgreements,
  IAutopayLetters,
  IDocument,
} from './DocCenterContext.interface';

export const DocCenterContext = React.createContext<IDocumentContext | null>(null);

export const DocProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [statements, setStatements] = useState<Array<IStatements>>([]);
  const [loanAgreements, setLoanAgreements] = useState<Array<ILoanAgreements>>([]);
  const [autopayLetters, setAutopayLetters] = useState<Array<IAutopayLetters>>([]);
  const [filteredAutopayLetters, setFilteredAutopayLetters] = useState<Array<IAutopayLetters>>([]);
  const [filteredLoanAgreements, setFilteredLoanAgreements] = useState<Array<ILoanAgreements>>([]);
  const [error, setError] = useState<boolean>(false);
  const [filteredStatements, setFilteredStatements] = useState<Array<IStatements>>([]);
  const [yearIndex, setYearIndex] = useState<Array<number>>([]);
  const [filters, setFilters] = useState<IFilters>({
    year: 'All',
    documentType: 'All',
  });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  useEffect(() => {
    switch (filters.documentType) {
      case globalConstants.DOCUMENT_MAPPING.Statements:
        console.info('User updated filter to view statements');
        if (filters.year !== 'All') {
          const filtered = statements.filter(
            (statement: IStatements) => new Date(statement.InvoiceDate).getFullYear().toString() === filters.year
          );
          setFilteredStatements(filtered);
        } else {
          setFilteredStatements(statements);
        }
        setFilteredLoanAgreements([]);
        setFilteredAutopayLetters([]);
        break;
      case globalConstants.DOCUMENT_MAPPING.Loan:
        console.info('User updated filter to view lease/finance agreement');
        if (filters.year !== 'All') {
          const filtered = loanAgreements.filter(
            (loanAgreement: ILoanAgreements) =>
              new Date(loanAgreement.CreateDate).getFullYear().toString() === filters.year
          );
          setFilteredLoanAgreements(filtered);
        } else {
          setFilteredLoanAgreements(loanAgreements);
        }
        setFilteredStatements([]);
        setFilteredAutopayLetters([]);
        break;
      case globalConstants.DOCUMENT_MAPPING.AutopayLetter:
        console.info('User updated filter to view autopay enrollment letter');
        if (filters.year !== 'All') {
          const filtered = autopayLetters.filter(
            (autopayLetter: IAutopayLetters) =>
              new Date(autopayLetter.CreateDate).getFullYear().toString() === filters.year
          );
          setFilteredAutopayLetters(filtered);
        } else {
          setFilteredAutopayLetters(autopayLetters);
        }
        setFilteredStatements([]);
        setFilteredLoanAgreements([]);
        break;
      case 'All':
        console.info('User updated filter to view all documents');
        if (filters.year !== 'All') {
          if (loanAgreements) {
            const filtered = loanAgreements.filter(
              (loanAgreement: ILoanAgreements) =>
                new Date(loanAgreement.CreateDate).getFullYear().toString() === filters.year
            );
            setFilteredLoanAgreements(filtered);
          }
          if (statements) {
            const filtered = statements.filter(
              (statement: IStatements) => new Date(statement.InvoiceDate).getFullYear().toString() === filters.year
            );
            setFilteredStatements(filtered);
          }
          if (autopayLetters) {
            const filtered = autopayLetters.filter(
              (autopayLetter: IAutopayLetters) =>
                new Date(autopayLetter.CreateDate).getFullYear().toString() === filters.year
            );
            setFilteredAutopayLetters(filtered);
          }
        } else {
          if (statements) setFilteredStatements(statements);
          if (loanAgreements) setFilteredLoanAgreements(loanAgreements);
          if (autopayLetters) setFilteredAutopayLetters(autopayLetters);
        }
        break;
      default:
        setFilteredStatements(statements);
        setFilteredLoanAgreements(loanAgreements);
        break;
    }
  }, [filters]);

  const { state: authState } = useAuthContext();
  const { stopLoader, startLoader, isLoading } = useLoader({
    isLoading: true,
    local: false,
    key: 'DocumentCenterContextProvider',
  });

  const getDocumentsList = async () => {
    try {
      if (authState.country === globalConstants.USA) {
        if (!isLoading) startLoader();
        if (!firstLoad) {
          const response = await getDocumentListService(authState);
          if (response.IsSuccess) {
            if (response.Invoices || response.Documents) {
              console.info('Success calling GetAllDocs api with documents');
            } else console.info('Success calling GetAllDocs api with no documents');
            setStatements(response?.Invoices || []);
            setFilteredStatements(response?.Invoices || []);

            const loanAgreementsTemp: ILoanAgreements[] = [];
            const autopayLettersTemp: IAutopayLetters[] = [];

            response?.Documents?.map((document: IDocument) => {
              if (document?.DocumentName?.toLowerCase().includes('autopay')) {
                autopayLettersTemp.push(document);
              } else {
                loanAgreementsTemp.push(document);
              }
              return null;
            });

            setLoanAgreements(loanAgreementsTemp || []);
            setFilteredLoanAgreements(loanAgreementsTemp || []);

            setAutopayLetters(autopayLettersTemp || []);
            setFilteredAutopayLetters(autopayLettersTemp || []);
          } else {
            throw new Error();
          }
          setFirstLoad(true);
        }
        stopLoader();
      }
    } catch (e) {
      setError(true);
      // HANDLE API ERROR
      stopLoader();
    }
  };

  useEffect(() => {
    const years = [];
    statements.map((statement: IStatements) => {
      const documentYear = new Date(statement.InvoiceDate).getFullYear();
      if (!years.includes(documentYear)) {
        return years.push(documentYear);
      }
      return null;
    });
    loanAgreements.map((loanAgreement: ILoanAgreements) => {
      const documentYear = new Date(loanAgreement.CreateDate).getFullYear();
      if (!years.includes(documentYear)) {
        return years.push(documentYear);
      }
      return null;
    });
    autopayLetters.map((autopayLetter: IAutopayLetters) => {
      const documentYear = new Date(autopayLetter.CreateDate).getFullYear();
      if (!years.includes(documentYear)) {
        return years.push(documentYear);
      }
      return null;
    });
    setYearIndex(years);
  }, [statements, loanAgreements, autopayLetters]);

  const value: IDocumentContext = {
    filteredStatements,
    filteredLoanAgreements,
    filters,
    setFilters,
    yearIndex,
    error,
    getDocumentsList,
    filteredAutopayLetters,
  };

  return (
    <>
      <DocCenterContext.Provider value={value}>{children}</DocCenterContext.Provider>
    </>
  );
};
