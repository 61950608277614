export const MESSAGE_LINK_TEXT = 'MessageCenter.remoteLink';
export const MESSAGE_LINK_PARAGRAPGH = 'MessageCenter.remoteLink.paragrapgh';
export const MESSAGE_WITH_DOC_CENTER_LINK_PARAGRAPGH = 'MessageCenterWithDocCenter.remoteLink.paragrapgh';
/* eslint-disable import/prefer-default-export */
export const MAIN_PAGE_TITLE = 'MessageCenter.Main.Page.Title';
export const ERROR_NOTIFICATION_SYSTEM_ERROR_HEADER = 'ErrorNotification.System.Error.Header';
export const ERROR_NOTIFICATION_SYSTEM_ERROR_CONTENT = 'ErrorNotification.System.Error.Content';
export const ERROR_NOTIFICATION_MESSAGE_CANNOT_BE_SENT_ERROR_CONTENT =
  'ErrorNotification.Message.Cannot.Be.Sent.Error.Content';

// API errors
export const API_ERROR_SEND_MESSAGE = 'Api_Error.SendMessage';
export const API_ERROR_GENERIC = 'Api_Error.Generic';

export const UPLOAD_TOOLTIP_CONTENT = 'MessageCenter.UploadFile.Tooltip.Content';
export const UPLOAD_FAILED_MESSAGE = 'MessageCenter.UploadFile.Faile.Message';
export const UPLOAD_FAILED_TOOLTIP_CONTENT = 'MessageCenter.UploadFile.Failed.Tooltip.Message';

// Mailbox
export const CSR_AUTHOR_TEXT = 'MessageCenter.CSR.Author.Text';
export const MESSAGE_DELETION_NOTICE = 'MessageCenter.MessageDeletionNotice.Message';
export const MESSAGE_REPLY_ATTACHMENT_TEXT = 'MessageCenter.MessageReply.Attachment';
export const MESSAGE_SENT_TODAY_TEXT = 'MessageCenter.Message.Sent.Today.Text';
export const MESSAGE_SENT_YESTERDAY_TEXT = 'MessageCenter.Message.Sent.Yesterday.Text';
export const MESSAGE_SENT_AT_TEXT = 'MessageCenter.Message.Sent.At.Text';

// Back link
export const BACK_LINK_AUDI = 'MessageCenter.BackLink.Audi';
export const BACK_LINK = 'MessageCenter.BackLink.VW6';
export const BACK_LINK_TOPBAR = 'MessageCenter.BackLink.Topbar';
export const BACK_LINK_MESSAGES_TEXT = 'MessageCenter.BackLink.Messages.Text';
export const MESSAGE_CENTER_VOLKSWAGEN_PLUS = 'MessageCenter.VolkswagenPlus';
export const MESSAGE_CENTER_VOLKSWAGEN_FINANCE = 'MessageCenter.VolkswagenFinance';
export const MESSAGE_CENTER_SELECT_OPTIONS = 'MessageCenter.SelectOptions';
export const MESSAGE_CENTER_BACK_TO = 'MessageCenter.BackTo';

// Upload attachments.
export const UPLOAD_FILE_DRAG_AND_DROP_TEXT = 'MessageCenter.Upload.File.Drag.And.Drop.Text';
export const UPLOAD_FILE_OR_TEXT = 'MessageCenter.Upload.File.Or.Text';
export const UPLOAD_FILE_ADD_ATTACHMENTS_TEXT = 'MessageCenter.Upload.File.Add.Attachments.Text';
export const UPLOAD_FILE_ALLOWED_FILE_TYPES_TEXT = 'MessageCenter.Upload.File.Allowed.File.Types.Text';
export const UPLOAD_FILE_PROCESSING_TEXT = 'MessageCenter.Upload.File.Processing.Text';

// Main components
export const FAQ_COMPONENT_NAME = 'MessageCenter.FAQ.Component.Name';
export const MESSAGECENTER_COMPONENT_NAME = 'MessageCenter.MessageCenter.Component.Name';

// New Message
export const NEW_MESSAGE_DESCRIPTION_LABEL_TEXT_AUDI = 'MessageCenter.NewMessage.Description.Label.Text.VW6';
export const NEW_MESSAGE_DESCRIPTION_LABEL_TEXT_VW6 = 'MessageCenter.NewMessage.Description.Label.Text.Audi';
export const NEW_MESSAGE_DESCRIPTION_PLACEHOLDER_TEXT_AUDI =
  'MessageCenter.NewMessage.Description.Placeholder.Text.Audi';
export const NEW_MESSAGE_DESCRIPTION_PLACEHOLDER_TEXT_VW6 = 'MessageCenter.NewMessage.Description.Placeholder.Text.VW6';
export const NEW_MESSSAGE_TITLE_TEXT = 'MessageCenter.NewMessage.Title.Text';
export const NEW_MESSSAGE_SUBJECT_DEFAULT_ITEM_TEXT = 'MessageCenter.NewMessage.Subject.Default.Item.Text';
export const NEW_MESSAGE_CLOSE_BUTTON_TEXT = 'MessageCenter.NewMessage.Close.Button.Text';
export const NEW_MESSAGE_SEND_BUTTON_TEXT = 'MessageCenter.NewMessage.Send.Button.Text';
export const ESIGN_AGREEMENT_TEXT_PART_ONE = 'MessageCenter.Esign.Agreement.Text.PartOne';
export const ESIGN_AGREEMENT_PDF_LINK = 'MessageCenter.Esign.Agreement.Pdf.Link';
export const ESIGN_AGREEMENT_TEXT_PART_TWO = 'MessageCenter.Esign.Agreement.Text.PartTwo';
export const ESIGN_AGREEMENT_TEXT_NOTE = 'MessageCenter.Esign.Agreement.Text.Note';
export const REPLY_TEXT = 'MessageCenter.Reply.Text';
export const VALIDATION_SUBJECT_MAX_CHARACTERS = 'MessageCenter.Validation.Subject.Max.Characters';
export const VALIDATION_BODY_MAX_CHARACTERS = 'MessageCenter.Validation.Body.Max.Characters';
export const VALIDATION_SUBJECT_REQUIRED = 'MessageCenter.Validation.Subject.Required';
export const VALIDATION_BODY_REQUIRED = 'MessageCenter.Validation.Body.Required';
export const VALIDATION_BODY_NOTEMPTY = 'MessageCenter.Validation.Body.NotEmpty';
export const VALIDATION_ESIGN_AGREEMENT = 'MessageCenter.Validation.ESign.Agreement';

// New Message Modal Confirmation
export const NEW_MESSAGE_MODAL_TITLE = 'MessageCenter.NewMessage.Modal.Title';
export const NEW_MESSAGE_MODAL_CONTENT = 'MessageCenter.NewMessage.Modal.Content';
export const NEW_MESSAGE_MODAL_CANCEL_BUTTON_TEXT = 'MessageCenter.NewMessage.Modal.Cancel.Button.Text';
export const NEW_MESSAGE_MODAL_OK_BUTTON_TEXT = 'MessageCenter.NewMessage.Modal.Ok.Button.Text';

// Message Sent Confirmation Notification
export const MESSAGE_SENT_CONFIRMATION_NOTIFICATION_CONTENT =
  'MessageCenter.MessageSent.Confirmation.Notification.Content';
export const MAILBOX_MESSAGE_AUTHOR_USER = 'MessageCenter.Mailbox.Message.Author.User';

// FAQ
export const FAQ_TITLE = 'FAQ.Title';
export const FAQ_MOVING = 'FAQ.Moving';
export const FAQ_PAYMENTOPTIONS = 'FAQ.PaymentOptions';
export const FAQ_PAPERLESSBILLING = 'FAQ.PaperlessBilling';
export const FAQ_PAYOFFQUOTES = 'FAQ.PayoffQuotes';
export const FAQ_ACCIDENT = 'FAQ.Accident';
export const FAQ_INSURANCEREQUIREMENTS = 'FAQ.InsuranceRequirements';
export const FAQ_THIRDPARTYAUTHORIZATION = 'FAQ.ThirdPartyAuthorization';
export const FAQ_CONSUMERCREDIT = 'FAQ.ConsumerCredit';
export const FAQ_SCRA = 'FAQ.SCRA';
export const FAQ_TRANSFERMYACCOUNT = 'FAQ.TransferMyAccount';
export const FAQ_LEASE_GENERAL = 'FAQ.Lease.General';
export const FAQ_LEASE_TURNIN = 'FAQ.Lease.TurnIn';
export const FAQ_LEASE_EARLYLEASETERMINATION = 'FAQ.Lease.EarlyLeaseTermination';
export const FAQ_LEASE_PURCHASING = 'FAQ.Lease.Purchasing';
export const FAQ_LEASE_VEHICLEINSPECTIONS = 'FAQ.Lease.VehicleInspections';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE = 'FAQ.Lease.TurnInSettlementInvoice';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY = 'FAQ.Lease.SellingToThirdParty';
export const FAQ_LEASE_TITLEANDREGISTRATION = 'FAQ.Lease.TitleAndRegistration';
export const FAQ_TITLES = 'FAQ.Titles';
export const FAQ_BALLOON_GENERAL = 'FAQ.Balloon.General';
export const FAQ_BALLOON_TURNIN = 'FAQ.Balloon.TurnIn';
export const FAQ_BALLOON_PURCHASING = 'FAQ.Balloon.Purchasing';
export const FAQ_BALLOON_VEHICLEINSPECTIONS = 'FAQ.Balloon.VehicleInspections';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE = 'FAQ.Balloon.TurnInSettlementInvoice';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION = 'FAQ.Balloon.VehicleTitleAndRegistration';
export const FAQ_TITLES_MESSAGES_PARAGRAPH = 'FAQ.Titles.Message.Paragraph';
export const FAQ_TITLES_MESSAGES_LINK = 'FAQ.Titles.Message.Link';
export const FAQ_TITLES_QUESTION1 = 'FAQ.Titles.Question1';
export const FAQ_TITLES_ANSWER1_AUDI = 'FAQ.Titles.Answer1.Audi';
export const FAQ_TITLES_ANSWER1_VW = 'FAQ.Titles.Answer1.VW';
export const FAQ_TITLES_ANSWER1_PARAGRAPH1 = 'FAQ.Titles.Answer1.Paragraph1';
export const FAQ_TITLES_ANSWER1_PARAGRAPH2 = 'FAQ.Titles.Answer1.Paragraph2';
export const FAQ_TITLES_QUESTION2 = 'FAQ.Titles.Question2';
export const FAQ_TITLES_ANSWER2 = 'FAQ.Titles.Answer2';
export const FAQ_TITLES_QUESTION3 = 'FAQ.Titles.Question3';
export const FAQ_TITLES_ANSWER3_AUDI = 'FAQ.Titles.Answer3.Audi';
export const FAQ_TITLES_ANSWER3_VW = 'FAQ.Titles.Answer3.VW';
export const FAQ_TITLES_QUESTION4 = 'FAQ.Titles.Question4';
export const FAQ_TITLES_ANSWER4 = 'FAQ.Titles.Answer4';
export const FAQ_TITLES_QUESTION5 = 'FAQ.Titles.Question5';
export const FAQ_TITLES_ANSWER5_PARAGRAPH1 = 'FAQ.Titles.Answer5.Paragraph1';
export const FAQ_TITLES_ANSWER5_LISTITEM1_TITLE = 'FAQ.Titles.Answer5.ListItem1.Title';
export const FAQ_TITLES_ANSWER5_LISTITEM1_CONTENT = 'FAQ.Titles.Answer5.ListItem1.Content';
export const FAQ_TITLES_ANSWER5_LISTITEM2_TITLE = 'FAQ.Titles.Answer5.ListItem2.Title';
export const FAQ_TITLES_ANSWER5_LISTITEM2_CONTENT_VW = 'FAQ.Titles.Answer5.ListItem2.Content.VW';
export const FAQ_TITLES_ANSWER5_LISTITEM2_CONTENT_AUDI = 'FAQ.Titles.Answer5.ListItem2.Content.Audi';
export const FAQ_TITLES_ANSWER5_LISTITEM3_TITLE = 'FAQ.Titles.Answer5.ListItem3.Title';
export const FAQ_TITLES_ANSWER5_LISTITEM3_CONTENT_VW = 'FAQ.Titles.Answer5.ListItem3.Content.VW';
export const FAQ_TITLES_ANSWER5_LISTITEM3_CONTENT_AUDI = 'FAQ.Titles.Answer5.ListItem3.Content.Audi';
export const FAQ_TITLES_ANSWER5_LISTITEM4_TITLE = 'FAQ.Titles.Answer5.ListItem4.Title';
export const FAQ_TITLES_ANSWER5_LISTITEM4_CONTENT_VW = 'FAQ.Titles.Answer5.ListItem4.Content.VW';
export const FAQ_TITLES_ANSWER5_LISTITEM4_CONTENT_AUDI = 'FAQ.Titles.Answer5.ListItem4.Content.Audi';
export const FAQ_TITLES_ANSWER5_PARAGRAPH2 = 'FAQ.Titles.Answer5.Paragraph2';
export const FAQ_TITLES_QUESTION6_AUDI = 'FAQ.Titles.Question6.Audi';
export const FAQ_TITLES_QUESTION6_VW = 'FAQ.Titles.Question6.VW';
export const FAQ_TITLES_ANSWER6 = 'FAQ.Titles.Answer6';
export const FAQ_TITLES_QUESTION7 = 'FAQ.Titles.Question7';
export const FAQ_TITLES_ANSWER7 = 'FAQ.Titles.Answer7';
export const FAQ_TITLES_QUESTION8 = 'FAQ.Titles.Question8';
export const FAQ_TITLES_ANSWER8_PARAGRAPH1_AUDI = 'FAQ.Titles.Answer8.Paragraph1.Audi';
export const FAQ_TITLES_ANSWER8_PARAGRAPH1_VW = 'FAQ.Titles.Answer8.Paragraph1.VW';
export const FAQ_TITLES_ANSWER8_PARAGRAPH1_WITHLINK_AUDI = 'FAQ.Titles.Answer8.Paragraph1.WithLink.Audi';
export const FAQ_TITLES_ANSWER8_PARAGRAPH1_WITHLINK_VW = 'FAQ.Titles.Answer8.Paragraph1.WithLink.VW';
export const FAQ_TITLES_ANSWER8_LINK1 = 'FAQ.Titles.Answer8.Link1';
export const FAQ_TITLES_ANSWER8_PARAGRAPH2 = 'FAQ.Titles.Answer8.Paragraph2';
export const FAQ_TITLES_REGULAR_MAIL_ADDRESS_PARAGRAPH1 = 'FAQ.Titles.Regular.Mail.Address.Paragraph1';
export const FAQ_TITLES_REGULAR_MAIL_ADDRESS_PARAGRAPH2 = 'FAQ.Titles.Regular.Mail.Address.Paragraph2';
export const FAQ_TITLES_OVERNIGHT_MAIL_ADDRESS_PARAGRAPH1 = 'FAQ.Titles.Overnight.Mail.Address.Paragraph1';
export const FAQ_TITLES_OVERNIGHT_MAIL_ADDRESS_PARAGRAPH2 = 'FAQ.Titles.Overnight.Mail.Address.Paragraph2';
export const FAQ_TITLES_QUESTION9 = 'FAQ.Titles.Question9';
export const FAQ_TITLES_ANSWER9 = 'FAQ.Titles.Answer9';
export const FAQ_TITLES_QUESTION10 = 'FAQ.Titles.Question10';
export const FAQ_TITLES_ANSWER10_PARAGRAPH1 = 'FAQ.Titles.Answer10.Paragraph1';
export const FAQ_TITLES_ANSWER10_LISTITEM1 = 'FAQ.Titles.Answer10.ListItem1';
export const FAQ_TITLES_ANSWER10_LISTITEM2_VW = 'FAQ.Titles.Answer10.ListItem2.VW';
export const FAQ_TITLES_ANSWER10_LISTITEM2_AUDI = 'FAQ.Titles.Answer10.ListItem2.Audi';
export const FAQ_TITLES_ANSWER10_LISTITEM3 = 'FAQ.Titles.Answer10.ListItem3';
export const FAQ_TITLES_QUESTION11 = 'FAQ.Titles.Question11';
export const FAQ_TITLES_ANSWER11_PARAGRAPH1_VW = 'FAQ.Titles.Answer11.Paragraph1.VW';
export const FAQ_TITLES_ANSWER11_PARAGRAPH1_AUDI = 'FAQ.Titles.Answer11.Paragraph1.Audi';
export const FAQ_TITLES_ANSWER11_PARAGRAPH2 = 'FAQ.Titles.Answer11.Paragraph2';
export const FAQ_TITLES_ANSWER11_PARAGRAPH2_LINK = 'FAQ.Titles.Answer11.Paragraph2.Link';
export const FAQ_MOVING_QUESTION1 = 'FAQ.Moving.Question1';
export const FAQ_MOVING_ANSWER1_LINK = 'FAQ.Moving.Answer1.Link';
export const FAQ_MOVING_ANSWER1_VW = 'FAQ.Moving.Answer1.VW';
export const FAQ_MOVING_ANSWER1_AUDI = 'FAQ.Moving.Answer1.Audi';
export const FAQ_PAYMENTOPTIONS_QUESTION1 = 'FAQ.PaymentOptions.Question1';
export const FAQ_PAYMENTOPTIONS_ANSWER1_LINK1 = 'FAQ.PaymentOptions.Answer1.Link1';
export const FAQ_PAYMENTOPTIONS_ANSWER1_LINK2 = 'FAQ.PaymentOptions.Answer1.Link2';
export const FAQ_PAYMENTOPTIONS_ANSWER1_LINK3 = 'FAQ.PaymentOptions.Answer1.Link3';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1_VW = 'FAQ.PaymentOptions.Answer1.Paragraph1.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2_VW = 'FAQ.PaymentOptions.Answer1.Paragraph2.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_TITLE_VW = 'FAQ.PaymentOptions.Answer1.Paragraph3.Title.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_CONTENT_VW = 'FAQ.PaymentOptions.Answer1.Paragraph3.Content.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_TITLE_VW = 'FAQ.PaymentOptions.Answer1.Paragraph4.Title.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT1_VW = 'FAQ.PaymentOptions.Answer1.Paragraph4.Content1.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT2_VW = 'FAQ.PaymentOptions.Answer1.Paragraph4.Content2.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_TITLE_VW = 'FAQ.PaymentOptions.Answer1.Paragraph5.Title.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_CONTENT_VW = 'FAQ.PaymentOptions.Answer1.Paragraph5.Content.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_TITLE_VW = 'FAQ.PaymentOptions.Answer1.Paragraph6.Title.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_CONTENT_VW = 'FAQ.PaymentOptions.Answer1.Paragraph6.Content.VW';
export const FAQ_PAYMENTOPTIONS_QUESTION2 = 'FAQ.PaymentOptions.Question2';
export const FAQ_PAYMENTOPTIONS_ANSWER2_LINK1 = 'FAQ.PaymentOptions.Answer2.Link1';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1_VW = 'FAQ.PaymentOptions.Answer2.Paragraph1.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2_VW = 'FAQ.PaymentOptions.Answer2.Paragraph2.VW';
export const FAQ_PAYMENTOPTIONS_QUESTION3 = 'FAQ.PaymentOptions.Question3';
export const FAQ_PAYMENTOPTIONS_ANSWER3_LINK1 = 'FAQ.PaymentOptions.Answer3.Link1';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1_VW = 'FAQ.PaymentOptions.Answer3.Paragraph1.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2_VW = 'FAQ.PaymentOptions.Answer3.Paragraph2.VW';
export const FAQ_PAYMENTOPTIONS_QUESTION4 = 'FAQ.PaymentOptions.Question4';
export const FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1_VW = 'FAQ.PaymentOptions.Answer4.Paragraph1.VW';
export const FAQ_PAYMENTOPTIONS_QUESTION5 = 'FAQ.PaymentOptions.Question5';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LINK1 = 'FAQ.PaymentOptions.Answer5.Link1';
// this FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1 is used for US VW and AUDI
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_US_DDC_DISABLED_AUDI =
  'FAQ.PaymentOptions.Answer5.Paragraph1.US.DDC.Disabled.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_US_DDC_DISABLED_VW =
  'FAQ.PaymentOptions.Answer5.Paragraph1.US.DDC.Disabled.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_US = 'FAQ.PaymentOptions.Answer5.Paragraph1.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH2_US = 'FAQ.PaymentOptions.Answer5.Paragraph2.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH3_US = 'FAQ.PaymentOptions.Answer5.Paragraph3.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST1_ITEM1_US = 'FAQ.PaymentOptions.Answer5.List1.Item1.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST1_ITEM2_US = 'FAQ.PaymentOptions.Answer5.List1.Item2.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH4_US = 'FAQ.PaymentOptions.Answer5.Paragraph4.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM1_US = 'FAQ.PaymentOptions.Answer5.List2.Item1.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM2_US = 'FAQ.PaymentOptions.Answer5.List2.Item2.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM3_US = 'FAQ.PaymentOptions.Answer5.List2.Item3.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM4_US = 'FAQ.PaymentOptions.Answer5.List2.Item4.US';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LINK_US = 'FAQ.PaymentOptions.Answer5.Link.US';
export const FAQ_PAPERLESSBILLING_QUESTION1 = 'FAQ.PaperlessBilling.Question1';
export const FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH1_VW = 'FAQ.PaperlessBilling.Answer1.Paragraph1.VW';
export const FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH2_VW = 'FAQ.PaperlessBilling.Answer1.Paragraph2.VW';
export const FAQ_PAPERLESSBILLING_QUESTION2 = 'FAQ.PaperlessBilling.Question2';
export const FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH1_VW = 'FAQ.PaperlessBilling.Answer2.Paragraph1.VW';
export const FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH2_VW = 'FAQ.PaperlessBilling.Answer2.Paragraph2.VW';
export const FAQ_PAPERLESSBILLING_QUESTION3 = 'FAQ.PaperlessBilling.Question3';
export const FAQ_PAPERLESSBILLING_ANSWER3_LINK1 = 'FAQ.PaperlessBilling.Answer3.Link1';
export const FAQ_PAPERLESSBILLING_ANSWER3_LINK2 = 'FAQ.PaperlessBilling.Answer3.Link2';
export const FAQ_PAPERLESSBILLING_ANSWER3_LINK3 = 'FAQ.PaperlessBilling.Answer3.Link3';
export const FAQ_PAPERLESSBILLING_ANSWER3_LINK4 = 'FAQ.PaperlessBilling.Answer3.Link4';
export const FAQ_PAPERLESSBILLING_ANSWER3_PARAGRAPH1_VW = 'FAQ.PaperlessBilling.Answer3.Paragraph1.VW';
export const FAQ_PAPERLESSBILLING_QUESTION4 = 'FAQ.PaperlessBilling.Question4';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK1 = 'FAQ.PaperlessBilling.Answer4.Link1';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK2 = 'FAQ.PaperlessBilling.Answer4.Link2';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK3 = 'FAQ.PaperlessBilling.Answer4.Link3';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK4 = 'FAQ.PaperlessBilling.Answer4.Link4';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK5 = 'FAQ.PaperlessBilling.Answer4.Link5';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK6 = 'FAQ.PaperlessBilling.Answer4.Link6';
export const FAQ_PAPERLESSBILLING_ANSWER4_LINK7 = 'FAQ.PaperlessBilling.Answer4.Link7';
export const FAQ_PAPERLESSBILLING_ANSWER4_PARAGRAPH1_VW = 'FAQ.PaperlessBilling.Answer4.Paragraph1.VW';
export const FAQ_PAYOFFQUOTES_QUESTION1 = 'FAQ.PayoffQuotes.Question1';
export const FAQ_PAYOFFQUOTES_ANSWER1_LINK1 = 'FAQ.PayoffQuotes.Answer1.Link1';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_TITLE_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph1.Title.VW';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_CONTENT_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph1.Content.VW';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_TITLE_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph2.Title.VW';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_CONTENT_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph2.Content.VW';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph3.VW';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4_VW = 'FAQ.PayoffQuotes.Answer1.Paragraph4.VW';
export const FAQ_ACCIDENT_QUESTION1 = 'FAQ.Accident.Question1';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH1_VW = 'FAQ.Accident.Answer1.Paragraph1.VW';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH2_VW = 'FAQ.Accident.Answer1.Paragraph2.VW';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH3_VW = 'FAQ.Accident.Answer1.Paragraph3.VW';
export const FAQ_INSURANCEREQUIREMENTS_QUESTION1 = 'FAQ.InsuranceRequirements.Question1';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1_VW = 'FAQ.InsuranceRequirements.Answer1.Paragraph1.VW';
export const FAQ_THIRDPARTYAUTHORIZATION_QUESTION1 = 'FAQ.ThirdPartyAuthorization.Question1';
export const FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_LINK1 = 'FAQ.ThirdPartyAuthorization.Answer1.Link1';
export const FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_LINK2 = 'FAQ.ThirdPartyAuthorization.Answer1.Link2';
export const FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_PARAGRAPH1_VW = 'FAQ.ThirdPartyAuthorization.Answer1.Paragraph1.VW';
export const FAQ_CONSUMERCREDIT_QUESTION1 = 'FAQ.ConsumerCredit.Question1';
export const FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH1_VW = 'FAQ.ConsumerCredit.Answer1.Paragraph1.VW';
export const FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH2_VW = 'FAQ.ConsumerCredit.Answer1.Paragraph2.VW';
export const FAQ_SCRA_QUESTION1 = 'FAQ.SCRA.Question1';
export const FAQ_SCRA_ANSWER1_LINK1 = 'FAQ.SCRA.Answer1.Link1';
export const FAQ_SCRA_ANSWER1_PARAGRAPH1_VW = 'FAQ.SCRA.Answer1.Paragraph1.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM1_VW = 'FAQ.SCRA.Answer1.ListItem1.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM2_VW = 'FAQ.SCRA.Answer1.ListItem2.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM3_VW = 'FAQ.SCRA.Answer1.ListItem3.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM4_VW = 'FAQ.SCRA.Answer1.ListItem4.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM5_VW = 'FAQ.SCRA.Answer1.ListItem5.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM6_VW = 'FAQ.SCRA.Answer1.ListItem6.VW';
export const FAQ_SCRA_ANSWER1_LISTITEM7_VW = 'FAQ.SCRA.Answer1.ListItem7.VW';
export const FAQ_SCRA_ANSWER1_PARAGRAPH2_VW = 'FAQ.SCRA.Answer1.Paragraph2.VW';
export const FAQ_SCRA_ANSWER1_PARAGRAPH3_VW = 'FAQ.SCRA.Answer1.Paragraph3.VW';
export const FAQ_TRANSFERMYACCOUNT_QUESTION1 = 'FAQ.TransferMyAccount.Question1';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH1_VW = 'FAQ.TransferMyAccount.Answer1.Paragraph1.VW';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH2_VW = 'FAQ.TransferMyAccount.Answer1.Paragraph2.VW';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH3_VW = 'FAQ.TransferMyAccount.Answer1.Paragraph3.VW';
export const FAQ_LEASE_GENERAL_QUESTION1 = 'FAQ.Lease.General.Question1';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1_VW = 'FAQ.Lease.General.Answer1.Paragraph1.VW';
export const FAQ_LEASE_GENERAL_QUESTION2 = 'FAQ.Lease.General.Question2';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1_VW = 'FAQ.Lease.General.Answer2.Paragraph1.VW';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH2_VW = 'FAQ.Lease.General.Answer2.Paragraph2.VW';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_TITLE_VW = 'FAQ.Lease.General.Answer2.Paragraph3.Title.VW';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_CONTENT_VW = 'FAQ.Lease.General.Answer2.Paragraph3.Content.VW';
export const FAQ_LEASE_GENERAL_QUESTION3 = 'FAQ.Lease.General.Question3';
export const FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1_VW = 'FAQ.Lease.General.Answer3.Paragraph1.VW';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph1.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph2.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_TITLE_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph3.Title.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_CONTENT_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph3.Content.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_TITLE_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph4.Title.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT1_AUDI =
  'FAQ.PaymentOptions.Answer1.Paragraph4.Content1.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT2_AUDI =
  'FAQ.PaymentOptions.Answer1.Paragraph4.Content2.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_TITLE_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph5.Title.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_CONTENT_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph5.Content.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_TITLE_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph6.Title.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_CONTENT_AUDI = 'FAQ.PaymentOptions.Answer1.Paragraph6.Content.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.PaymentOptions.Answer2.Paragraph1.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2_AUDI = 'FAQ.PaymentOptions.Answer2.Paragraph2.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.PaymentOptions.Answer3.Paragraph1.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2_AUDI = 'FAQ.PaymentOptions.Answer3.Paragraph2.Audi';
export const FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1_AUDI = 'FAQ.PaymentOptions.Answer4.Paragraph1.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.PaperlessBilling.Answer1.Paragraph1.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.PaperlessBilling.Answer1.Paragraph2.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.PaperlessBilling.Answer2.Paragraph1.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH2_AUDI = 'FAQ.PaperlessBilling.Answer2.Paragraph2.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.PaperlessBilling.Answer3.Paragraph1.Audi';
export const FAQ_PAPERLESSBILLING_ANSWER4_PARAGRAPH1_AUDI = 'FAQ.PaperlessBilling.Answer4.Paragraph1.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_TITLE_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph1.Title.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_CONTENT_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph1.Content.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_TITLE_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph2.Title.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_CONTENT_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph2.Content.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph3.Audi';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4_AUDI = 'FAQ.PayoffQuotes.Answer1.Paragraph4.Audi';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Accident.Answer1.Paragraph1.Audi';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.Accident.Answer1.Paragraph2.Audi';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH3_AUDI = 'FAQ.Accident.Answer1.Paragraph3.Audi';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.InsuranceRequirements.Answer1.Paragraph1.Audi';
export const FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.ThirdPartyAuthorization.Answer1.Paragraph1.Audi';
export const FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.ConsumerCredit.Answer1.Paragraph1.Audi';
export const FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.ConsumerCredit.Answer1.Paragraph2.Audi';
export const FAQ_SCRA_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.SCRA.Answer1.Paragraph1.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM1_AUDI = 'FAQ.SCRA.Answer1.ListItem1.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM2_AUDI = 'FAQ.SCRA.Answer1.ListItem2.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM3_AUDI = 'FAQ.SCRA.Answer1.ListItem3.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM4_AUDI = 'FAQ.SCRA.Answer1.ListItem4.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM5_AUDI = 'FAQ.SCRA.Answer1.ListItem5.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM6_AUDI = 'FAQ.SCRA.Answer1.ListItem6.Audi';
export const FAQ_SCRA_ANSWER1_LISTITEM7_AUDI = 'FAQ.SCRA.Answer1.ListItem7.Audi';
export const FAQ_SCRA_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.SCRA.Answer1.Paragraph2.Audi';
export const FAQ_SCRA_ANSWER1_PARAGRAPH3_AUDI = 'FAQ.SCRA.Answer1.Paragraph3.Audi';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.TransferMyAccount.Answer1.Paragraph1.Audi';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.TransferMyAccount.Answer1.Paragraph2.Audi';
export const FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH3_AUDI = 'FAQ.TransferMyAccount.Answer1.Paragraph3.Audi';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Lease.General.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.Lease.General.Answer2.Paragraph1.Audi';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH2_AUDI = 'FAQ.Lease.General.Answer2.Paragraph2.Audi';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_TITLE_AUDI = 'FAQ.Lease.General.Answer2.Paragraph3.Title.Audi';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_CONTENT_AUDI = 'FAQ.Lease.General.Answer2.Paragraph3.Content.Audi';
export const FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.Lease.General.Answer3.Paragraph1.Audi';
export const FAQ_LEASE_TURNIN_QUESTION1 = 'FAQ.Lease.TurnIn.Question1';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK1_VW = 'FAQ.Lease.TurnIn.Answer1.Link1.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK1_AUDI = 'FAQ.Lease.TurnIn.Answer1.Link1.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK2 = 'FAQ.Lease.TurnIn.Answer1.Link2';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK3 = 'FAQ.Lease.TurnIn.Answer1.Link3';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK4 = 'FAQ.Lease.TurnIn.Answer1.Link4';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph2.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph2.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph2.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph2.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM1_VW = 'FAQ.Lease.TurnIn.Answer1.ListItem1.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM1_AUDI = 'FAQ.Lease.TurnIn.Answer1.ListItem1.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM2_VW = 'FAQ.Lease.TurnIn.Answer1.ListItem2.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM2_AUDI = 'FAQ.Lease.TurnIn.Answer1.ListItem2.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM3_VW = 'FAQ.Lease.TurnIn.Answer1.ListItem3.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_LISTITEM3_AUDI = 'FAQ.Lease.TurnIn.Answer1.ListItem3.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph3.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph3.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph3.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph3.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph4.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph4.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph5.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph5.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph5.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph5.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph6.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph6.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph6.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph6.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph7.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph7.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph8.Title.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph8.Title.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph8.Content.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph8.Content.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph9.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph9.Audi';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH10_VW = 'FAQ.Lease.TurnIn.Answer1.Paragraph10.VW';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH10_AUDI = 'FAQ.Lease.TurnIn.Answer1.Paragraph10.Audi';
export const FAQ_LEASE_EARLYTERMINATION_QUESTION1 = 'FAQ.Lease.EarlyTermination.Question1';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1_VW = 'FAQ.Lease.EarlyTermination.Answer1.Paragraph1.VW';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Lease.EarlyTermination.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH2_VW = 'FAQ.Lease.EarlyTermination.Answer1.Paragraph2.VW';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.Lease.EarlyTermination.Answer1.Paragraph2.Audi';
export const FAQ_LEASE_PURCHASING_QUESTION1 = 'FAQ.Lease.Purchasing.Question1';
export const FAQ_LEASE_PURCHASING_ANSWER1_LINK1 = 'FAQ.Lease.Purchasing.Answer1.Link1';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph1.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH2_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph2.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph2.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_TITLE_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph3.Title.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_TITLE_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph3.Title.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_CONTENT_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph3.Content.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_CONTENT_AUDI =
  'FAQ.Lease.Purchasing.Answer1.Paragraph3.Content.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_TITLE_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph4.Title.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_TITLE_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph4.Title.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_CONTENT_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph4.Content.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_CONTENT_AUDI =
  'FAQ.Lease.Purchasing.Answer1.Paragraph4.Content.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_TITLE_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph5.Title.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_TITLE_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph5.Title.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_CONTENT_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph5.Content.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_CONTENT_AUDI =
  'FAQ.Lease.Purchasing.Answer1.Paragraph5.Content.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH6_VW = 'FAQ.Lease.Purchasing.Answer1.Paragraph6.VW';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH6_AUDI = 'FAQ.Lease.Purchasing.Answer1.Paragraph6.Audi';
export const FAQ_LEASE_PURCHASING_QUESTION2 = 'FAQ.Lease.Purchasing.Question2';
export const FAQ_LEASE_PURCHASING_ANSWER2_LINK1 = 'FAQ.Lease.Purchasing.Answer2.Link1';
export const FAQ_LEASE_PURCHASING_ANSWER2_LINK2 = 'FAQ.Lease.Purchasing.Answer2.Link2';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1_VW = 'FAQ.Lease.Purchasing.Answer2.Paragraph1.VW';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.Lease.Purchasing.Answer2.Paragraph1.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH2_VW = 'FAQ.Lease.Purchasing.Answer2.Paragraph2.VW';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH2_AUDI = 'FAQ.Lease.Purchasing.Answer2.Paragraph2.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_TITLE_VW = 'FAQ.Lease.Purchasing.Answer2.Paragraph3.Title.VW';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_TITLE_AUDI = 'FAQ.Lease.Purchasing.Answer2.Paragraph3.Title.Audi';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_CONTENT_VW = 'FAQ.Lease.Purchasing.Answer2.Paragraph3.Content.VW';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_CONTENT_AUDI =
  'FAQ.Lease.Purchasing.Answer2.Paragraph3.Content.Audi';
export const FAQ_LEASE_PURCHASING_QUESTION3 = 'FAQ.Lease.Purchasing.Question3';
export const FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1_VW = 'FAQ.Lease.Purchasing.Answer3.Paragraph1.VW';
export const FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.Lease.Purchasing.Answer3.Paragraph1.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION1 = 'FAQ.Lease.VehicleInspections.Question1';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK1 = 'FAQ.Lease.VehicleInspections.Answer1.Link1';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK2 = 'FAQ.Lease.VehicleInspections.Answer1.Link2';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW = 'FAQ.Lease.VehicleInspections.Answer1.Link3.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI = 'FAQ.Lease.VehicleInspections.Answer1.Link3.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK4 = 'FAQ.Lease.VehicleInspections.Answer1.Link4';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.Title.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.Title.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.Content.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.Content.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2_VW = 'FAQ.Lease.VehicleInspections.Answer1.Paragraph2.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph2.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph3.Title.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph3.Title.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph3.Content.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph3.Content.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4_VW = 'FAQ.Lease.VehicleInspections.Answer1.Paragraph4.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph4.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5_VW = 'FAQ.Lease.VehicleInspections.Answer1.Paragraph5.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph5.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6_VW = 'FAQ.Lease.VehicleInspections.Answer1.Paragraph6.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph6.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph7.Title.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph7.Title.Audi';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT_VW =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph7.Content.VW';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT_AUDI =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph7.Content.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION1 = 'FAQ.Lease.TurnInSettlementInvoice.Question1';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LINK1 = 'FAQ.Lease.TurnInSettlementInvoice.Answer1.Link1';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph1.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM1_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem1.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM1_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem1.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM2_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem2.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM2_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem2.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM3_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem3.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM3_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem3.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM4_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem4.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM4_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem4.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM5_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem5.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM5_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem5.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM6_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem6.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM6_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem6.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM7_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem7.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM7_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem7.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM8_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem8.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM8_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem8.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM9_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem9.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM9_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.ListItem9.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_TITLE_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph2.Title.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_TITLE_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph2.Title.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_CONTENT_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph2.Content.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_CONTENT_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph2.Content.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH3_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph3.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH3_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph3.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph4.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph4.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH5_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph5.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH5_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer1.Paragraph5.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION2 = 'FAQ.Lease.TurnInSettlementInvoice.Question2';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1 = 'FAQ.Lease.TurnInSettlementInvoice.Answer2.Link1';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer2.Paragraph1.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer2.Paragraph1.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer2.Paragraph2.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer2.Paragraph2.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION3 = 'FAQ.Lease.TurnInSettlementInvoice.Question3';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_VW = 'FAQ.Lease.TurnInSettlementInvoice.Answer3.Link1.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer3.Link1.Audi';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_VW =
  'FAQ.Lease.TurnInSettlementInvoice.Answer3.Paragraph1.VW';
export const FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_AUDI =
  'FAQ.Lease.TurnInSettlementInvoice.Answer3.Paragraph1.Audi';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_QUESTION1 = 'FAQ.Lease.SellingToThirdParty.Question1';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH1_VW =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph1.VW';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_TITLE_VW =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph2.Title.VW';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_TITLE_AUDI =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph2.Title.Audi';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_CONTENT_VW =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph2.Content.VW';
export const FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_CONTENT_AUDI =
  'FAQ.Lease.SellingToThirdParty.Answer1.Paragraph2.Content.Audi';
export const FAQ_LEASE_TITLEANDREGISTRATION_QUESTION1 = 'FAQ.Lease.TitleAndRegistration.Question1';
export const FAQ_LEASE_TITLEANDREGISTRATION_ANSWER1_PARAGRAPH1_VW =
  'FAQ.Lease.TitleAndRegistration.Answer1.Paragraph1.VW';
export const FAQ_LEASE_TITLEANDREGISTRATION_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.Lease.TitleAndRegistration.Answer1.Paragraph1.Audi';
export const FAQ_LEASE_TITLEANDREGISTRATION_QUESTION2 = 'FAQ.Lease.TitleAndRegistration.Question2';
export const FAQ_LEASE_TITLEANDREGISTRATION_ANSWER2_PARAGRAPH1_VW =
  'FAQ.Lease.TitleAndRegistration.Answer2.Paragraph1.VW';
export const FAQ_LEASE_TITLEANDREGISTRATION_ANSWER2_PARAGRAPH1_AUDI =
  'FAQ.Lease.TitleAndRegistration.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_GENERAL_QUESTION1 = 'FAQ.Balloon.General.Question1';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1_VW = 'FAQ.Balloon.General.Answer1.Paragraph1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Balloon.General.Answer1.Paragraph1.Audi';
export const FAQ_BALLOON_GENERAL_QUESTION2 = 'FAQ.Balloon.General.Question2';
export const FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1_VW = 'FAQ.Balloon.General.Answer2.Paragraph1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.Balloon.General.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_GENERAL_QUESTION3_VW = 'FAQ.Balloon.General.Question3.VW';
export const FAQ_BALLOON_GENERAL_QUESTION3_AUDI = 'FAQ.Balloon.General.Question3.Audi';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1_VW = 'FAQ.Balloon.General.Answer3.Paragraph1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.Balloon.General.Answer3.Paragraph1.Audi';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_TITLE_VW = 'FAQ.Balloon.General.Answer3.Paragraph2.Title.VW';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_TITLE_AUDI = 'FAQ.Balloon.General.Answer3.Paragraph2.Title.Audi';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_CONTENT_VW = 'FAQ.Balloon.General.Answer3.Paragraph2.Content.VW';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_CONTENT_AUDI =
  'FAQ.Balloon.General.Answer3.Paragraph2.Content.Audi';
export const FAQ_BALLOON_GENERAL_QUESTION4_VW = 'FAQ.Balloon.General.Question4.VW';
export const FAQ_BALLOON_GENERAL_QUESTION4_AUDI = 'FAQ.Balloon.General.Question4.Audi';
export const FAQ_BALLOON_GENERAL_ANSWER4_LINK1_VW = 'FAQ.Balloon.General.Answer4.Link1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER4_LINK1_AUDI = 'FAQ.Balloon.General.Answer4.Link1.Audi';
export const FAQ_BALLOON_GENERAL_ANSWER4_PARAGRAPH1_VW = 'FAQ.Balloon.General.Answer4.Paragraph1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER4_PARAGRAPH1_AUDI = 'FAQ.Balloon.General.Answer4.Paragraph1.Audi';
export const FAQ_BALLOON_GENERAL_QUESTION5 = 'FAQ.Balloon.General.Question5';
export const FAQ_BALLOON_GENERAL_ANSWER5_PARAGRAPH1_VW = 'FAQ.Balloon.General.Answer5.Paragraph1.VW';
export const FAQ_BALLOON_GENERAL_ANSWER5_PARAGRAPH1_AUDI = 'FAQ.Balloon.General.Answer5.Paragraph1.Audi';
export const FAQ_BALLOON_TURNIN_QUESTION1_VW = 'FAQ.Balloon.TurnIn.Question1.VW';
export const FAQ_BALLOON_TURNIN_QUESTION1_AUDI = 'FAQ.Balloon.TurnIn.Question1.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER1_PARAGRAPH1_VW = 'FAQ.Balloon.TurnIn.Answer1.Paragraph1.VW';
export const FAQ_BALLOON_TURNIN_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Balloon.TurnIn.Answer1.Paragraph1.Audi';
export const FAQ_BALLOON_TURNIN_QUESTION2_VW = 'FAQ.Balloon.TurnIn.Question2.VW';
export const FAQ_BALLOON_TURNIN_QUESTION2_AUDI = 'FAQ.Balloon.TurnIn.Question2.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_LINK1 = 'FAQ.Balloon.TurnIn.Answer2.Link1';
export const FAQ_BALLOON_TURNIN_ANSWER2_LINK2 = 'FAQ.Balloon.TurnIn.Answer2.Link2';
export const FAQ_BALLOON_TURNIN_ANSWER2_LINK3 = 'FAQ.Balloon.TurnIn.Answer2.Link3';

export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH1_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph1.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH2_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph2.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH2_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph2.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH3_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph3.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH3_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph3.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH4_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph4.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH4_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph4.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH5_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph5.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH5_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph5.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_TITLE_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph6.Title.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_TITLE_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph6.Title.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_CONTENT_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph6.Content.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_CONTENT_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph6.Content.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH7_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph7.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH7_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph7.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH8_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph8.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH8_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph8.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH9_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph9.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH9_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph9.Audi';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH10_VW = 'FAQ.Balloon.TurnIn.Answer2.Paragraph10.VW';
export const FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH10_AUDI = 'FAQ.Balloon.TurnIn.Answer2.Paragraph10.Audi';
export const FAQ_BALLOON_PURCHASING_QUESTION1 = 'FAQ.Balloon.Purchasing.Question1';
export const FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH1_VW = 'FAQ.Balloon.Purchasing.Answer1.Paragraph1.VW';
export const FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH1_AUDI = 'FAQ.Balloon.Purchasing.Answer1.Paragraph1.Audi';
export const FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH2_VW = 'FAQ.Balloon.Purchasing.Answer1.Paragraph2.VW';
export const FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH2_AUDI = 'FAQ.Balloon.Purchasing.Answer1.Paragraph2.Audi';
export const FAQ_BALLOON_PURCHASING_QUESTION2 = 'FAQ.Balloon.Purchasing.Question2';
export const FAQ_BALLOON_PURCHASING_ANSWER2_PARAGRAPH1_VW = 'FAQ.Balloon.Purchasing.Answer2.Paragraph1.VW';
export const FAQ_BALLOON_PURCHASING_ANSWER2_PARAGRAPH1_AUDI = 'FAQ.Balloon.Purchasing.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_PURCHASING_QUESTION3 = 'FAQ.Balloon.Purchasing.Question3';
export const FAQ_BALLOON_PURCHASING_ANSWER3_PARAGRAPH1_VW = 'FAQ.Balloon.Purchasing.Answer3.Paragraph1.VW';
export const FAQ_BALLOON_PURCHASING_ANSWER3_PARAGRAPH1_AUDI = 'FAQ.Balloon.Purchasing.Answer3.Paragraph1.Audi';
export const FAQ_BALLOON_PURCHASING_QUESTION4 = 'FAQ.Balloon.Purchasing.Question4';
export const FAQ_BALLOON_PURCHASING_ANSWER4_PARAGRAPH1_VW = 'FAQ.Balloon.Purchasing.Answer4.Paragraph1.VW';
export const FAQ_BALLOON_PURCHASING_ANSWER4_PARAGRAPH1_AUDI = 'FAQ.Balloon.Purchasing.Answer4.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_QUESTION1 = 'FAQ.Balloon.VehicleInspections.Question1';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK1 = 'FAQ.Balloon.VehicleInspections.Answer1.Link1';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK2 = 'FAQ.Balloon.VehicleInspections.Answer1.Link2';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW = 'FAQ.Balloon.VehicleInspections.Answer1.Link3.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI = 'FAQ.Balloon.VehicleInspections.Answer1.Link3.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK4 = 'FAQ.Balloon.VehicleInspections.Answer1.Link4';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph1.Title.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph1.Title.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph1.Content.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph1.Content.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph2.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph2.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph3.Title.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph3.Title.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph3.Content.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph3.Content.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph4.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph4.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph5.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph5.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph6.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph6.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph7.Title.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph7.Title.Audi';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT_VW =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph7.Content.VW';
export const FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT_AUDI =
  'FAQ.Balloon.VehicleInspections.Answer1.Paragraph7.Content.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION1 = 'FAQ.Balloon.TurnInSettlementInvoice.Question1';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_LINK1 = 'FAQ.Balloon.TurnInSettlementInvoice.Answer1.Link1';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer1.Paragraph1.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer1.Paragraph1.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer1.Paragraph2.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer1.Paragraph2.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION2 = 'FAQ.Balloon.TurnInSettlementInvoice.Question2';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Link1.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Link1.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Paragraph1.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Paragraph2.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer2.Paragraph2.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION3 = 'FAQ.Balloon.TurnInSettlementInvoice.Question3';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1 = 'FAQ.Balloon.TurnInSettlementInvoice.Answer3.Link1';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer3.Paragraph1.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer3.Paragraph1.Audi';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION4 = 'FAQ.Balloon.TurnInSettlementInvoice.Question4';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER4_LINK1 = 'FAQ.Balloon.TurnInSettlementInvoice.Answer4.Link1';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER4_PARAGRAPH1_VW =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer4.Paragraph1.VW';
export const FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER4_PARAGRAPH1_AUDI =
  'FAQ.Balloon.TurnInSettlementInvoice.Answer4.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH2_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph2.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH2_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph2.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH3_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph3.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH3_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph3.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_TITLE_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph4.Title.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_TITLE_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph4.Title.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_CONTENT_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph4.Content.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_CONTENT_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph4.Content.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_TITLE_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph5.Title.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_TITLE_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph5.Title.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_CONTENT_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph5.Content.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_CONTENT_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph5.Content.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_TITLE_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph6.Title.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_TITLE_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph6.Title.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_CONTENT_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph6.Content.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_CONTENT_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer1.Paragraph6.Content.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION2 = 'FAQ.Balloon.VehicleTitleAndRegistration.Question2';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER2_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer2.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER2_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer2.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION3_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question3.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION3_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question3.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER3_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer3.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER3_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer3.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION4_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question4.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION4_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question4.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER4_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer4.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER4_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer4.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION5 = 'FAQ.Balloon.VehicleTitleAndRegistration.Question5';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER5_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer5.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER5_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer5.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION6 = 'FAQ.Balloon.VehicleTitleAndRegistration.Question6';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER6_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer6.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER6_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer6.Paragraph1.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION7_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question7.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION7_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Question7.Audi';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER7_PARAGRAPH1_VW =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer7.Paragraph1.VW';
export const FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER7_PARAGRAPH1_AUDI =
  'FAQ.Balloon.VehicleTitleAndRegistration.Answer7.Paragraph1.Audi';
export const FAQ_TITLE_CANADA = 'FAQ.Title.Canada';
export const FAQ_MOVING_CANADA = 'FAQ.Moving.Canada';
export const FAQ_PAYMENTOPTIONS_CANADA = 'FAQ.PaymentOptions.Canada';
export const FAQ_USAGEINTHEUS_CANADA = 'FAQ.UsageInTheUS.Canada';
export const FAQ_PAYOFFQUOTES_CANADA = 'FAQ.PayoffQuotes.Canada';
export const FAQ_ACCIDENT_CANADA = 'FAQ.Accident.Canada';
export const FAQ_NAMECHANGE_CANADA = 'FAQ.NameChange.Canada';
export const FAQ_INSURANCEREQUIREMENTS_CANADA = 'FAQ.InsuranceRequirements.Canada';
export const FAQ_WEBSITESECURITY_CANADA = 'FAQ.WebSiteSecurity.Canada';
export const FAQ_WEBSITEPROBLEMS_CANADA = 'FAQ.WebSiteProblems.Canada';
export const FAQ_LEASE_GENERAL_CANADA = 'FAQ.Lease.General.Canada';
export const FAQ_LEASE_TURNIN_CANADA = 'FAQ.Lease.TurnIn.Canada';
export const FAQ_LEASE_EARLYTERMINATION_CANADA = 'FAQ.Lease.EarlyTermination.Canada';
export const FAQ_LEASE_PURCHASING_CANADA = 'FAQ.Lease.Purchasing.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_CANADA = 'FAQ.Lease.VehicleInspections.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_CANADA = 'FAQ.Lease.VehicleRepairs.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_CANADA = 'FAQ.Lease.FinalLeaseSettlementInvoice.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_CANADA = 'FAQ.Lease.SecurityDeposit.Canada';
export const FAQ_BALLOON_GENERAL_CANADA = 'FAQ.Balloon.General.Canada';
export const FAQ_BALLOON_PAYOFF_CANADA = 'FAQ.Balloon.Payoff.Canada';
export const FAQ_MOVING_QUESTION1_CANADA = 'FAQ.Moving.Question1.Canada';
export const FAQ_MOVING_ANSWER1_LINK_CANADA = 'FAQ.Moving.Answer1.Link.Canada';
export const FAQ_MOVING_ANSWER1_VW_CANADA = 'FAQ.Moving.Answer1.VW.Canada';
export const FAQ_MOVING_ANSWER1_AUDI_CANADA = 'FAQ.Moving.Answer1.Audi.Canada';
export const FAQ_MOVING_QUESTION2_CANADA = 'FAQ.Moving.Question2.Canada';
export const FAQ_MOVING_ANSWER2_LINK_CANADA = 'FAQ.Moving.Answer2.Link.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Moving.Answer2.Paragraph1.VW.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.Moving.Answer2.Paragraph1.Audi.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH2_TITLE_VW_CANADA = 'FAQ.Moving.Answer2.Paragraph2.Title.VW.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH2_CONTENT_VW_CANADA = 'FAQ.Moving.Answer2.Paragraph2.Content.VW.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH2_TITLE_AUDI_CANADA = 'FAQ.Moving.Answer2.Paragraph2.Title.Audi.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH2_CONTENT_AUDI_CANADA = 'FAQ.Moving.Answer2.Paragraph2.Content.Audi.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH3_TITLE_VW_CANADA = 'FAQ.Moving.Answer2.Paragraph3.Title.VW.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH3_CONTENT_VW_CANADA = 'FAQ.Moving.Answer2.Paragraph3.Content.VW.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH3_TITLE_AUDI_CANADA = 'FAQ.Moving.Answer2.Paragraph3.Title.Audi.Canada';
export const FAQ_MOVING_ANSWER2_PARAGRAPH3_CONTENT_AUDI_CANADA = 'FAQ.Moving.Answer2.Paragraph3.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_QUESTION1_CANADA = 'FAQ.PaymentOptions.Question1.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_LINK1_CANADA = 'FAQ.PaymentOptions.Answer1.Link1.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph1.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph1.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2_VW_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph2.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2_AUDI_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph2.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_VW_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph3.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_AUDI_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph3.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_VW_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph4.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_AUDI_CANADA = 'FAQ.PaymentOptions.Answer1.Paragraph4.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_QUESTION2_CANADA = 'FAQ.PaymentOptions.Question2.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.PaymentOptions.Answer2.Paragraph1.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.PaymentOptions.Answer2.Paragraph1.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2_VW_CANADA = 'FAQ.PaymentOptions.Answer2.Paragraph2.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2_AUDI_CANADA = 'FAQ.PaymentOptions.Answer2.Paragraph2.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_QUESTION3_CANADA = 'FAQ.PaymentOptions.Question3.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_LINK1_CANADA = 'FAQ.PaymentOptions.Answer3.Link1.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_LINK2_CANADA = 'FAQ.PaymentOptions.Answer3.Link2.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_LINK3_CANADA = 'FAQ.PaymentOptions.Answer3.Link3.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph1.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph1.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph2.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph2.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH3_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph3.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH3_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph3.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH4_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph4.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH4_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph4.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH5_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph5.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH5_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph5.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph6.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph6.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph6.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH6_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph6.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph7.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph7.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph7.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH7_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph7.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph8.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph8.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph8.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH8_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer3.Paragraph8.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH9_VW_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph9.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH9_AUDI_CANADA = 'FAQ.PaymentOptions.Answer3.Paragraph9.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_QUESTION4_CANADA = 'FAQ.PaymentOptions.Question4.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1_VW_CANADA = 'FAQ.PaymentOptions.Answer4.Paragraph1.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1_AUDI_CANADA = 'FAQ.PaymentOptions.Answer4.Paragraph1.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_QUESTION5_CANADA = 'FAQ.PaymentOptions.Question5.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LINK1_CANADA = 'FAQ.PaymentOptions.Answer5.Link1.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LINK2_CANADA = 'FAQ.PaymentOptions.Answer5.Link2.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_LINK3_CANADA = 'FAQ.PaymentOptions.Answer5.Link3.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph1.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph1.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH2_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph2.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH2_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph2.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH3_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph3.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH3_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph3.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH4_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph4.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH4_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph4.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH5_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph5.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH5_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph5.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph6.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph6.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph6.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH6_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph6.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph7.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph7.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph7.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH7_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph7.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_TITLE_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph8.Title.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_TITLE_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph8.Title.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_CONTENT_VW_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph8.Content.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH8_CONTENT_AUDI_CANADA =
  'FAQ.PaymentOptions.Answer5.Paragraph8.Content.Audi.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH9_VW_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph9.VW.Canada';
export const FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH9_AUDI_CANADA = 'FAQ.PaymentOptions.Answer5.Paragraph9.Audi.Canada';
export const FAQ_USAGEINTHEUS_QUESTION1_CANADA = 'FAQ.UsageInTheUS.Question1.Canada';
export const FAQ_USAGEINTHEUS_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.UsageInTheUS.Answer1.Paragraph1.VW.Canada';
export const FAQ_USAGEINTHEUS_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.UsageInTheUS.Answer1.Paragraph1.Audi.Canada';
export const FAQ_PAYOFFQUOTES_QUESTION1_CANADA = 'FAQ.PayoffQuotes.Question1.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_LINK1_CANADA = 'FAQ.PayoffQuotes.Answer1.Link1.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_LINK2_CANADA = 'FAQ.PayoffQuotes.Answer1.Link2.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph1.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph1.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph2.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph2.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph3.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph3.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph4.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph4.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH5_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph5.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH5_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph5.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH6_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph6.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH6_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph6.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH7_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph7.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH7_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph7.Audi.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH8_VW_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph8.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH8_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer1.Paragraph8.Audi.Canada';
export const FAQ_PAYOFFQUOTES_QUESTION2_CANADA = 'FAQ.PayoffQuotes.Question2.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.PayoffQuotes.Answer2.Paragraph1.VW.Canada';
export const FAQ_PAYOFFQUOTES_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.PayoffQuotes.Answer2.Paragraph1.Audi.Canada';
export const FAQ_ACCIDENT_QUESTION1_CANADA = 'FAQ.Accident.Question1.Canada';
export const FAQ_ACCIDENT_ANSWER1_LINK1_CANADA = 'FAQ.Accident.Answer1.Link1.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Accident.Answer1.Paragraph1.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.Accident.Answer1.Paragraph1.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM1_VW_CANADA = 'FAQ.Accident.Answer1.ListItem1.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM1_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem1.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM2_VW_CANADA = 'FAQ.Accident.Answer1.ListItem2.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM2_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem2.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM3_VW_CANADA = 'FAQ.Accident.Answer1.ListItem3.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM3_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem3.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH2_VW_CANADA = 'FAQ.Accident.Answer1.Paragraph2.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH2_AUDI_CANADA = 'FAQ.Accident.Answer1.Paragraph2.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH3_VW_CANADA = 'FAQ.Accident.Answer1.Paragraph3.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_PARAGRAPH3_AUDI_CANADA = 'FAQ.Accident.Answer1.Paragraph3.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM4_VW_CANADA = 'FAQ.Accident.Answer1.ListItem4.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM4_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem4.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM5_VW_CANADA = 'FAQ.Accident.Answer1.ListItem5.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM5_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem5.Audi.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM6_VW_CANADA = 'FAQ.Accident.Answer1.ListItem6.VW.Canada';
export const FAQ_ACCIDENT_ANSWER1_LISTITEM6_AUDI_CANADA = 'FAQ.Accident.Answer1.ListItem6.Audi.Canada';
export const FAQ_NAMECHANGE_QUESTION1_CANADA = 'FAQ.NameChange.Question1.Canada';
export const FAQ_NAMECHANGE_ANSWER1_LINK1_VW_CANADA = 'FAQ.NameChange.Answer1.Link1.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_LINK1_AUDI_CANADA = 'FAQ.NameChange.Answer1.Link1.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.NameChange.Answer1.Paragraph1.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.NameChange.Answer1.Paragraph1.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_TITLE_VW_CANADA = 'FAQ.NameChange.Answer1.Paragraph2.Title.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph2.Title.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.NameChange.Answer1.Paragraph2.Content.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph2.Content.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_TITLE_VW_CANADA = 'FAQ.NameChange.Answer1.Paragraph3.Title.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph3.Title.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.NameChange.Answer1.Paragraph3.Content.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph3.Content.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_TITLE_VW_CANADA = 'FAQ.NameChange.Answer1.Paragraph4.Title.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_TITLE_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph4.Title.Audi.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_CONTENT_VW_CANADA =
  'FAQ.NameChange.Answer1.Paragraph4.Content.VW.Canada';
export const FAQ_NAMECHANGE_ANSWER1_PARAGRAPH4_CONTENT_AUDI_CANADA =
  'FAQ.NameChange.Answer1.Paragraph4.Content.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_QUESTION1_VW_CANADA = 'FAQ.InsuranceRequirements.Question1.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_QUESTION1_AUDI_CANADA = 'FAQ.InsuranceRequirements.Question1.Audi.Canada';

export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH_FR_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph.Fr.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH_FR_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph.Fr.Audi.Canada';

export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM1_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem1.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM1_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem1.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM2_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem2.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM2_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem2.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM3_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem3.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM3_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem3.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM4_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem4.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_LISTITEM4_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.ListItem4.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph1.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph1.Audi.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH2_VW_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph2.VW.Canada';
export const FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH2_AUDI_CANADA =
  'FAQ.InsuranceRequirements.Answer1.Paragraph2.Audi.Canada';
export const FAQ_WEBSITESECURITY_QUESTION1_VW_CANADA = 'FAQ.WebSiteSecurity.Question1.VW.Canada';
export const FAQ_WEBSITESECURITY_QUESTION1_AUDI_CANADA = 'FAQ.WebSiteSecurity.Question1.Audi.Canada';
export const FAQ_WEBSITESECURITY_ANSWER1_LINK1_CANADA = 'FAQ.WebSiteSecurity.Answer1.Link1.Canada';
export const FAQ_WEBSITESECURITY_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.WebSiteSecurity.Answer1.Paragraph1.VW.Canada';
export const FAQ_WEBSITESECURITY_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.WebSiteSecurity.Answer1.Paragraph1.Audi.Canada';
export const FAQ_WEBSITEPROBLEMS_QUESTION1_CANADA = 'FAQ.WebSiteProblems.Question1.Canada';
export const FAQ_WEBSITEPROBLEMS_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.WebSiteProblems.Answer1.Paragraph1.VW.Canada';
export const FAQ_WEBSITEPROBLEMS_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.WebSiteProblems.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION1_CANADA = 'FAQ.Lease.General.Question1.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH2_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph2.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH2_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph2.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH3_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph3.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH3_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph3.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH4_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph4.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH4_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph4.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH5_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph5.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH5_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph5.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH6_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph6.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH6_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph6.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH7_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph7.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH7_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph7.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH8_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph8.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH8_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph8.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH9_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph9.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH9_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph9.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH10_VW_CANADA = 'FAQ.Lease.General.Answer1.Paragraph10.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH10_AUDI_CANADA = 'FAQ.Lease.General.Answer1.Paragraph10.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION2_CANADA = 'FAQ.Lease.General.Question2.Canada';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION3_CANADA = 'FAQ.Lease.General.Question3.Canada';
export const FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer3.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer3.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION4_CANADA = 'FAQ.Lease.General.Question4.Canada';
export const FAQ_LEASE_GENERAL_ANSWER4_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer4.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER4_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer4.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION5_CANADA = 'FAQ.Lease.General.Question5.Canada';
export const FAQ_LEASE_GENERAL_ANSWER5_LINK1_CANADA = 'FAQ.Lease.General.Answer5.Link1.Canada';
export const FAQ_LEASE_GENERAL_ANSWER5_LINK2_CANADA = 'FAQ.Lease.General.Answer5.Link2.Canada';
export const FAQ_LEASE_GENERAL_ANSWER5_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer5.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER5_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer5.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION6_CANADA = 'FAQ.Lease.General.Question6.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer6.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer6.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER6_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.General.Answer6.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION7_CANADA = 'FAQ.Lease.General.Question7.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer7.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer7.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER7_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.General.Answer7.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION8_CANADA = 'FAQ.Lease.General.Question8.Canada';
export const FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer8.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer8.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH2_VW_CANADA = 'FAQ.Lease.General.Answer8.Paragraph2.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER8_PARAGRAPH2_AUDI_CANADA = 'FAQ.Lease.General.Answer8.Paragraph2.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION9_CANADA = 'FAQ.Lease.General.Question9.Canada';
export const FAQ_LEASE_GENERAL_ANSWER9_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer9.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER9_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer9.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION10_CANADA = 'FAQ.Lease.General.Question10.Canada';
export const FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH_FR_VW_CANADA = 'FAQ.Lease.General.Answer10.Paragraph.Fr.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH_FR_AUDI_CANADA =
  'FAQ.Lease.General.Answer10.Paragraph.Fr.Audi.Canada';
export const FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer10.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER10_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer10.Paragraph1.Audi.Canada';
export const FAQ_LEASE_GENERAL_QUESTION11_CANADA = 'FAQ.Lease.General.Question11.Canada';
export const FAQ_LEASE_GENERAL_ANSWER11_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.General.Answer11.Paragraph1.VW.Canada';
export const FAQ_LEASE_GENERAL_ANSWER11_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.General.Answer11.Paragraph1.Audi.Canada';
export const FAQ_LEASE_TURNIN_QUESTION1_CANADA = 'FAQ.Lease.TurnIn.Question1.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_LINK1_CANADA = 'FAQ.Lease.TurnIn.Answer1.Link1.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.TurnIn.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph4.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph4.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph4.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph4.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph5.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph5.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph5.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph5.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph6.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph6.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph6.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph6.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph7.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph7.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph7.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph7.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph8.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph8.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph8.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph8.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_TITLE_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph9.Title.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_TITLE_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph9.Title.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_CONTENT_VW_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph9.Content.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9_CONTENT_AUDI_CANADA =
  'FAQ.Lease.TurnIn.Answer1.Paragraph9.Content.Audi.Canada';
export const FAQ_LEASE_TURNIN_QUESTION2_CANADA = 'FAQ.Lease.TurnIn.Question2.Canada';
export const FAQ_LEASE_TURNIN_ANSWER2_LINK1_CANADA = 'FAQ.Lease.TurnIn.Answer2.Link1.Canada';
export const FAQ_LEASE_TURNIN_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.TurnIn.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.TurnIn.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_TURNIN_QUESTION3_VW_CANADA = 'FAQ.Lease.TurnIn.Question3.VW.Canada';
export const FAQ_LEASE_TURNIN_QUESTION3_AUDI_CANADA = 'FAQ.Lease.TurnIn.Question3.Audi.Canada';
export const FAQ_LEASE_TURNIN_ANSWER3_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.TurnIn.Answer3.Paragraph1.VW.Canada';
export const FAQ_LEASE_TURNIN_ANSWER3_PARAGRAPH1_AUDI_CANADA = 'FAQ.Lease.TurnIn.Answer3.Paragraph1.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_QUESTION1_CANADA = 'FAQ.Lease.EarlyTermination.Question1.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_QUESTION2_CANADA = 'FAQ.Lease.EarlyTermination.Question2.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER2_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER2_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_QUESTION3_CANADA = 'FAQ.Lease.EarlyTermination.Question3.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_TITLE_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph1.Title.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_TITLE_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph1.Title.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_CONTENT_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph1.Content.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH1_CONTENT_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph1.Content.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH3_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph3.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH3_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph3.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH4_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph4.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH4_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph4.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH5_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph5.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH5_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph5.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH6_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph6.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH6_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph6.Audi.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH7_VW_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph7.VW.Canada';
export const FAQ_LEASE_EARLYTERMINATION_ANSWER3_PARAGRAPH7_AUDI_CANADA =
  'FAQ.Lease.EarlyTermination.Answer3.Paragraph7.Audi.Canada';
export const FAQ_LEASE_PURCHASING_QUESTION1_CANADA = 'FAQ.Lease.Purchasing.Question1.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.Purchasing.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.Purchasing.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_PURCHASING_QUESTION2_CANADA = 'FAQ.Lease.Purchasing.Question2.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.Purchasing.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.Purchasing.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_PURCHASING_QUESTION3_CANADA = 'FAQ.Lease.Purchasing.Question3.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.Purchasing.Answer3.Paragraph1.VW.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.Purchasing.Answer3.Paragraph1.Audi.Canada';
export const FAQ_LEASE_PURCHASING_QUESTION4_CANADA = 'FAQ.Lease.Purchasing.Question4.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER4_PARAGRAPH1_VW_CANADA = 'FAQ.Lease.Purchasing.Answer4.Paragraph1.VW.Canada';
export const FAQ_LEASE_PURCHASING_ANSWER4_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.Purchasing.Answer4.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION1_CANADA = 'FAQ.Lease.VehicleInspections.Question1.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK1_CANADA = 'FAQ.Lease.VehicleInspections.Answer1.Link1.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION2_CANADA = 'FAQ.Lease.VehicleInspections.Question2.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER2_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer2.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION3_CANADA = 'FAQ.Lease.VehicleInspections.Question3.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER3_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer3.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER3_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer3.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION4_CANADA = 'FAQ.Lease.VehicleInspections.Question4.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER4_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer4.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER4_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer4.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION5_CANADA = 'FAQ.Lease.VehicleInspections.Question5.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER5_LINK1_CANADA = 'FAQ.Lease.VehicleInspections.Answer5.Link1.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER5_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer5.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER5_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer5.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION6_CANADA = 'FAQ.Lease.VehicleInspections.Question6.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER6_LINK1_CANADA = 'FAQ.Lease.VehicleInspections.Answer6.Link1.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER6_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer6.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER6_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer6.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION7_CANADA = 'FAQ.Lease.VehicleInspections.Question7.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER7_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.VehicleInspections.Answer7.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_QUESTION1_CANADA = 'FAQ.Lease.VehicleRepairs.Question1.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_LINK1_CANADA = 'FAQ.Lease.VehicleRepairs.Answer1.Link1.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_VEHICLEREPAIRS_ANSWER1_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.VehicleRepairs.Answer1.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION1_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Question1.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LINK1_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Link1.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph2.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph2.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH3_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph3.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH3_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph3.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem1.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM2_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem2.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM2_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem2.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM3_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem3.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM3_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem3.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM4_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem4.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM4_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem4.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM5_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem5.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM5_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem5.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM6_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem6.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM6_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem6.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM7_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem7.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM7_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem7.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph4.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph4.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph4.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH4_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph4.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM8_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem8.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM8_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem8.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM9_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem9.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM9_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem9.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM10_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem10.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_LISTITEM10_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.ListItem10.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH5_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph5.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH5_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph5.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph6.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph6.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph6.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH6_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph6.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph7.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph7.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph7.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH7_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph7.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH8_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph8.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER1_PARAGRAPH8_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer1.Paragraph8.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION2_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Question2.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph1.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH3_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph3.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER2_PARAGRAPH3_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer2.Paragraph3.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION3_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Question3.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer3.Paragraph1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER3_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer3.Paragraph1.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION4_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Question4.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph1.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph2.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph2.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph2.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph2.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph3.Title.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph3.Title.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph3.Content.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph3.Content.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH4_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph4.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER4_PARAGRAPH4_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer4.Paragraph4.Audi.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_QUESTION5_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Question5.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER5_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer5.Paragraph1.VW.Canada';
export const FAQ_LEASE_FINALLEASESETTLEMENTINVOICE_ANSWER5_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.FinalLeaseSettlementInvoice.Answer5.Paragraph1.Audi.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_QUESTION1_CANADA = 'FAQ.Lease.SecurityDeposit.Question1.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_ANSWER1_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.SecurityDeposit.Answer1.Paragraph1.VW.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_ANSWER1_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.SecurityDeposit.Answer1.Paragraph1.Audi.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_QUESTION2_CANADA = 'FAQ.Lease.SecurityDeposit.Question2.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_ANSWER2_PARAGRAPH1_VW_CANADA =
  'FAQ.Lease.SecurityDeposit.Answer2.Paragraph1.VW.Canada';
export const FAQ_LEASE_SECURITYDEPOSIT_ANSWER2_PARAGRAPH1_AUDI_CANADA =
  'FAQ.Lease.SecurityDeposit.Answer2.Paragraph1.Audi.Canada';
export const FAQ_BALLOON_GENERAL_QUESTION1_VW_CANADA = 'FAQ.Balloon.General.Question1.VW.Canada';
export const FAQ_BALLOON_GENERAL_QUESTION1_AUDI_CANADA = 'FAQ.Balloon.General.Question1.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Balloon.General.Answer1.Paragraph1.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.Balloon.General.Answer1.Paragraph1.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_TITLE_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph2.Title.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_TITLE_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph2.Title.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_CONTENT_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph2.Content.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH2_CONTENT_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph2.Content.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_TITLE_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph3.Title.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_TITLE_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph3.Title.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_CONTENT_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph3.Content.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH3_CONTENT_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph3.Content.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_TITLE_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph4.Title.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_TITLE_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph4.Title.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_CONTENT_VW_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph4.Content.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH4_CONTENT_AUDI_CANADA =
  'FAQ.Balloon.General.Answer1.Paragraph4.Content.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH5_VW_CANADA = 'FAQ.Balloon.General.Answer1.Paragraph5.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH5_AUDI_CANADA = 'FAQ.Balloon.General.Answer1.Paragraph5.Audi.Canada';
export const FAQ_BALLOON_GENERAL_QUESTION2_VW_CANADA = 'FAQ.Balloon.General.Question2.VW.Canada';
export const FAQ_BALLOON_GENERAL_QUESTION2_AUDI_CANADA = 'FAQ.Balloon.General.Question2.Audi.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Balloon.General.Answer2.Paragraph1.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.Balloon.General.Answer2.Paragraph1.Audi.Canada';
export const FAQ_BALLOON_GENERAL_QUESTION3_CANADA = 'FAQ.Balloon.General.Question3.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1_VW_CANADA = 'FAQ.Balloon.General.Answer3.Paragraph1.VW.Canada';
export const FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1_AUDI_CANADA = 'FAQ.Balloon.General.Answer3.Paragraph1.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_QUESTION1_CANADA = 'FAQ.Balloon.Payoff.Question1.Canada';
export const FAQ_BALLOON_PAYOFF_QUESTION1_LINK1_CANADA = 'FAQ.Balloon.Payoff.Answer1.Link1.Canada';
export const FAQ_BALLOON_PAYOFF_QUESTION1_LINK2_CANADA = 'FAQ.Balloon.Payoff.Answer1.Link2.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH1_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph1.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH1_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph1.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH2_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph2.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH2_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph2.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH3_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph3.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH3_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph3.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH4_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph4.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH4_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph4.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH5_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph5.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH5_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph5.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH6_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph6.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH6_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph6.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH7_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph7.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH7_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph7.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH8_VW_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph8.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER1_PARAGRAPH8_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer1.Paragraph8.Audi.Canada';
export const FAQ_BALLOON_PAYOFF_QUESTION2_CANADA = 'FAQ.Balloon.Payoff.Question2.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER2_PARAGRAPH1_VW_CANADA = 'FAQ.Balloon.Payoff.Answer2.Paragraph1.VW.Canada';
export const FAQ_BALLOON_PAYOFF_ANSWER2_PARAGRAPH1_AUDI_CANADA = 'FAQ.Balloon.Payoff.Answer2.Paragraph1.Audi.Canada';

// Default / Welcome Message
export const DEFAULT_MESSAGE_SUBJECT = 'Default.Message.Subject';
export const DEFAULT_MESSAGE_AUTHOR = 'Default.Message.Author';
export const DEFAULT_MESSAGE_GREETING = 'Default.Message.Greeting';
export const DEFAULT_MESSAGE_WELCOME_MSG_VW = 'Default.Message.Welcome.Message.VW';
export const DEFAULT_MESSAGE_WELCOME_MSG_AUDI = 'Default.Message.Welcome.Message.Audi';
export const DEFAULT_MESSAGE_PARAGRAPH1 = 'Default.Message.Paragraph1';
export const DEFAULT_MESSAGE_LIST_ITEM_ONE_VW = 'Default.Message.List.Item.One.VW';
export const DEFAULT_MESSAGE_LIST_ITEM_ONE_AUDI = 'Default.Message.List.Item.One.Audi';
export const DEFAULT_MESSAGE_LIST_ITEM_TWO = 'Default.Message.List.Item.Two';
export const DEFAULT_MESSAGE_LIST_ITEM_THREE = 'Default.Message.List.Item.Three';
export const DEFAULT_MESSAGE_LIST_ITEM_FOUR = 'Default.Message.List.Item.Four';
export const DEFAULT_MESSAGE_LIST_ITEM_FIVE = 'Default.Message.List.Item.Five';
export const DEFAULT_MESSAGE_PARAGRAPH2 = 'Default.Message.Paragraph2';
/* eslint-enable import/prefer-default-export */

// DOCUMENT CENTER
export const DOCUMENT_CENTER_TITLE = 'DocumentCenter.Title';
export const DOCUMENT_CENTER_YEAR = 'DocumentCenter.Filters.Year';
export const DOCUMENT_CENTER_YEAR_ALL = 'DocumentCenter.Filters.Year.All';
export const DOCUMENT_CENTER_TYPE = 'DocumentCenter.Filters.Type';
export const DOCUMENT_CENTER_TYPE_ALL = 'DocumentCenter.Filters.Type.All';
export const DOCUMENT_CENTER_TABLE_HEADER_DATE = 'DocumentCenter.Table.Header.Date';
export const DOCUMENT_CENTER_TABLE_HEADER_DOWNLOAD = 'DocumentCenter.Table.Header.Download';
export const DOCUMENT_CENTER_TABLE_HEADER_NAME = 'DocumentCenter.Table.Header.Name';
export const DOCUMENT_CENTER_BUTTON_LOAD_MORE = 'DocumentCenter.Button.LoadMore';

export const DOCUMENT_CENTER_MOST_RECENT = 'DocumentCenter.MostRecent';
export const DOCUMENT_CENTER_ZERO_BALANCE_LETTER = 'DocumentCenter.ZeroBalanceLetter';
export const DOCUMENT_CENTER_TURN_IN_LETTER = 'DocumentCenter.TurnInLetter';
export const DOCUMENT_CENTER_PAID_IN_FULL_LETTER = 'DocumentCenter.PaidInFullLetter';

export const DOCUMENT_CENTER_NOTIFICATION_ERROR = 'DocumentCenter.Notification.Error.Content';
export const DOCUMENT_CENTER_NOTIFICATION_ERROR_2 = 'DocumentCenter.Notification.Error.Content2';
export const DOCUMENT_CENTER_NOTIFICATION_SUCCESS = 'DocumentCenter.Notification.Success.Content';
export const DOCUMENT_CENTER_ERROR_TITLE = 'DocumentCenter.Error.Title';
export const DOCUMENT_CENTER_ERROR_CONTENT = 'DocumentCenter.Error.Content';
export const DOCUMENT_CENTER_MYAUDI_CONTENT_ONE = 'DocumentCenter.MyAudi.Content.One';
export const DOCUMENT_CENTER_MYAUDI_CONTENT_TWO = 'DocumentCenter.MyAudi.Content.Two';

export const DOCUMENT_CENTER_NO_DOCS_TITLE = 'DocumentCenter.No.Docs.Title';
export const DOCUMENT_CENTER_NO_DOCS_NOTE = 'DocumentCenter.No.Docs.Note';
