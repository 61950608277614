import React from 'react';

interface ActiveIconProps {
  // eslint-disable-next-line react/require-default-props
  active?: boolean;
}

const ActiveIcon: React.FC<ActiveIconProps> = ({ active = false }: ActiveIconProps) => {
  if (!active) return <></>;
  return <i className='c-link__icon  c-icon  c-icon--[semantic-forward]  ' aria-hidden='true' role='img' />;
};

export default ActiveIcon;
