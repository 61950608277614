import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as dateFormat } from 'date-fns';
import englishUSA from './en-us.json';
import frenchCAN from './fr-ca.json';
import englishCA from './en-ca.json';

const resourceContent = {
  'en-US': {
    translation: englishUSA,
  },
  'en-CA': {
    translation: englishCA,
  },
  'fr-CA': {
    translation: frenchCAN,
  },
};

i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init(
    {
      interpolation: {
        // React already does escaping
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        format: (value: any, format?: string) => {
          if (value instanceof Date) {
            return dateFormat(value, format || 'MMM dd, yyyy');
          }
          return value;
        },
        escapeValue: false,
      },
      // 'en' | 'es'
      lng: i18n.language,
      fallbackLng: 'en-US',
      resources: resourceContent,
      keySeparator: '.',
    },
    () => {}
  );

export default i18n;
