/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import globalConstants from 'src/constants/globalConstants';
import { useMessageContext } from 'src/context/MessageContext';
import {
  FAQ_PATH,
  INBOX_PATH,
  MESSAGE_CENTER_INBOX_PATH,
  DOCUMENT_CENTER_PATH,
  DOCUMENT_PATH,
} from 'src/constants/routePaths';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';
import LinkItem from '../sidebar-nav/LinkItem';
import BackLink from '../sidebar-nav/BackLink';

interface TopBarProps {
  brand: string;
  country: string;
}

const TopBar: React.FC<TopBarProps> = ({ brand, country }: TopBarProps) => {
  const location = useLocation();
  const { newmessageCount } = useMessageContext();
  const { pathname } = location;
  const [focusedItem, setFocusedItem] = useState('');

  const faqRef = useRef<HTMLDivElement>(null);
  const messageCenterRef = useRef<HTMLDivElement>(null);
  const docCenterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusedItem === 'faq' && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    } else if (focusedItem === 'messageCenter' && messageCenterRef.current) {
      messageCenterRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    } else if (focusedItem === 'docCenter' && docCenterRef.current) {
      docCenterRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [focusedItem]);

  return (
    <div data-testid='topbar' className='topbar-container' role='navigation' aria-label='main navigation'>
      <ul className='topbar-top'>
        <li className='asidebar-nav__item'>
          <div id='backLinkContainer' className='asidebar-nav__item__label'>
            <i
              id='backLinkIcon'
              className='c-link__icon  c-icon  c-icon--[semantic-back]  '
              aria-hidden='true'
              role='img'
            />
            <BackLink data-testid='back-link' label={i18n.t(rk.BACK_LINK)} mobile />
          </div>
        </li>
      </ul>
      <div className='topbar-navbar'>
        <div className='c-tabs'>
          <nav aria-label='Tabs Navigation'>
            <ul className='c-tabs__nav'>
              <div ref={faqRef} onClick={() => setFocusedItem('faq')}>
                <LinkItem
                  data-testid='link-item-faqs'
                  label={i18n.t(rk.FAQ_COMPONENT_NAME)}
                  to={FAQ_PATH}
                  active={pathname === `/message-center/FAQs`}
                  badge={0}
                  brand={brand}
                  navPosition={globalConstants.NAVBAR_POSITION_TOP}
                />
              </div>
              <div ref={messageCenterRef} onClick={() => setFocusedItem('messageCenter')}>
                <LinkItem
                  data-testid='link-item-message-center'
                  label={i18n.t(rk.MESSAGECENTER_COMPONENT_NAME)}
                  to={INBOX_PATH}
                  active={pathname.includes(`${MESSAGE_CENTER_INBOX_PATH}`)}
                  badge={newmessageCount}
                  brand={brand}
                  navPosition={globalConstants.NAVBAR_POSITION_TOP}
                />
              </div>
              {country === globalConstants?.USA && (
                <div ref={docCenterRef} onClick={() => setFocusedItem('docCenter')}>
                  <LinkItem
                    data-testid='link-item-doc-center'
                    label={i18n.t(rk.DOCUMENT_CENTER_TITLE)}
                    to={DOCUMENT_PATH}
                    active={pathname.includes(`${DOCUMENT_CENTER_PATH}`)}
                    brand={brand}
                    badge={0}
                    navPosition={globalConstants.NAVBAR_POSITION_TOP}
                  />
                </div>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
