/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, ChangeEvent } from 'react';

interface WordCountTextAreaProps {
  characterLimit: number;
  columns?: number;
  rows?: number;
  id: string;
  placeholder: string;
  onChange: (a: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (a: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  children?: React.ReactNode;
  hasError: boolean;
}

const WordCountTextArea: React.FC<WordCountTextAreaProps> = ({
  characterLimit,
  columns = 20,
  rows = 10,
  id,
  placeholder,
  onChange,
  onBlur,
  value,
  children,
  hasError,
}: WordCountTextAreaProps) => {
  const [currentCharacterCount, setCharacterCount] = useState(0);

  /**
   * Handle change event and extra safety for the characterLimit to be enforced.
   * @param event ChangeEvent<HtmlTextAreaElement>
   */
  const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length <= characterLimit) {
      setCharacterCount(inputValue.length);
    }
    onChange(event);
  };

  return (
    <div className='o-fieldset__row word-count-textarea'>
      <div className={`c-form-field  c-form-field--floating-textarea ${hasError ? 'is-error' : ''}`}>
        <div className='c-form-field__box'>
          <div className='c-input  c-input--textarea'>
            <textarea
              data-testid='word-count-textarea'
              className='c-input__input'
              id={id}
              name={id}
              cols={columns}
              rows={rows}
              maxLength={characterLimit}
              onChange={(e) => handleChangeInput(e)}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
            />
            {children}
          </div>
        </div>
      </div>
      <span data-testid='word-count-indicator' className='word-count-indicator'>
        {currentCharacterCount} / {characterLimit}
      </span>
    </div>
  );
};

export default WordCountTextArea;
