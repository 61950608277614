import gql from 'graphql-tag';

const getDocumentList = gql`
  query GetAllDocs($CorrelationId: String!, $AccountNumber: String!) {
    AllDocTypesList(Request: { CorrelationId: $CorrelationId, AccountNumber: $AccountNumber }) {
      Invoices {
        AccountNumber
        SequenceNumber
        InvoiceDate
        Pid
        Guid
        Mp
      }
      Documents {
        DocumentFileTypeName
        DocumentName
        DocumentTypeName
        DocumentVersion
        CreateDate
        AppId
        ExternalDocumentID
      }
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getInvoice = gql`
  query GetInvoice(
    $CorrelationId: String!
    $AccountNumber: String!
    $SequenceNumber: String!
    $Pid: String!
    $Guid: String!
    $Mp: String!
    $Source: String!
  ) {
    Invoice(
      Request: {
        CorrelationId: $CorrelationId
        AccountNumber: $AccountNumber
        SequenceNumber: $SequenceNumber
        Pid: $Pid
        Guid: $Guid
        Mp: $Mp
        Source: $Source
      }
    ) {
      Invoice
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getLoanAgreement = gql`
  query GetDocument(
    $CorrelationId: String!
    $AccountNumber: String!
    $ExternalDocumentId: String!
    $Source: String!
    $DocumentType: String
  ) {
    Document(
      Request: {
        CorrelationId: $CorrelationId
        AccountNumber: $AccountNumber
        ExternalDocumentId: $ExternalDocumentId
        Source: $Source
        DocumentType: $DocumentType
      }
    ) {
      Document
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getAccountsByPartyId = gql`
  query GetAccountsByPartyId($Request: AccountsRequest!) {
    GetAccountsByPartyId(Request: $Request) {
      Accounts {
        AccountNumber
        AccountId
        IsAccountClosedNotEditable
        IsDisabled
        AccountStatusDate
        AccountStatus
        TerminationStatus
        TermTypeStatus
        Finance {
          FinanceType
          DetailedAccountType
          AccountType
        }
        Payment {
          CurrentBalance
          DueAmount {
            Current {
              TotalAmount
            }
            Past {
              TotalAmount
            }
            TotalAmount
          }
        }
        Customer {
          PartyRoleType
        }
        PartyId
        TerminationStatus
        TermTypeStatus
        BankruptcyStatus
        RepossessionStatus
      }
    }
  }
`;

const getDocumentEligibility = gql`
  query GetDocumentEligibility($CorrelationId: String!, $AccountNumber: String!, $Country: Country!) {
    GetDocumentEligibility(
      Request: { CorrelationId: $CorrelationId, AccountNumber: $AccountNumber, Country: $Country }
    ) {
      IsPaidInFullLetterEligible
      IsSuccess
      IsTurnInLetterEligible
      IsZeroBalanceLetterEligible
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getPaidInFullLetter = gql`
  query PaidInFullLetter($CorrelationId: String!, $AccountNumber: String!, $Source: String!) {
    GetPaidInFullLetter(Request: { CorrelationId: $CorrelationId, AccountNumber: $AccountNumber, Source: $Source }) {
      DocumentContent
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getZeroBalanceLetter = gql`
  query ZeroBalanceLetter($CorrelationId: String!, $AccountNumber: String!, $Source: String!) {
    GetZeroBalanceLetter(Request: { CorrelationId: $CorrelationId, AccountNumber: $AccountNumber, Source: $Source }) {
      DocumentContent
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const getTurnInLetter = gql`
  query TurnInLetter($CorrelationId: String!, $AccountNumber: String!, $Source: String!) {
    GetTurnInLetter(Request: { CorrelationId: $CorrelationId, AccountNumber: $AccountNumber, Source: $Source }) {
      DocumentContent
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export {
  getInvoice,
  getDocumentList,
  getLoanAgreement,
  getAccountsByPartyId,
  getDocumentEligibility,
  getPaidInFullLetter,
  getZeroBalanceLetter,
  getTurnInLetter,
};
