export const LOGIN_PATH = '/login';
export const MAIN_PATH = 'message-center';
export const PROFILE_PATH = '/account';
export const PAYMENT_OPTIONS_PATH = '/paymentOptions';

// Message Center
export const INBOX_PATH = 'inbox';
export const FAQ_PATH = 'FAQs';
export const MESSAGE_CENTER_INBOX_PATH = 'message-center/inbox';
export const MESSAGE_CENTER_CREATE_NEW_MESSAGE = 'message-center/inbox/new-message';
export const CREATE_NEW_MESSAGE_FROM_FAQ = '../inbox/new-message';

// FAQ
export const MESSAGE_CENTER_FAQ_PATH = 'message-center/FAQs';

/* This links to the parent route  when is used with navigate()  as `cd ../` */
export const LINK_TO_PARENT_PATH = '..';
export const LINK_TO_CURRENT_PATH = '.';

// Document Center
export const DOCUMENT_CENTER_PATH = '/documents';
export const DOCUMENT_PATH = 'documents';
