import React, { useState } from 'react';
import { INotificationContext, INotification } from './NotificationContext.interface';
import Notification from '../views/components/common/notification/Notification';

export const NotificationContext = React.createContext<INotificationContext | null>(null);

export const NotificationProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<INotification>>([]);

  const removeNotification = (id: string) => {
    const result = notifications.filter((c) => c.id !== id);
    setNotifications(result);
  };
  const addNotification = (notification: INotification) => {
    setNotifications((notificationsOld) => [...notificationsOld, notification]);
  };

  const value: INotificationContext = {
    addNotification,
    removeNotification,
    notifications,
  };

  return (
    <>
      {notifications.length > 0 ? (
        <aside className='c-toast-notifications  js-toast-notifications'>
          {notifications.map((notification) => (
            <Notification notification={notification} removeNotification={removeNotification} key={notification.id} />
          ))}
        </aside>
      ) : (
        ''
      )}
      <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
    </>
  );
};
