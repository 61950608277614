import { IAuthState } from 'src/context/AuthContext';
import { IMessageResponse } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const ALL_MESSAGE_ENDPOINT = '/messages';

const getAllMessages = (auth: IAuthState): Promise<IMessageResponse> => {
  return apiClient
    .get(ALL_MESSAGE_ENDPOINT, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getAllMessages');
      return response.data;
    });
};

export default getAllMessages;
