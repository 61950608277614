import React, { ReactNode, useState, useEffect, createContext, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postMessage } from 'src/utility/Utilities';
import componentConstants from 'src/constants/componentConstants';
import { TEST_HARNESS_PATH_NAME } from 'src/constants/globalConstants';
import initializeToken from '../services/auth/initialize-token';
import { set, remove } from '../services/session-service';
import { LINK_TO_CURRENT_PATH, LOGIN_PATH } from '../constants/routePaths';
import { Brand, Country, UIBrand } from '../types';

enum StatusEnum {
  INITIAL = 'INITIAL',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

/**
 * brand - the brand Audi, VW, ....
 * uiBrand - the "Bronson" brand. Audi, VW6, VW, ...
 * country - USA, CAN
 * accountNumber - the account nunmber
 * vin - the vin for the account
 * token - the token for auth
 * externalId - the external id for the brand site. For portal it is undefined
 * partyId - the partyId of the user
 * acceptLanguage - en-US. Language code passed. When passed it overrides browser language code
 * status - "success", "pending", "error" - TODO - add type
 * tokenExpTime - expiration time of token
 * correlationId - correlation if created to start the portal
 * isSuccess - boolean - returned from API but removed when storing in state
 */
export interface IAuthState {
  brand: Brand;
  uiBrand: UIBrand;
  country: Country;
  accountNumber: string;
  accountType: string;
  externalId: string | undefined;
  vin?: string;
  token: string;
  partyId: string;
  acceptLanguage?: string;
  status: keyof typeof StatusEnum;
  tokenExpTime?: Date | null;
  correlationId?: string;
  tokenAboutExp?: boolean;
  sourceId: string | undefined;
  // path is to simulate deep linking for storybook test harness passing from authProps
  path: string | undefined;
}
export interface AuthContextType {
  // set the type of state you want to handle with context
  state: IAuthState;
  logout: (withNavigation: boolean) => void;
  isTokenExpired: () => boolean;
  isAccountNumberChange: boolean;
  changeAccountNumber: (hasChanged: boolean) => void;
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
function createCtx(tag: string) {
  const ctx = createContext<AuthContextType | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error(`${tag} -- useContext must be inside a Provider with a value`);
    return c;
  }
  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

// We still have to specify a type, but no default!
export const [useAuthContext, CtxProvider] = createCtx('AuthContext');

type Props = {
  children: ReactNode;
  value: null | {
    brand: Brand;
    uiBrand: UIBrand;
    country: Country;
    accountNumber: string;
    accountType: string;
    externalId: string;
    token: string;
    partyId: string;
    correlationId: string;
    path: string | undefined;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consumerProps: any | undefined;
};

export const AuthContextProvider = (props: Props): JSX.Element => {
  const { children, value, consumerProps = {} } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const accountNumber = value?.accountNumber ?? '';
  const initializedToken = consumerProps?.token ?? value?.token;

  // If we have a token in appStorage we should remove it and let it be only on Auth state.

  if (consumerProps.accessToken) {
    delete consumerProps.accessToken;
    set('appProps', JSON.stringify({ ...consumerProps }));
  }

  const initialState = {
    // value props use to be localstorage authprops, see the provider
    brand: value?.brand ?? Brand.AUDI,
    uiBrand: value?.uiBrand ?? UIBrand.AUDI,
    country: value?.country ?? Country.USA,
    accountNumber: value?.accountNumber ?? '',
    accountType: value?.accountType ?? '',
    externalId: value?.externalId ?? '',
    status: StatusEnum.INITIAL,
    partyId: value?.partyId ?? '',
    vin: '',
    token: value?.token ?? '',
    tokenExpTime: null,
    tokenAboutExp: false,
    correlationId: value?.correlationId ?? undefined,
    path: value?.path ?? undefined,
  };
  const [state, setState] = useState<IAuthState>(initialState);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [modalTimer, setModalTimer] = useState<NodeJS.Timeout | null>(null);
  const [isAccountNumberChange, setIsAccountNumberChanged] = useState(false);
  const isTokenExpired = () => {
    if (!state.token || !state.tokenExpTime) {
      return true;
    }
    const currentTime = new Date(Date.now());
    return currentTime > state.tokenExpTime;
  };

  const changeAccountNumber = (hasChanged: boolean) => {
    setIsAccountNumberChanged(hasChanged);
  };

  useEffect(() => {
    const updateTokenListener = (event: any) => {
      const tokenToUpdate = event?.detail?.token;
      setState((prevState) => ({ ...prevState, token: tokenToUpdate }));
    };
    // listener for refreshing token event, to sync up the new token with the context
    window.addEventListener('token-updated', updateTokenListener);
  }, []);

  // this useEffect is to identify if the user is logged out
  useEffect(() => {
    // If you are in logout page does not apply
    if (location.pathname === LOGIN_PATH) return undefined;

    // if the props from auth does not exists in local storage it means the user is not logged in

    return undefined;
  }, [location]);

  const logout = () => {
    remove('authProps');

    const newState = { ...initialState, status: StatusEnum.UNAUTHENTICATED };

    setState(newState);

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (modalTimer) {
      clearTimeout(modalTimer);
      setModalTimer(null);
    }
  };

  /**
   * Gets initialize token from account number,
   * function gets called when account number is resolved in account context
   * @param {string} accountNumber
   * @returns {void}
   */
  const initializeTokenFlow = (payload: { token: string; accountNumber: string }) => {
    return initializeToken(null, payload.token, payload.accountNumber).then(
      (response: { status: number; data: Record<string, unknown> }) => {
        // set initializeToken flag as true and status as success as well.
        const newState = { ...state, ...(response.data ?? {}), status: StatusEnum.SUCCESS };

        setState(newState);

        const storedProps = { ...newState };
        delete storedProps.tokenExpTime;
        // save in storage

        set('authProps', JSON.stringify(storedProps));

        // send message notification to parent domain if it exists
        const data: Partial<IAuthState> = {
          ...newState,
        };
        delete data.token;
        delete data.tokenExpTime;
        postMessage(consumerProps.parentDomain, componentConstants.MessageTypes.AUTH_SUCCESS, data);
      },
      (error) => {
        setState({
          ...state,
          status: StatusEnum.ERROR,
        });
        remove('authProps');
        const data = { error: { name: error.name, message: error.message, accountNumber: payload.accountNumber } };
        postMessage(consumerProps.parentDomain, componentConstants.MessageTypes.AUTH_ERROR, data);
      }
    );
  };

  useEffect(() => {
    if (initializedToken) {
      // the token is already got before, now we work with token from localstorage
      setState((preState) => ({ ...preState, status: StatusEnum.SUCCESS }));
      // if message center is consumed as remote federation component from storybook test harness we need to change to authProps path in order to simulate deep linking
      if (location.pathname === TEST_HARNESS_PATH_NAME || !!state.path) {
        navigate(state.path || LINK_TO_CURRENT_PATH, { replace: true });
      }

      if (location.pathname !== LOGIN_PATH) return;

      navigate(LINK_TO_CURRENT_PATH, { replace: true });
    }

    // call just when status is `initial`, avoiding multiple calls
    if (state.status !== 'INITIAL') {
      return;
    }

    initializeTokenFlow({ accountNumber: value?.accountNumber ?? '', token: value?.token ?? '' }).then(() => {
      navigate(LINK_TO_CURRENT_PATH, { replace: true });
    });
  }, [props]);

  // update accountNumber whenever the vehicle is changed.
  useEffect(() => {
    const newState = { ...state };
    if (accountNumber && newState.accountNumber !== accountNumber) {
      newState.accountNumber = accountNumber;
      setState({ ...newState });
      setIsAccountNumberChanged(true);
    }
  }, [accountNumber]);
  return (
    <CtxProvider value={{ state, logout, isTokenExpired, isAccountNumberChange, changeAccountNumber }}>
      {children}
    </CtxProvider>
  );
};
