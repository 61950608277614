import { IAuthState } from 'src/context/AuthContext';
import { AttachmentsResponse } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const ATTACHMENTS_ENDPOINT = '/attachments';
/**
 * Get attachments from specific message with message id
 * @param {string | number} messageId
 * @param auth
 * @returns
 */

const getAttachments = (messageId: string | number, auth: IAuthState): Promise<AttachmentsResponse> => {
  return apiClient
    .get(`${ATTACHMENTS_ENDPOINT}/${messageId}`, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getAttachments');
      return response.data;
    });
};

export default getAttachments;
