import React, { useEffect, useState, useRef } from 'react';
import globalConstants from 'src/constants/globalConstants';
import getAccountsByPartyIdService from 'src/services/message-center/get-accounts';
import { useAuthContext } from './AuthContext';
import { useLoader } from './LoadingContext';
import { IAccountContext, IAccounts } from './AccountContext.interface';

export const AccountContext = React.createContext<IAccountContext | null>(null);

export const AccountProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [accounts, setAccounts] = useState<IAccounts>();
  const [error, setError] = useState<boolean>(false);

  const { state: authState } = useAuthContext();
  const { stopLoader, startLoader, isLoading } = useLoader({
    isLoading: true,
    local: false,
    key: 'AccountsContextProvider',
  });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const isUnmountedRef = useRef(false);

  const getAccountsByPartyId = async () => {
    try {
      if (!isLoading) startLoader();
      if (!firstLoad) {
        const response = await getAccountsByPartyIdService(authState);
        if (response.GetAccountsByPartyId && response.GetAccountsByPartyId.Accounts) {
          const account = response.GetAccountsByPartyId.Accounts.find(
            (accountData) => accountData.AccountNumber === authState.accountNumber
          );
          setAccounts(account);
          setFirstLoad(true);
        } else {
          stopLoader();
          throw new Error();
        }
      }
      stopLoader();
    } catch (e) {
      setError(true);
      // HANDLE API ERROR
      stopLoader();
    }
  };

  const value: IAccountContext = {
    accounts,
    error,
    getAccountsByPartyId,
  };

  const fetchAccounts = async () => {
    await getAccountsByPartyId();
  };

  useEffect(() => {
    if (!authState.token) return () => {};
    if (!isUnmountedRef.current) fetchAccounts();

    // eslint-disable-next-line consistent-return
    return () => {
      isUnmountedRef.current = true;
    };
  }, [authState.token]);

  return (
    <>
      <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
    </>
  );
};
