const VWConstants = {
  trackerBackgroundColor: '#d5d6d6',
  trackerGradientStartColor: '#00b0f0',
  trackerGradientEndColor: '#00b0f0',
  SSOLinkUs: `${process.env.REACT_APP_CI_SSO_LINK_HOST_VW_US}/authentication/autologin`,
  SSOLinkCa: `${process.env.REACT_APP_CI_SSO_LINK_HOST_VW_CA}/authentication/autologin`,
  faqLinkUs: `${process.env.REACT_APP_CI_SSO_LINK_HOST_VW_US}/CustomerSupport/FAQ`,
  faqlinkCa: `${process.env.REACT_APP_CI_SSO_LINK_HOST_VW_CA}/CustomerSupport/FAQ`,
  defaultImageUrl: 'https://cdn.bronson.vwfs.tools/vw6/v/1.12.0/img/logo.svg',
  tncLinkAutopay: `${process.env.REACT_APP_TNC_HOST_AUDI_US}/vw/autopay`,
  tncLinkACH: `${process.env.REACT_APP_TNC_HOST_AUDI_US}/vw/ach`,
  accountTypeLease: 'Lease',
  accountTypeLoan: 'Loan',
  accountTypePremier: 'Premier Purchase',
  vwTelephone: '+18004284034',
  vwCancelPaymentView: true,
  vwCancelConfirmationBannerIcon: true,
  inActiveAccountShowIcon: true,
  paymentSource: 'vwc portal',
  vwView: true,
};

export default VWConstants;
