/* eslint-disable @typescript-eslint/no-explicit-any */
import AudiConstants from './Audiconstants';
import VWConstants from './VWConstants';
import globalConstants from './globalConstants';
import componentConstants from './componentConstants';

/* eslint-disable import/no-mutable-exports */
export let constants: any = {};
// TODO - KWC - there is a more concise means to get constants. Use brand in path and keep all file names the same

export const updateConstants = (brand: string): any => {
  switch (brand) {
    case 'vw':
    case 'vw6':
      constants = {
        ...constants,
        ...globalConstants,
        ...componentConstants,
        ...VWConstants,
      };
      break;
    case 'audi':
    default:
      constants = {
        ...constants,
        ...globalConstants,
        ...componentConstants,
        ...AudiConstants,
      };
      break;
  }
};
