import { IAuthState } from 'src/context/AuthContext';
import axios from 'axios';
import { print } from 'graphql';
import { v4 as uuid } from 'uuid';
import { getAccountsByPartyId } from '../../utility/graphql-queries';

const getAccountsByPartyIdService = async (authState: IAuthState) => {
  // GraphQL Call
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_OWNPORTAL_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getAccountsByPartyId),
        variables: {
          Request: {
            correlationId: `${uuid()}`,
          },
        },
      },
    });
    return response?.data?.data;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetAccountsByPartyId => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

export default getAccountsByPartyIdService;
