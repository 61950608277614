import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Spinner from '../views/components/common/spinner/Spinner';

type Props = {
  children: ReactNode;
};

// type ISpinnerComponent = ({ children }: { children: React.ReactNode }) => JSX.Element;

interface LoadingContextType {
  // isLoading state
  isLoading: boolean;
  // isLoading dispatcher
  startLoader: () => void;
  stopLoader: () => void;
  setLocal: (val: boolean) => void;
  Spinner: React.ReactNode;
}

export const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

let mounted = false;
/**
 * by default `isLoading` and `local` are initialized with false values.
 */
export const useLoader = ({ isLoading = false, local = false } = {}): LoadingContextType => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoader must be used within a CountProvider');
  }

  useEffect(() => {
    mounted = true;

    if (!mounted) return undefined;

    if (local && isLoading === false) return context.stopLoader();

    context.setLocal(local);

    return () => {
      mounted = false;
    };
  }, [isLoading]);

  return context;
};

export const LoadingContextProvider = (props: Props): React.ReactElement => {
  const { children } = props;

  const [local, setLocal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const startLoader = () => setLoading(true);
  const stopLoader = () => setLoading(false);

  const SpinnerComponent = ({ children: child }) => (isLoading ? <Spinner ariaLabel /> : child);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoader, stopLoader, Spinner: SpinnerComponent, setLocal }}>
      {!local && <Spinner ariaLabel={isLoading} spinnerType='fullscreen' />}
      {children}
    </LoadingContext.Provider>
  );
};
