import React, { useContext, useEffect, useState } from 'react';
import './DocumentsTable.scss';
import globalConstants from 'src/constants/globalConstants';
import { ILoanAgreements, IStatements } from '../../../context/DocCenterContext.interface';
import { INotificationContext } from '../../../context/NotificationContext.interface';
import { NotificationContext } from '../../../context/NotificationContext';
import { getStatementsService, getLoanAgreementService } from '../../../services/message-center/get-documents';
import { useAuthContext } from '../../../context/AuthContext';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import { uuidv4, b64toBlob } from '../../../utility/Utilities';

type Props = {
  file: IStatements | ILoanAgreements;
};

const TableRow = (props: Props) => {
  const { file } = props;
  const { addNotification, notifications, removeNotification } = useContext(
    NotificationContext
  ) as INotificationContext;
  const [id] = useState<string>(uuidv4());
  const [status, setStatus] = useState<string>('notDownloading');
  const { state: authState } = useAuthContext();
  let docDate;
  if (file.InvoiceDate) {
    docDate = new Date(file.InvoiceDate).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  } else if (file.CreateDate) {
    docDate = new Date(file.CreateDate).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  useEffect(() => {
    const find = notifications.find((c) => c.id === id);
    let timer: ReturnType<typeof setTimeout>;
    if (!find) {
      if (status === 'error' || status === 'success') {
        setStatus('notDownloading');
      }
    } else {
      timer = setTimeout(() => {
        removeNotification(id);
      }, 5500);
    }
    return () => clearTimeout(timer);
  }, [notifications]);

  useEffect(() => {
    // FILTERING RESETS DOWNLOAD STATE
    setStatus('notDownloading');
  }, [file]);

  const handleClick = async (clickedDocument: IStatements) => {
    try {
      setStatus('downloading');
      let response;

      if (clickedDocument?.InvoiceDate) {
        response = await getStatementsService(authState, clickedDocument);
      } else if (clickedDocument?.DocumentFileTypeName) {
        const documentType = clickedDocument?.DocumentName?.toLowerCase().includes('autopay') ? 'Autopay' : 'Statement';
        response = await getLoanAgreementService(authState, clickedDocument, documentType);
      }

      if (response.IsSuccess) {
        // DOWNLOAD THE FILE
        // TODO - File name should be changed
        const fileName = 'Document.pdf';

        // Create the anchor element to trigger the download.
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);

        // Generate BLOB.
        const blobData = b64toBlob(
          clickedDocument?.InvoiceDate ? response.Invoice : response.Document,
          'application/pdf'
        );

        // Add the href attribute with the blob data and click it.
        downloadLink.href = window.URL.createObjectURL(blobData);
        downloadLink.setAttribute('download', fileName);
        downloadLink.click();
        setStatus('success');

        // Clean up the element we created for the download.
        window.URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);

        addNotification({
          message: i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_SUCCESS),
          style: 'success',
          action: () => true,
          id,
        });
      } else {
        addNotification({
          message: i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR),
          style: 'error',
          action: () => {
            handleClick(clickedDocument);
          },
          id,
        });
        setStatus('error');
      }
    } catch (error) {
      addNotification({
        message: i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR),
        style: 'error',
        action: () => {
          handleClick(clickedDocument);
        },
        id,
      });
      setStatus('error');
    }
  };

  const getFileName = () => {
    let fileName;
    if (file?.DocumentName?.toLowerCase().includes('autopay')) {
      fileName = globalConstants.DOCUMENT_MAPPING.AutopayLetter;
    } else if (file?.InvoiceDate) {
      fileName = globalConstants.DOCUMENT_MAPPING.Statements;
    } else if (file?.DocumentFileTypeName) {
      fileName = globalConstants.DOCUMENT_MAPPING.Loan;
    }
    return fileName;
  };

  return (
    <>
      <tr className='doc-table-col doc-table-col__body'>
        <td className='doc-table-col__date' data-columnheader='Date'>
          <div className='doc-table-col__text'>{docDate}</div>
        </td>
        <td className='doc-table-col__download' data-columnheader='Download'>
          <button
            onClick={() => {
              if (status === 'notDownloading') handleClick(file);
            }}
            type='button'
            className='c-link  c-download-link'
          >
            <div className={`status ${authState.brand.toLowerCase()}`}>
              {status === 'notDownloading' && (
                <i className='c-icon  c-icon--[semantic-download]' aria-hidden='true' role='img' />
              )}
              {status === 'downloading' && (
                <div className='c-spinner  c-spinner--small c-spinner-table' aria-live='polite' aria-busy='true'>
                  <div className='c-spinner__loader'>
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                    <span className='c-spinner__element' />
                  </div>
                </div>
              )}
              {status === 'error' && (
                <i
                  className={`c-icon ${
                    authState.brand.toLowerCase() === 'audi' && 'u-border u-round'
                  } c-icon--[semantic-crossed-out]`}
                  aria-hidden='true'
                  role='img'
                />
              )}
              {status === 'success' && (
                <i
                  className={`c-icon ${
                    authState.brand.toLowerCase() === 'audi' && 'u-border u-round'
                  } c-icon--[semantic-checkmark]`}
                  aria-hidden='true'
                  role='img'
                />
              )}
            </div>
          </button>
        </td>
        <td className='doc-table-col__name' data-columnheader='Name'>
          <div className='doc-table-col__text'> {getFileName()} </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
