import React, { useEffect } from 'react';
import MessageCenter from './MessageCenter';

const Site = (): React.ReactElement => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { bronson } = window as any;
    // total hack to initialize bronson
    if (bronson) {
      bronson.ComponentRegister.initAll();
    }
  });

  return <MessageCenter />;
};

export default Site;
