/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get } from 'src/services/session-service';
import globalConstants from 'src/constants/globalConstants';
import i18n from 'src/localization/i18n';
import { getBrandFromValues, getUrlParam } from './Utilities';
/**
 * Set the brand based on properties
 * if brand passed on process.env.REACT_APP_BRAND, set brand
 * If brand is passed in properties, use that brand
 * then check the URL param brand=<brand>
 * Otherwise set it from the appProps in storage
 *
 * @param props the properties passed (i.e. xprops)
 * @returns {*} String the brand
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBrand = (props: any): string => {
  let localBrand;
  if (props && props?.brand) {
    // if the brand is passed in properties, use it
    localBrand = props?.brand;
  }

  // if there is a param passed then use that to override base react setting
  const urlBrand = getUrlParam('brand');
  if (urlBrand) {
    const newBrand = getBrandFromValues(urlBrand.toLowerCase(), globalConstants.VALID_BRANDS);
    if (newBrand) {
      localBrand = newBrand;
    }
  } else {
    // is there already a brand in the local storage. If so, pickup that brand on a refresh
    const existingProps = JSON.parse(get('appProps'));
    if (existingProps && existingProps.brand) {
      localBrand = existingProps.brand;
    }
  }

  if (!localBrand) {
    // if brand still is not set, then pickup from process.env or set default brand
    localBrand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : globalConstants.DEFAULT_BRAND;
  }
  return localBrand;
};

/**
 * Setup app props.
 * If no couutry is passed, default country is set
 * If no language is passed, default language is set
 * Sets brand. @see getBrand
 * Sets flag if the app is running in the myAudi mobile app
 *
 * @param xprops
 * @returns {*&{country: string, languageCode: *, brand: *, isMyAudiApp: boolean, parentDomain: *}}
 */

/**
 * Remove appProps data we don't need to be saving on localStorage.
 *
 * @param {*} appProps
 * @returns {*}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeAppPropsExtraInfo = (appProps: any): any => {
  delete appProps.account;
  delete appProps.accountNumber;
  delete appProps.id;
  delete appProps.initializedToken;
  delete appProps.partyId;
  delete appProps.url;
  delete appProps.path;

  return appProps;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function buildAppProps(xprops: any): any {
  // TODO - the brand and country are case sensitive when passed to some backend APIs. We need enumerated constants
  console.debug('buildAppProps');
  console.debug('xprops', xprops);
  let appProps = JSON.parse(get('appProps'));
  console.debug('appProps', appProps);

  const localBrand = getBrand(xprops);
  console.debug('localBrand', localBrand);

  let country = globalConstants?.DEFAULT_COUNTRY;
  if (xprops?.country) country = xprops?.country;
  if (appProps?.country) country = appProps.country;
  let languageCode = globalConstants?.EN_US;
  if (xprops?.languageCode) languageCode = xprops?.languageCode;
  if (appProps?.languageCode) languageCode = appProps?.languageCode;
  let accountType;
  if (xprops?.account?.accountProductType?.detailedAccountType)
    accountType = xprops?.account?.accountProductType?.detailedAccountType;
  const parentDomain = xprops ? xprops?.getParentDomain?.() : null;
  //  use the below hardcoded value to test myaudi mobile app views locally without fs-client
  //  const isMyAudiApp = true;
  // @see AudiConstants in vci-dashboard-app
  const source = xprops && xprops?.source;
  let isMyAudiApp = false;
  if (source) {
    isMyAudiApp = source === globalConstants.MY_AUDI_MOBILE_SOURCE || source === globalConstants.MY_AUDI_SOURCE;
  }

  appProps = {
    ...appProps,
    ...xprops,
    brand: localBrand,
    country,
    languageCode,
    parentDomain,
    isMyAudiApp,
    accountType,
  };

  return removeAppPropsExtraInfo(appProps);
}

/**
 * Change language for i18n.
 *
 * @param {*} appProps
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeLanguageFromAppProps = (appProps: any): void => {
  if (appProps?.languageCode) {
    i18n.changeLanguage(appProps.languageCode);
  }
};
