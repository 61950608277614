/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import App from './App';
import './App.scss';

import buildAppProps from './utility/buildAppProps';
import { updateAuthProps } from './utility/Utilities';

/**
 This component is used as an app shell, it uses to inject Bronson styles when it is working as a standalone app, 
 it was split into a different component/shell, because `App` component is used as *remote module*, 
 and when the `Bronson` declaration was in App component it used to remove the `Bronson` styles from 
 the app(dashboard, portal) as whole, when the message center module component was unmounted
 */
const AppShell = (): JSX.Element => {
  updateAuthProps((window as any)?.xprops);
  const appProps = buildAppProps((window as any)?.xprops);

  const localBrand = appProps.brand;
  const bronsonJsUrl = `https://cdn.bronson.vwfs.tools/${localBrand.toLowerCase()}/v/11.6.0/js/bronson.min.js`;
  const bronsonCssUrl = `https://cdn.bronson.vwfs.tools/${localBrand.toLowerCase()}/v/11.6.0/css/style.min.css`;

  return (
    <BrowserRouter>
      <Helmet>
        <script type='text/javascript' src={bronsonJsUrl} />
        <link rel='stylesheet' href={bronsonCssUrl} />
      </Helmet>
      <Routes>
        <Route path='*' element={<Navigate to='message-center' />} />
        <Route path='message-center/*' element={<App />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppShell;
