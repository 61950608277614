import React, { useContext, useEffect, useState } from 'react';
import './DocumentsTable.scss';
import { IDocumentContext } from '../../../context/DocCenterContext.interface';
import { DocCenterContext } from '../../../context/DocCenterContext';
import TableRow from './TableRow';
import { useAuthContext } from '../../../context/AuthContext';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';

const DocumentsTable: React.FC = () => {
  const { filteredStatements, filteredLoanAgreements, filteredAutopayLetters } = useContext(
    DocCenterContext
  ) as IDocumentContext;
  const { state: authState } = useAuthContext();
  const [endLoad, setEndLoad] = useState(12);
  const [hideBtn, setHideBtn] = useState('');
  const [documentsShown, setDocumentsShown] = useState(0);
  const documentsQuantity = filteredStatements.length + filteredLoanAgreements.length + filteredAutopayLetters.length;
  const load = [
    filteredStatements?.slice(0, endLoad),
    filteredLoanAgreements?.slice(0, endLoad),
    filteredAutopayLetters?.slice(0, endLoad),
  ];
  const yearIndex: Array<number> = [];
  const errorIcon = authState.brand === 'VW' ? 'semantic-error' : 'caution';

  const handleLoadMore = () => {
    if (documentsQuantity > documentsShown) {
      setHideBtn('');
      setEndLoad(endLoad + 12);
      // Error management of no more documents to load
    }
  };

  const handleYearTables = () => {
    const yearSet = new Set();
    load.forEach((document) => {
      document.forEach((doc) => {
        let documentDate;
        if (doc) {
          if (doc.InvoiceDate) {
            documentDate = new Date(doc.InvoiceDate);
          } else if (doc.CreateDate) {
            documentDate = new Date(doc.CreateDate);
          } else return;
          const year = documentDate.getFullYear();
          yearSet.add(year);
        }
      });
    });
    yearIndex.push(...yearSet);
  };

  let counter = 0;

  const documentRows = (tableYear: number) => {
    const flatLoad = load.flat();
    return flatLoad.map((doc, index) => {
      let docYear;
      if (doc) {
        if (doc.InvoiceDate) {
          docYear = new Date(doc.InvoiceDate).getFullYear();
        } else if (doc.CreateDate) {
          docYear = new Date(doc.CreateDate).getFullYear();
        } else return null;
        if (docYear === tableYear && JSON.stringify(doc) !== '{}' && counter < endLoad) {
          counter += 1;
          // eslint-disable-next-line react/no-array-index-key
          return <TableRow file={doc} key={`table-row-${index}`} />;
        }
      }
      return null;
    });
  };

  const documentYears = () => {
    handleYearTables();
    return yearIndex.map((year, index) => {
      /* eslint-disable react/no-array-index-key */
      return (
        <div className=' o-layout__item' key={index}>
          <div className={`c-table-wrapper  c-table--responsive  js-table-responsive ${authState.brand.toLowerCase()}`}>
            <div className='doc-table'>
              <div className='doc-table__year'>
                <h6>{year}</h6>
              </div>
              <div className='doc-table__table'>
                <table className='doc-table__table-table'>
                  <thead>
                    <tr className='doc-table-col'>
                      <th className='doc-table-col__date doc-table-col__title' scope='col'>
                        {i18n.t(rk.DOCUMENT_CENTER_TABLE_HEADER_DATE)}
                      </th>
                      <th className='doc-table-col__download doc-table-col__title' scope='col'>
                        {i18n.t(rk.DOCUMENT_CENTER_TABLE_HEADER_DOWNLOAD)}
                      </th>
                      <th className='doc-table-col__name doc-table-col__title' scope='col'>
                        {i18n.t(rk.DOCUMENT_CENTER_TABLE_HEADER_NAME)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{documentRows(year)}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    handleYearTables();
  }, [filteredStatements]);

  useEffect(() => {
    const documents = document.getElementsByClassName('doc-table-col__body');
    setDocumentsShown(documents.length);
  }, [filteredStatements, filteredLoanAgreements, filteredAutopayLetters, endLoad]);

  useEffect(() => {
    if (documentsQuantity === documentsShown) {
      setHideBtn('u-hide');
    } else {
      setHideBtn('');
    }
  }, [documentsShown]);

  return (
    <>
      <div className='doc-tables-wrapper'>{documentYears()}</div>
      {filteredStatements.length > 0 || filteredLoanAgreements.length > 0 || filteredAutopayLetters.length > 0 ? (
        <div>
          {documentsQuantity > endLoad && (
            <div className=' o-layout__item load-more'>
              <div className=' load-more__container'>
                <button
                  type='button'
                  onClick={() => handleLoadMore()}
                  className={`c-btn  c-btn--secondary load-more__btn ${hideBtn}`}
                >
                  <span className='c-btn__text'>{i18n.t(rk.DOCUMENT_CENTER_BUTTON_LOAD_MORE)}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='o-layout__item u-1/1 u-mt-none no-docs'>
          <div className='no-docs__wrapper'>
            <article className='c-card  c-card--states  c-card--states-error'>
              <header className='c-card__header'>
                <div className='c-card__i'>
                  <i className={`c-icon  c-icon--[${errorIcon}] no-docs__icon`} aria-hidden='true' role='img' />
                </div>
                <h4 className='c-card__h4'>{i18n.t(rk.DOCUMENT_CENTER_NO_DOCS_TITLE)}</h4>
                <p className='c-card__p'>{i18n.t(rk.DOCUMENT_CENTER_NO_DOCS_NOTE)}</p>
              </header>
            </article>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentsTable;
