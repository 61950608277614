import { IAuthState } from 'src/context/AuthContext';
import axios from 'axios';
import { print } from 'graphql';
import { v4 as uuid } from 'uuid';
import { ILoanAgreements, IStatements } from 'src/context/DocCenterContext.interface';
import {
  getInvoice,
  getDocumentList,
  getLoanAgreement,
  getPaidInFullLetter,
  getTurnInLetter,
  getZeroBalanceLetter,
  getDocumentEligibility,
} from '../../utility/graphql-queries';

const getSource = (brand: string, country: string) => {
  let source = '';
  if (country === 'USA') {
    if (brand === 'AUDI') {
      source = 'MYAUDI';
    } else if (brand === 'VW') {
      source = 'VWC PORTAL';
    }
  } else if (country === 'CANADA') {
    if (brand === 'AUDI') {
      source = 'AF PORTAL';
    } else if (brand === 'VW') {
      source = 'VF PORTAL';
    }
  }

  return source;
};

const getStatementsService = async (authState: IAuthState, document: IStatements) => {
  // GraphQL Call
  const source = getSource(authState?.brand?.toUpperCase(), authState?.country?.toUpperCase());
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getInvoice),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: document.AccountNumber,
          SequenceNumber: document.SequenceNumber,
          Pid: document.Pid,
          Guid: document.Guid,
          Mp: document.Mp,
          Source: source,
        },
      },
    });
    if (response?.data?.data?.Invoice?.IsSuccess) {
      console.info('Success calling GetInvoice api with document for download');
    } else {
      console.error(
        `Unsuccessful in calling GetInvoice => API failed with error ${JSON.stringify(response?.data?.errors) || ''}`
      );
    }
    return response.data?.data?.Invoice;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetInvoice => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getLoanAgreementService = async (authState: IAuthState, document: ILoanAgreements, documentType: string) => {
  // GraphQL Call
  const source = getSource(authState?.brand?.toUpperCase(), authState?.country?.toUpperCase());
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getLoanAgreement),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: authState.accountNumber,
          ExternalDocumentId: document.ExternalDocumentID,
          Source: source,
          DocumentType: documentType,
        },
      },
    });
    if (response?.data?.data?.Document?.IsSuccess) {
      console.info('Success calling GetDocument api with document for download');
    } else {
      console.error(
        `Unsuccessful in calling GetDocument => API failed with error ${JSON.stringify(response?.data?.errors) || ''}`
      );
    }
    return response.data?.data?.Document;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetDocument => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getDocumentListService = async (authState: IAuthState) => {
  // GraphQL Call
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getDocumentList),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: `${authState?.accountNumber}`,
        },
      },
    });
    return response.data?.data?.AllDocTypesList;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetAllDocs => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getPaidInFullLetterService = async (authState: IAuthState) => {
  // GraphQL Call
  const source = getSource(authState?.brand?.toUpperCase(), authState?.country?.toUpperCase());
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getPaidInFullLetter),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: authState.accountNumber,
          Source: source,
        },
      },
    });
    if (response?.data?.data?.GetPaidInFullLetter?.IsSuccess) {
      console.info('Success calling GetPaidInFullLetter api with document for download');
    } else {
      console.error(
        `Unsuccessful in calling GetPaidInFullLetter => API failed with error
          ${JSON.stringify(response?.data?.errors) || response?.data?.data?.GetPaidInFullLetter?.Errors || ''}`
      );
    }
    return response.data?.data?.GetPaidInFullLetter;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetPaidInFullLetter => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getTurnInLetterService = async (authState: IAuthState) => {
  // GraphQL Call
  const source = getSource(authState?.brand?.toUpperCase(), authState?.country?.toUpperCase());
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getTurnInLetter),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: authState.accountNumber,
          Source: source,
        },
      },
    });
    if (response?.data?.data?.GetTurnInLetter?.IsSuccess) {
      console.info('Success calling GetTurnInLetter api with document for download');
    } else {
      console.error(
        `Unsuccessful in calling GetTurnInLetter => API failed with error
          ${JSON.stringify(response?.data?.errors) || response?.data?.data?.GetTurnInLetter?.Errors || ''}`
      );
    }
    return response.data?.data?.GetTurnInLetter;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetTurnInLetter => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getZeroBalanceLetterService = async (authState: IAuthState) => {
  // GraphQL Call
  const source = getSource(authState?.brand?.toUpperCase(), authState?.country?.toUpperCase());
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getZeroBalanceLetter),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: authState.accountNumber,
          Source: source,
        },
      },
    });
    if (response?.data?.data?.GetZeroBalanceLetter?.IsSuccess) {
      console.info('Success calling GetZeroBalanceLetter api with document for download');
    } else {
      console.error(
        `Unsuccessful in calling GetZeroBalanceLetter => API failed with error
          ${JSON.stringify(response?.data?.errors) || response?.data?.data?.GetZeroBalanceLetter?.Errors || ''}`
      );
    }
    return response.data?.data?.GetZeroBalanceLetter;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetZeroBalanceLetter => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

const getDocumentEligibilityService = async (authState: IAuthState) => {
  // GraphQL Call
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_DOCUMENT_CENTER_API_HOST}`,
      method: 'POST',
      headers: {
        Authorization: `${authState?.token}`,
      },
      data: {
        query: print(getDocumentEligibility),
        variables: {
          CorrelationId: `${uuid()}`,
          AccountNumber: `${authState.accountNumber}`,
          Country: `${authState.country}`,
        },
      },
    });
    return response?.data;
  } catch (error) {
    // HANDLE API ERROR
    console.error(`Unsuccessful in calling GetDocumentEligibility => API failed with error ${JSON.stringify(error)}`);
    return new Error();
  }
};

export {
  getStatementsService,
  getDocumentListService,
  getLoanAgreementService,
  getPaidInFullLetterService,
  getTurnInLetterService,
  getZeroBalanceLetterService,
  getDocumentEligibilityService,
};
