import React, { useContext, useEffect } from 'react';
import { AccountContext } from 'src/context/AccountContext';
import { IAccountContext } from 'src/context/AccountContext.interface';
import { generateSiteLoadEvent } from 'src/utility/Utilities';
import { useMessageContext } from 'src/context/MessageContext';
import { ISubjectMappingResponse } from 'src/context/MessageContext.interface';
import { AccountType, Brand, Country } from '../../../types';
import FAQComponentCanada from '../../components/faq/FAQComponentCanada';
import FAQComponentUSA from '../../components/faq/FAQComponentUSA';

const FAQ: React.FC<{ country: Country; brand: Brand; accountType: AccountType; mobile: boolean }> = (props) => {
  const { country, brand, accountType, mobile } = props;
  const accountContext = useContext(AccountContext) as IAccountContext;
  const accounts = accountContext?.accounts;
  const { messages, subjectMappings, newmessageCount } = useMessageContext();
  let detailedAccountType = accountType;
  if (detailedAccountType === AccountType.Default && accounts?.Finance?.DetailedAccountType) {
    detailedAccountType = accounts?.Finance?.DetailedAccountType as AccountType;
  }

  if (!detailedAccountType) return <></>;

  useEffect(() => {
    if (
      accounts &&
      accounts.AccountId !== '' &&
      messages.messages.length >= 0 &&
      process.env.REACT_APP_ADOBE_ANALYTICS_FLAG === 'true'
    ) {
      generateSiteLoadEvent(accounts, subjectMappings as ISubjectMappingResponse, messages, newmessageCount as number);
    }
  }, [accounts, messages.messages]);

  return country === Country.Canada ? (
    <FAQComponentCanada brand={brand} accountType={detailedAccountType} mobile={mobile} />
  ) : (
    <FAQComponentUSA brand={brand} accountType={detailedAccountType} mobile={mobile} />
  );
};

export default FAQ;
