/* eslint-disable react/require-default-props */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParentDomain, postMessage } from 'src/utility/Utilities';
import { useDialogContext } from 'src/context/DialogContext';
import {
  INBOX_PATH,
  MAIN_PATH,
  MESSAGE_CENTER_CREATE_NEW_MESSAGE,
  MESSAGE_CENTER_INBOX_PATH,
} from 'src/constants/routePaths';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';

interface BackLinkProps {
  label: string;
  className?: string;
  mobile?: boolean;
}

const BackLink: React.FC<BackLinkProps> = ({ label, className = 'c-link__body', mobile = false }: BackLinkProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: dialogState, showDialog } = useDialogContext();
  const isNewMessagePath = pathname.endsWith(MESSAGE_CENTER_CREATE_NEW_MESSAGE);
  const isInboxWithMessageSelected = pathname.replace(MESSAGE_CENTER_INBOX_PATH, '').match(/\/\d{1,10}/);

  const [, ENTRY_PATH] = pathname.split('/');

  const onClickHandler = () => {
    // Added for iframes where we need to tell the parent to scrollToTop.
    if (getParentDomain()) {
      postMessage(getParentDomain(), 'scrollToTop', '');
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (dialogState.isUpdated) {
      showDialog('BACK_TO_HOME_ACTION');
      return;
    }

    const INBOX_ABSOLUTE_PATH = (() => {
      // if path is the same it means it is running as standalone, required to pass e2e tests
      if (ENTRY_PATH === MAIN_PATH) return `/${MAIN_PATH}/${INBOX_PATH}` as const;

      return `/${ENTRY_PATH}/${MAIN_PATH}/${INBOX_PATH}` as const;
    })();

    if ((isNewMessagePath || isInboxWithMessageSelected) && mobile) {
      navigate(INBOX_ABSOLUTE_PATH);
      return;
    }

    // if rootPath exists that means you came to message center, from another app
    // and when you click go home you need to go there.
    // otherwise just goback to previous page.
    if (window.rootPath) {
      navigate(window.rootPath);
      return;
    }
    navigate(-1);
  };

  return (
    <button type='button' className='c-link' onClick={onClickHandler}>
      <span className={className}>
        {(isNewMessagePath || isInboxWithMessageSelected) && mobile ? i18n.t(rk.BACK_LINK_MESSAGES_TEXT) : label}
      </span>
    </button>
  );
};

export default BackLink;
