import React, { useEffect, useRef } from 'react';
import './ListItemMessage.scss';
import { format, formatDistance } from 'date-fns';
import globalConstants from 'src/constants/globalConstants';
import { IListItem, ThreadType } from '../../IListItem.interface';
import attachmentIconAudi from '../../../../../assets/images/attachment_icon_audi.png';
import attachmentIconVW from '../../../../../assets/images/attachment_icon_vw.png';

const Unread = () => (
  <span
    data-testid='unreaded-item'
    style={{ borderRadius: '100%', background: 'black', width: '8px', height: '8px', marginRight: '10px' }}
  />
);
/**
 * We need to indentify whether the timestamp is created
 * recently to show just the hour, otherwise show the date.
 * Obviously with the format expect it.
 */
function resolveDate(timestamp: string) {
  const date = new Date(timestamp ?? 0);
  const currenDate = new Date();

  // below date value is used to test when it has to show hour or date,
  // now the validation is `when the date is 8 hours different than current date`
  // const date = sub(currenDate, { hours: 7, minutes: 52, months: 2 });

  // I need to indentify the distance between two dates
  // in order to show hour or date
  // https://date-fns.org/v2.28.0/docs/formatDistance

  const distance = formatDistance(currenDate, date);

  const minorThan8Hours = !!distance.match(/about [1-8] hour/);
  const isMinutesDifferent = !!distance.match(/(\d{1,2} minute)|(less than a minute)/);

  if (isMinutesDifferent || minorThan8Hours) return format(date, 'p');

  return format(date, 'MM/dd/yyyy');
}

const ListItemMessage = ({
  label = '',
  timestamp = '',
  unread = false,
  title = '',
  description = '',
  active = false,
  onClick = () => undefined,
  hasAttachment = false,
  brand,
  type,
}: IListItem): JSX.Element => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  // description has html entities and we need decoded to understandable
  // string by DOM
  useEffect(() => {
    if (descriptionRef.current) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(`<!doctype html><body>${description}`, 'text/html');

      const tmpEl = document.createElement('template');
      tmpEl.innerHTML = dom.body.textContent ?? '';
      const text = tmpEl.content.textContent ?? '';

      descriptionRef.current.textContent = text.length >= 67 ? `${text.substring(0, 67)}...` : text;
    }
  }, []);

  return (
    <li>
      <button
        type='button'
        onClick={() =>
          onClick({ label, timestamp, unread, title, description, active, onClick, hasAttachment, brand, type })
        }
        data-testid={unread ? 'unread-message-item-button' : 'message-item-button'}
        className={`messages-list__item ${active ? 'messages-list__item--active' : ''}`}
      >
        <header className='messages-list__item__header' data-testid='message-item-header'>
          <span>{label}</span>
          <span>{type === ThreadType.WELCOME_MESSAGE ? '' : resolveDate(timestamp)}</span>
        </header>
        <article className='messages-list__item__body' data-testid='message-item-body'>
          <div className={`messages-list__item__body title ${unread ? 'title--unread' : ''}`}>
            <div className='messages-list__item_body title-inner-container'>
              {unread ? <Unread /> : ''}
              <span>{title.length >= 20 && unread ? `${title.substring(0, 20)}...` : title}</span>
            </div>
            {hasAttachment && (
              <img
                className={`attachment-icon ${brand.toLowerCase()}`}
                src={brand.toLowerCase() === globalConstants.AUDI_BRAND ? attachmentIconAudi : attachmentIconVW}
                alt='attachment-icon'
              />
            )}
          </div>
          {!hasAttachment && (
            <p
              ref={descriptionRef}
              className={`messages-list__item__body description ${unread ? 'description--unread' : ''}`}
            />
          )}
        </article>
      </button>
    </li>
  );
};

export default ListItemMessage;
