import { AxiosRequestHeaders } from 'axios';
import { getCorrelationId, getSourceId } from 'src/utility/Utilities';
import { IAuthState } from '../../context/AuthContext';
/**
 * Build headers for api calls
 *
 * @returns {{Authorization: string, country: string, 'Accept-Language': string,
 * partyId: string, brand: string, externalId: string}}
 * @param auth
 */

type MessageCenterHeaders = {
  Authorization: string;
  accountNumber: string;
  partyId: string;
  brand: string;
  country: string;
  correlationId?: string | null;
  externalId?: string | null;
  sourceId: string;
};

// eslint-disable-next-line import/prefer-default-export
export const buildHeaderParams = (auth: IAuthState): AxiosRequestHeaders => {
  const headers: MessageCenterHeaders = {
    Authorization: `Bearer ${auth.token}`,
    accountNumber: auth.accountNumber,
    partyId: auth.partyId,
    brand: auth.brand,
    country: auth.country,
    correlationId: getCorrelationId(),
    externalId: auth.externalId,
    sourceId: `${getSourceId()}`,
  };

  // Check for non-empty values for external and correlation IDs.
  if (!headers.correlationId) {
    delete headers.correlationId;
  }

  if (!headers.externalId) {
    delete headers.externalId;
  }

  return headers as AxiosRequestHeaders;
};
