interface MessageTypesDataTypes {
  AUTH_ERROR: string;
  AUTH_SUCCESS: string;
  BRAND_ERROR: string;
  LANGUAGE_ERROR: string;

  MESSAGE_CENTER_FAQ_VISIT: string;
  MESSAGE_CENTER_VISIT: string;

  MESSAGE_CENTER_MESSAGE_MAILBOX_MESSAGE_SELECTED: string;
  MESSAGE_CENTER_MESSAGE_MAILBOX_CONFIRMATION_MESSAGE_CLOSED: string;

  MESSAGE_CENTER_NEW_MESSAGE_STARTED: string;
  MESSAGE_CENTER_NEW_MESSAGE_SUBMITTED: string;
  MESSAGE_CENTER_NEW_MESSAGE_ABORTED: string;
  MESSAGE_CENTER_NEW_MESSAGE_ERROR: string;
  MESSAGE_CENTER_NEW_MESSAGE_COMPLETED: string;
  MESSAGE_CENTER_NEW_MESSAGE_CANCELLED: string;

  MESSAGE_CENTER_ATTACHMENT_UPLOAD_ATTEMPTED: string;
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_MALWARE_DETECTED: string;
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_ERROR: string;
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_USER_ABORTED: string;
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_SUCCESSFUL: string;

  MESSAGE_CENTER_REPLY_STARTED: string;
  MESSAGE_CENTER_REPLY_SUBMITTED: string;
  MESSAGE_CENTER_REPLY_ABORTED: string;
  MESSAGE_CENTER_REPLY_ERROR: string;
  MESSAGE_CENTER_REPLY_COMPLETED: string;
  MESSAGE_CENTER_REPLY_CANCELLED: string;

  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_TRIGGERED: string;
  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_SUCCESS: string;
  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_ERROR: string;
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_TRIGGERED: string;
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_SUCCESS: string;
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_ERROR: string;
}
export const MessageTypes: MessageTypesDataTypes = {
  AUTH_ERROR: 'authError',
  AUTH_SUCCESS: 'authSuccess',
  BRAND_ERROR: 'brandError',
  LANGUAGE_ERROR: 'languageError',

  MESSAGE_CENTER_FAQ_VISIT: 'messageCenterFAQVisit',
  MESSAGE_CENTER_VISIT: 'messageCenterVisit',

  MESSAGE_CENTER_MESSAGE_MAILBOX_MESSAGE_SELECTED: 'messageCenterMailboxOpenSelected',
  MESSAGE_CENTER_MESSAGE_MAILBOX_CONFIRMATION_MESSAGE_CLOSED: 'messageCenterConfirmationMessageClosed',

  MESSAGE_CENTER_NEW_MESSAGE_STARTED: 'messageCenterNewMessageStarted',
  MESSAGE_CENTER_NEW_MESSAGE_SUBMITTED: 'messageCenterNewMessageSubmitted',
  MESSAGE_CENTER_NEW_MESSAGE_ABORTED: 'messageCenterNewMessageAborted',
  MESSAGE_CENTER_NEW_MESSAGE_ERROR: 'messageCenterNewMessageError',
  MESSAGE_CENTER_NEW_MESSAGE_COMPLETED: 'messageCenterNewMessageCompleted',
  MESSAGE_CENTER_NEW_MESSAGE_CANCELLED: 'messageCenterNewMessageCancelled',

  MESSAGE_CENTER_ATTACHMENT_UPLOAD_ATTEMPTED: 'messageCenterAttachmentUploadAttempted',
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_MALWARE_DETECTED: 'messageCenterAttachmentUploadMalwareDetected',
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_USER_ABORTED: 'messageCenterAttachmentUploadUserAborted',
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_ERROR: 'messageCenterAttachmentUploadError',
  MESSAGE_CENTER_ATTACHMENT_UPLOAD_SUCCESSFUL: 'messageCenterAttachmentUploadSuccessful',

  MESSAGE_CENTER_REPLY_STARTED: 'messageCenterReplyStarted',
  MESSAGE_CENTER_REPLY_SUBMITTED: 'messageCenterReplySubmitted',
  MESSAGE_CENTER_REPLY_ABORTED: 'messageCenterReplyAborted',
  MESSAGE_CENTER_REPLY_ERROR: 'messageCenterReplyError',
  MESSAGE_CENTER_REPLY_COMPLETED: 'messageCenterReplyCompleted',
  MESSAGE_CENTER_REPLY_CANCELLED: 'messageCenterReplyCancelled',

  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_TRIGGERED:
    'messageCenterAttachmentButtonDocumentDownloadTriggered',
  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_SUCCESS: 'messageCenterAttachmentButtonDocumentDownloadSuccess',
  MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_ERROR: 'messageCenterAttachmentButtonDocumentDownloadError',
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_TRIGGERED:
    'messageCenterAttachmentButtonBatchLetterDownloadTriggered',
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_SUCCESS:
    'messageCenterAttachmentButtonBatchLetterDownloadSuccess',
  MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_ERROR: 'messageCenterAttachmentButtonBatchLetterDownloadError',
};

export default {
  MessageTypes,
};
