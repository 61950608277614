/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { QueryClient } from 'react-query';
import { UIMessage } from 'src/views/pages/mailbox/IListItem.interface';
import Switch from 'src/views/components/common/Switch';
import saveAttachmentsService from 'src/services/message-center/save-attachments';
import updateMessageStatusService from '../services/message-center/update-message-status';
import getAllMessagesService from '../services/message-center/get-messages';
import getNewMessageCountService from '../services/message-center/get-newmessage-count';
import sendMessageService from '../services/message-center/send-message';
import getSubjectMappingsService from '../services/message-center/get-subject-lines';
import { createCtx } from '../utility/Helper';
import {
  IMessageResponse,
  MessageContextProviderProps,
  MessageContextType,
  ISubjectMappingResponse,
} from './MessageContext.interface';
import getConversationService from '../services/message-center/get-conversation';
import getAttachmentsServices from '../services/message-center/get-attachments';
import getBatchLetterService from '../services/message-center/get-batcheLetter';
import { useAuthContext } from './AuthContext';
import { useLoader } from './LoadingContext';

export const [useMessageContext, MessageCtxProvider, MessageCtxConsumer] =
  createCtx<MessageContextType>('MessageCtxProvider');

// https://react-query.tanstack.com/reference/QueryClient#queryclient
// instead of create a cache setup I use this supported library
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

/**
 *
 * @param {React.ReactNode} Props.children
 * @param {Object} Props.omitRequestOnMount - Omit http calls when the component is mounted,
 * you accept one of the types check with `true` value
 * @param {boolean} Props.scoped - you can create a context spinner scoped, it is used to use spinner local and not the fullscreen
 */
export const MessageContextProvider: React.FC<MessageContextProviderProps> = ({
  children,
  scoped,
  omitRequestOnMount,
}): JSX.Element => {
  const [messages, setMessages] = useState<IMessageResponse>({ documents: [], messages: [] });
  const [newmessageCount, setNewmessageCount] = useState<number | undefined>(undefined);
  const [subjectMappings, setSubjectMappings] = useState<ISubjectMappingResponse>();
  const { state: authState, changeAccountNumber } = useAuthContext();
  const [sentMessage, setSentMessage] = useState<UIMessage | null>(null);
  const { stopLoader, startLoader, isLoading, Spinner } = useLoader({
    isLoading: scoped,
    local: scoped,
    key: 'MessageContextProvider',
  });
  // this is a flag to keep the spinner until the get messages api call responses
  // we set the flag to true to avoid an infinite spinner only if refreshMessages is invoked the flag will set to false
  let alreadyGetMessagesCallResponsed = true;
  const isUnmountedRef = useRef(false);

  const setNewSentMessage = useCallback((m: UIMessage | null) => {
    setSentMessage(m);
  }, []);

  const getAttachments = useCallback(
    (messageId: string | number) => {
      return getAttachmentsServices(messageId, authState);
    },
    [authState]
  );

  const getBatchLetters = useCallback(
    (originalBody: string) => {
      return getBatchLetterService(originalBody, authState);
    },
    [authState]
  );

  const getConversation = useCallback(
    (messageId: string | number) => {
      return getConversationService(messageId, authState);
    },
    [authState]
  );

  const refreshMessages = useCallback(
    ({ force }: { force: boolean } = { force: false }) => {
      alreadyGetMessagesCallResponsed = false;
      if (!isLoading) startLoader();
      queryClient
        .fetchQuery('refreshMessages', () => getAllMessagesService(authState), force ? { staleTime: 0 } : undefined)
        .then((responseMessages) => {
          setMessages(responseMessages);
          alreadyGetMessagesCallResponsed = true;
          stopLoader();
        })
        .catch((error) => {
          stopLoader();
          console.error(`Error in getAllMessagesService=${JSON.stringify(error)}`);
        });
    },
    [isLoading, alreadyGetMessagesCallResponsed]
  );

  const refreshNewmessageCount = useCallback(
    ({ force }: { force: boolean } = { force: false }) => {
      if (!isLoading) startLoader();
      queryClient
        .fetchQuery(
          'refreshNewmessageCount',
          () => getNewMessageCountService(authState),
          force ? { staleTime: 0 } : undefined
        )
        .then((res) => {
          setNewmessageCount(res.documents + res.messages);
          // if get messages api call does not response yet we keep the spinner so stopLoader is not invoked, stopLoader must be invoked inside refreshMessages
          if (alreadyGetMessagesCallResponsed) {
            stopLoader();
          }
        })
        .catch((error) => {
          stopLoader();
          console.error(`Error in getNewMessageCountService=${JSON.stringify(error)}`);
        });
    },
    [isLoading]
  );

  const updateMessageStatus = useCallback(
    (messageId: string | number, isRead: boolean, isDocument: boolean) => {
      return (
        Promise.resolve()
          // firstable the count is set minus 1, since is too slow wait for calls end
          .then(() => {
            setNewmessageCount((count) => (count ? count - 1 : count));
            const messageIndex = messages.messages.findIndex((message) =>
              isDocument ? message.id === messageId : message.threadId === messageId
            );
            const updatedMessages = { ...messages };
            updatedMessages.messages[messageIndex] = { ...updatedMessages.messages[messageIndex], isread: true };
            setMessages(updatedMessages);
          })
          .then(() => updateMessageStatusService(messageId, isRead, isDocument, authState))
          .catch((error) => console.error(`Error in updateMessageStatusService=${JSON.stringify(error)}`))
      );
    },
    [messages, authState]
  );

  const sendMessage = useCallback(
    async (params: FormData) => {
      try {
        if (!isLoading) startLoader();
        const sentMessageResponse = await sendMessageService(params, authState);
        stopLoader();
        return sentMessageResponse;
      } catch (error) {
        stopLoader();
        throw error;
      }
    },
    [isLoading, authState]
  );

  const saveAttachments = (subjectTopicId: string | number, body: File[]) => {
    return saveAttachmentsService({ subjectTopicId, body }, authState);
  };

  const getSubjectLines = useCallback(() => {
    if (!isLoading) startLoader();
    queryClient
      .fetchQuery('getSubjectMappings', () => getSubjectMappingsService(authState), { staleTime: Infinity })
      .then((response) => {
        setSubjectMappings(response);
        // if get messages api call does not response yet we keep the spinner so stopLoader is not invoked, stopLoader must be invoked inside refreshMessages
        if (alreadyGetMessagesCallResponsed) {
          stopLoader();
        }
      })
      .catch((error) => {
        console.error(`Error in getSubjectMappingsService=${JSON.stringify(error)}`);
        stopLoader();
      });
  }, [isLoading]);

  useEffect(() => {
    if (!!omitRequestOnMount?.getSubjectLines === false && !isUnmountedRef.current) {
      getSubjectLines();
    }
    if (!!omitRequestOnMount?.refreshMessages === false && !isUnmountedRef.current) {
      refreshMessages();
    }
    if (!!omitRequestOnMount?.refreshNewmessageCount === false && !isUnmountedRef.current) {
      refreshNewmessageCount();
    }

    // When un-mounting the message center app reset queryClient to fetch fresh messages again on mount.
    return () => {
      isUnmountedRef.current = true;
      queryClient.resetQueries('refreshMessages', { exact: true });
      queryClient.resetQueries('refreshNewmessageCount', { exact: true });
    };
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    if (authState.accountNumber && !queryClient.isFetching()) {
      changeAccountNumber(false);
      startLoader();
      getNewMessageCountService(authState)
        .then((response) => {
          setNewmessageCount(response.documents + response.messages);
        })
        .catch((error) => {
          console.error(`Error in getNewMessageCountService=${JSON.stringify(error)}`);
        })
        .finally(() => {
          stopLoader();
        });
    }
    // eslint-disable-next-line consistent-return
    return () => ac.abort();
  }, [authState.accountNumber]);
  const contextValue = {
    messages,
    newmessageCount,
    subjectMappings,
    sendMessage,
    updateMessageStatus,
    getConversation,
    getAttachments,
    getBatchLetters,
    saveAttachments,
    getSubjectLines,
    sentMessage,
    setNewSentMessage,
    refreshMessages,
  };

  return (
    <MessageCtxProvider value={contextValue}>
      <Switch value={!!scoped} defaultChild=''>
        <Switch.Item case>
          <Spinner>{children}</Spinner>
        </Switch.Item>
        <Switch.Item case={false}>{children}</Switch.Item>
      </Switch>
    </MessageCtxProvider>
  );
};
