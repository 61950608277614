import React from 'react';
import { AxiosError } from 'axios';
import { useLoader } from 'src/context/LoadingContext';
import { SEND_MESSAGE_ENDPOINT } from 'src/services/message-center/send-message';
import { ErrorType, useErrorContext } from '../../../../context/ErrorContext';
import i18n from '../../../../localization/i18n';
import BannerNotification from '../banner-notification/BannerNotification';
import * as r from '../../../../localization/resourceKeys';
import { apiClient, HandleErrorType, HandleResponseType, useAxiosInterceptors } from '../../../../utility/Axios';

export const DEFAULT_ERROR = {
  messageCode: r.API_ERROR_GENERIC,
  title: i18n.t(r.ERROR_NOTIFICATION_SYSTEM_ERROR_HEADER),
  message: i18n.t(r.ERROR_NOTIFICATION_SYSTEM_ERROR_CONTENT),
};

export const SEND_MESSAGE_API_ERROR = {
  messageCode: r.API_ERROR_SEND_MESSAGE,
  title: '',
  message: i18n.t(r.ERROR_NOTIFICATION_MESSAGE_CANNOT_BE_SENT_ERROR_CONTENT),
};
/**
 * helper function to know if the error needs a specific title, content or message code depends on endpoint url
 * @param endpoint path
 * @returns ErrorType which contains the title and content
 */
export const getErrorMessageByEndpoint = (endpoint: string | undefined): ErrorType | null => {
  switch (endpoint) {
    case '/postmessage':
      return SEND_MESSAGE_API_ERROR;
    // if you want to skip in specific api just return null
    case '/SPECIFIC_API_TO_SKIP':
      return null;
    default:
      return DEFAULT_ERROR;
  }
};

/**
 * Helper function to parse Axios Error to ErrorType for Context
 * @param err AxiosError
 * @returns ErrorType
 */
export const parseAxiosError = (err: AxiosError): ErrorType | null => {
  const endpoint = err?.config?.url;
  const errorMessage = getErrorMessageByEndpoint(endpoint);
  if (!errorMessage) return null;
  const { messageCode, title, message } = errorMessage;
  return {
    messageCode,
    title,
    message,
    aError: err,
    errorOrigin: endpoint,
  };
};

/**
 * Helper function to know if the BannerNotification needs close icon
 * @param messageCode
 */
const needsCloseIcon = (messageCode: string) => {
  if (messageCode === r.API_ERROR_SEND_MESSAGE) return true;
  return false;
};

/**
 * Helper function to know if the BannerNotification needs close icon
 * @param messageCode
 */
const needsNotificationIcon = (messageCode: string) => {
  if (messageCode === r.API_ERROR_SEND_MESSAGE) return true;
  return false;
};

// max retries for retry logic
export const MAX_RETRIES = 1;
// status code to consider as retry request, if request status code is greater than or equal to retry status code then retry request
export const RETRY_STATUS_CODE = 500;
// counter object to save the value of each endpoint which is making a request
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const counter: any = {};
// endpoints array allowed to retry request if fails
const ENDPOINTS_TO_RETRY = [SEND_MESSAGE_ENDPOINT];

/**
 * This is just a wrapper which displays the current error in the Content and use axios interceptor to set error in context when any API
 * fails, you could use to display any error using addEror from useErrorContext
 */
export const Error: React.FC = (): JSX.Element | null => {
  const { error, removeError, addError } = useErrorContext();
  const { messageCode = '', title = '', message = '' } = error || {};
  const { stopLoader } = useLoader();

  /**
   * intercept the response and check if has error in context then clear it in the context
   * @param res axios response
   * @returns must return the same response
   */
  const handleResponse: HandleResponseType = (res) => {
    const endpoint = res?.config?.url || '';
    if (error) {
      removeError();
    }
    // we restart the counter for the specific endpoint if request was successfully
    if (ENDPOINTS_TO_RETRY.includes(endpoint)) {
      counter[endpoint] = 0;
    }
    return res;
  };
  /**
   * intercept the error and check if needs to add error in the context to displays banner error
   * @param axiosErr axios error
   * @returns must return error rejected
   */
  const handleError: HandleErrorType = (axiosErr) => {
    // we add retry logic in the handle error interceptor
    const endpoint = axiosErr?.config?.url || '';
    // if endpoint is in ENDPOINTS_TO_RETRY then the retry logic should be triggered
    if (ENDPOINTS_TO_RETRY.includes(endpoint)) {
      const { config, response } = axiosErr;
      // to test retry logic just comment the line above and uncomment the following line
      // const { config, response = { status: 504 } } = axiosErr;
      if (endpoint && counter[endpoint] === undefined) {
        // we initialize the counter to 0 for the specific endpoint if the counter does not exist
        counter[endpoint] = 0;
      }
      if (counter[endpoint] < MAX_RETRIES && response && response.status >= RETRY_STATUS_CODE) {
        counter[endpoint] += 1;
        // retrying request
        return Promise.resolve(apiClient(config));
      }
    }
    console.error('[Error] handleError', axiosErr);
    const err = parseAxiosError(axiosErr);
    if (err) {
      stopLoader();
      addError(err);
    }
    // we restart the counter for the specific endpoint if retry logic is completed
    if (ENDPOINTS_TO_RETRY.includes(endpoint)) {
      counter[endpoint] = 0;
    }
    return Promise.reject(err);
  };

  // use this custom hook to setup interceptor when occurs an error or success response
  useAxiosInterceptors(handleResponse, handleError);

  return (
    <BannerNotification
      notificationIcon={needsNotificationIcon(messageCode)}
      horizontalSpacing={false}
      extendedMargin
      isOpen={!!error} // if there is no error renders null
      notificationStyle='error'
      title={title}
      closeIcon={needsCloseIcon(messageCode)}
      onClose={() => {
        // after the notification is closed removing the error object in context
        removeError();
      }}
    >
      {message}
    </BannerNotification>
  );
};
