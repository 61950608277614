/* eslint-disable react/require-default-props */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMessageContext } from 'src/context/MessageContext';
import {
  FAQ_PATH,
  INBOX_PATH,
  MESSAGE_CENTER_INBOX_PATH,
  DOCUMENT_CENTER_PATH,
  DOCUMENT_PATH,
} from 'src/constants/routePaths';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';
import LinkItem from './LinkItem';
import BackLink from './BackLink';
import globalConstants from '../../../../constants/globalConstants';

interface AsideBarProps {
  brand: string;
  country: string;
}

const AsideBar: React.FC<AsideBarProps> = ({ brand, country }: AsideBarProps) => {
  const location = useLocation();
  const { newmessageCount } = useMessageContext();

  const { pathname } = location;

  return (
    <div data-testid='Sidebar' className='asidebar-container' role='navigation' aria-label='main navigation'>
      <ul className='asidebar-top'>
        <li className='asidebar-nav__item'>
          <div id='backLinkContainer' className='asidebar-nav__item__label'>
            <i
              id='backLinkIcon'
              className='c-link__icon  c-icon  c-icon--[semantic-back]  '
              aria-hidden='true'
              role='img'
            />
            <BackLink data-testid='back-link' label={i18n.t(rk.BACK_LINK)} />
          </div>
        </li>
      </ul>
      <ul className='asidebar-nav'>
        <LinkItem
          data-testid='link-item-faqs'
          label={i18n.t(rk.FAQ_COMPONENT_NAME)}
          to={FAQ_PATH}
          active={pathname.includes(`FAQs`)}
          badge={0}
          brand={brand}
        />
        <LinkItem
          data-testid='link-item-message-center'
          label={i18n.t(rk.MESSAGECENTER_COMPONENT_NAME)}
          to={INBOX_PATH}
          active={pathname.includes(`${MESSAGE_CENTER_INBOX_PATH}`)}
          badge={newmessageCount}
          brand={brand}
        />
        {country === globalConstants?.USA && (
          <LinkItem
            data-testid='link-item-doc-center'
            label={i18n.t(rk.DOCUMENT_CENTER_TITLE)}
            to={DOCUMENT_PATH}
            active={pathname.includes(`${DOCUMENT_CENTER_PATH}`)}
            brand={brand}
            badge={0}
          />
        )}
      </ul>
    </div>
  );
};

export default AsideBar;
