export enum Brand {
  AUDI = 'Audi',
  VW = 'VW',
}

export enum AccountType {
  Lease = 'Lease',
  Loan = 'Loan',
  Balloon = 'Balloon',
  Default = 'Default',
}

export enum UIBrand {
  AUDI = 'Audi',
  VW = 'VW',
  VW6 = 'VW6',
  SKODA = 'Skoda',
  DEFAULT = 'Default',
  BLUELABEL = 'BlueLabel',
}

export enum Country {
  USA = 'USA',
  Canada = 'Canada',
}

export enum Languages {
  EN_US = 'en-US',
  EN_CAN = 'en-CAN',
  FR_CAN = 'fr-CAN',
}

export interface Account {
  accountNumber: {
    value: number | undefined;
  };
  vehicle: {
    vin: { value: string | undefined };
    description: { value: string | undefined };
    make: { value: string | undefined };
    model: { value: string | undefined };
    modelYear: { value: number | undefined };
  };
}

export enum IViewSource {
  myAudi = 'myAudi',
  VWCPortal = 'VWC Portal',
  AFPortal = 'AF Portal',
  VFPortal = 'VF Portal',
  default = 'docuploadAPI',
}
