/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/*
<Project Name> Component
SelectPaymentAccount
*/
import React, { useRef, useEffect, LegacyRef } from 'react';
import { constants } from '../../../../constants';
import './BannerNotification.scss';

interface BannerNotificationProps {
  notificationStyle: string;
  children?: string;
  extendedMargin?: boolean;
  notificationIcon?: boolean;
  title?: string;
  closeIcon?: boolean;
  isOpen?: boolean;
  brand?: string;
  onClose?: () => void;
  horizontalSpacing?: boolean;
}

const BannerNotification: React.FC<BannerNotificationProps> = (props): JSX.Element | null => {
  const {
    notificationStyle,
    children = undefined,
    extendedMargin = false,
    notificationIcon = false,
    title = '',
    closeIcon = undefined,
    isOpen = true,
    brand = 'Audi',
    onClose = () => {},
    horizontalSpacing = true,
  } = props;
  const targetRef = useRef<HTMLDivElement>();
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    const currentTarget = targetRef.current as HTMLDivElement;
    currentTarget?.focus();
  }, []);
  if (!isOpen) return null;
  const closeBanner = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  return (
    <div className={`${notificationStyle}-banner c-notification__container ${horizontalSpacing ? 'u-pr u-pl' : ' '}`}>
      <div
        className={`c-notification  c-notification--${notificationStyle} ${
          extendedMargin ? 'notification-margin-bottom' : 'u-mb'
        }`}
        data-testid={`banner-notification-${notificationStyle}`}
      >
        <div
          className='c-notification__outer-wrap ariaNoBorder'
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          ref={targetRef as unknown as LegacyRef<HTMLDivElement>}
        >
          {notificationStyle === 'error' && brand === constants?.BRAND_MAPPING?.vw && (
            <i className='error-icon c-icon c-icon--[semantic-error] c-icon--xxlarge' />
          )}
          <div
            className={
              notificationStyle === 'info'
                ? 'c-notification__info c-notification__inner-wrap u-pr-none'
                : 'c-notification__inner-wrap u-pr-none'
            }
          >
            {notificationIcon && <i className='c-notification__status-icon' data-testid='notification-icon' />}
            <div className='c-notification__content'>
              {title && <h3 className='c-notification__title u-text-left'>{title}</h3>}
              <p className='c-notification__text u-text-left'>{children}</p>
            </div>
            {closeIcon && (
              <button
                type='button'
                onClick={closeBanner}
                className='c-notification__close'
                aria-expanded='true'
                aria-label='close'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerNotification;
