/* eslint-disable @typescript-eslint/no-explicit-any */
/** Get, Set, Remove and ClearALl actions on localstorage */

/**
 * Simple set data on localstorage
 * @param key
 * @param value
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const set = (key: string, value: any): void => {
  localStorage.setItem(key, value);
};
/**
 * Get item by key from localstorage
 * @param key the key
 * @returns {string} the value
 */
export const get = (key: string): any => {
  return localStorage.getItem(key);
};

/**
 * Remove item by key from localstorage
 * @param key the key
 */
export const remove = (key: string): void => {
  localStorage.removeItem(key);
};

/**
 * Clear all from localstorage
 */
export const clearAll = (): void => {
  localStorage.clear();
};
