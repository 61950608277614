// TODO: Verify eslint error "Curly braces are unnecessary here"
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { formatDistance } from 'date-fns';
import { enUS, frCA } from 'date-fns/locale';
import globalConstants from 'src/constants/globalConstants';
import { ThreadType, UIMessage } from '../IListItem.interface';
import ReplyMessage from '../../reply-message/ReplyMessage';
import { Error } from '../../../components/common/errors/Error';
import MessageDeletionNotice from '../../../components/common/message-deletion-notice/MessageDeletionNotice';
import AttachmentDownloadButton from './attachment-download-button/AttachmentDownloadButton';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';

interface MessageSectionProps {
  author: string;
  date: string;
  hour: string;
  description: string;
  active: boolean;
  attachmentId?: string;
  isReply?: boolean;
  isWelcomeMessage: boolean;
  isBatchLetter: boolean;
  originalBody: string;
}

const MessageSection = ({
  author,
  date,
  hour,
  description = '',
  attachmentId,
  isReply,
  isWelcomeMessage = false,
  isBatchLetter = false,
  originalBody,
}: Partial<MessageSectionProps>) => {
  const ref = useRef<HTMLParagraphElement>(null);

  // This behavior is added because `description` could come with unknown characters,
  // CSR is returning HTML entities not interpreted(easily) by DOM.
  // Customer text also has characters that we need to change to DOM understandable elements
  // https://www.w3schools.com/html/html_entities.asp
  useEffect(() => {
    if (ref.current) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(`<!doctype html><body>${description}`, 'text/html');
      const decodedString = dom.body.textContent;

      ref.current.innerHTML = isWelcomeMessage ? description : decodedString ?? '';
    }
  }, [description]);

  // TODO: Check date / hour order msg-content-date for french
  return (
    <article>
      <div className='author-date-container'>
        <p className='msg-content-author'>{author}</p>
        {!isWelcomeMessage && (
          <p className='msg-content-date'>{`${date} ${i18n.t(rk.MESSAGE_SENT_AT_TEXT)} ${hour}`}</p>
        )}
      </div>
      <div className='msg-content'>
        {!attachmentId && <p className='msg-content-body' ref={ref} />}
        {isReply && author === 'You' && <p className='attachment-reply'>{i18n.t(rk.MESSAGE_REPLY_ATTACHMENT_TEXT)}</p>}
        {attachmentId && (
          <AttachmentDownloadButton
            attachmentId={attachmentId}
            fileName={description}
            isBatchLetter={isBatchLetter}
            originalBody={originalBody}
          />
        )}
      </div>
    </article>
  );
};

const getProperDay = (timestamp: string, languageCode: string) => {
  const date = new Date(timestamp ?? 0);
  const currenDate = new Date();

  // I need to indentify the distance between two dates
  // in order to show hour or date
  // https://date-fns.org/v2.28.0/docs/formatDistance

  const distance = formatDistance(currenDate, date);

  const minorThan8Hours = !!distance.match(/about [1-8] hour/);
  const isMinutesDifferent = !!distance.match(/(\d{1,2} minute)|(less than a minute)/);

  const minorThan24Hours = distance.match(/about [1-9]{1,2} hours/);

  let locale;
  if (languageCode) {
    if (languageCode === globalConstants.EN_US || languageCode === globalConstants.EN_CA) {
      locale = enUS;
    } else if (languageCode === globalConstants.FR_CA) {
      locale = frCA;
    } else {
      locale = enUS;
    }
  }
  if (isMinutesDifferent || minorThan8Hours) return i18n.t(rk.MESSAGE_SENT_TODAY_TEXT);

  if (minorThan24Hours) return i18n.t(rk.MESSAGE_SENT_YESTERDAY_TEXT);

  return format(date, 'PPPP', { locale });
};

const MailBoxPreviewer = ({ messageData, brand }: { messageData: UIMessage; brand: 'vw6' | 'audi' }): JSX.Element => {
  const { title, timestamp, description, active, repliesList, type, attachments } = messageData;
  const storedProps = localStorage.getItem('appProps');
  const appProps = storedProps ? JSON.parse(storedProps) : {};
  const date =
    messageData.type !== ThreadType.WELCOME_MESSAGE ? getProperDay(timestamp ?? '', appProps?.languageCode ?? '') : '';
  const isBatchLetter = attachments && attachments[0]?.type !== globalConstants.MESSAGE_LETTERS_INDICATOR;
  const originalBody = attachments && attachments[0]?.name;
  let centralTimeDate = new Date(timestamp ?? '').toLocaleString('en-US');

  return (
    <div className={`${brand.toUpperCase()} mailbox-container__previewer`}>
      <Error />
      <p className='msg-content-title'>{title}</p>
      <div className='msg-body-container'>
        {(repliesList ?? []).map((reply, index, arr) => {
          centralTimeDate = new Date(reply.timestamp ?? '').toLocaleString('en-US');
          return (
            <React.Fragment key={reply.timestamp}>
              <MessageSection
                author={
                  reply.type === ThreadType.CSR ? i18n.t(rk.CSR_AUTHOR_TEXT) : i18n.t(rk.MAILBOX_MESSAGE_AUTHOR_USER)
                }
                hour={format(new Date(centralTimeDate), 'p')}
                date={getProperDay(reply.timestamp ?? '', appProps?.languageCode ?? '')}
                description={reply.description}
                isReply={reply.attachments && reply.attachments[0] !== null}
                attachmentId={reply.attachments[0] ? reply.attachments[0].attachmentId : ''}
                originalBody={originalBody}
              />
              {arr.length > index && <hr className='line' />}
            </React.Fragment>
          );
        })}
        {messageData.type === ThreadType.ORIGINAL && (
          <MessageSection
            author={type === ThreadType.DOCUMENT ? i18n.t(rk.CSR_AUTHOR_TEXT) : i18n.t(rk.MAILBOX_MESSAGE_AUTHOR_USER)}
            hour={format(new Date(centralTimeDate), 'p')}
            date={date}
            description={description}
            isReply={
              repliesList !== null && repliesList !== undefined && repliesList?.length > 0 && attachments[0] !== null
            }
            attachmentId={attachments[0] ? attachments[0].attachmentId : ''}
            originalBody={originalBody}
          />
        )}
        {messageData.type === ThreadType.DOCUMENT && (
          <>
            <MessageSection
              author={
                type === ThreadType.DOCUMENT ? i18n.t(rk.CSR_AUTHOR_TEXT) : i18n.t(rk.MAILBOX_MESSAGE_AUTHOR_USER)
              }
              hour={format(new Date(centralTimeDate), 'p')}
              date={date}
              description={description}
              isReply={
                repliesList !== null && repliesList !== undefined && repliesList?.length > 0 && attachments[0] !== null
              }
              attachmentId={attachments[0] ? attachments[0].attachmentId : ''}
              isBatchLetter={isBatchLetter}
              originalBody={originalBody}
            />
            <MessageDeletionNotice />
          </>
        )}
        {messageData.type === ThreadType.WELCOME_MESSAGE && (
          <MessageSection
            author={i18n.t(rk.DEFAULT_MESSAGE_AUTHOR)}
            hour=''
            date={date}
            description={messageData.description}
            isReply={false}
            attachmentId=''
            isWelcomeMessage
          />
        )}
      </div>
      {active && type !== ThreadType.DOCUMENT && type !== ThreadType.WELCOME_MESSAGE && (
        <ReplyMessage parentMessage={messageData} />
      )}
    </div>
  );
};

export default MailBoxPreviewer;
