/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useScrollToElementByHash } from 'src/hooks/Path';
import { CREATE_NEW_MESSAGE_FROM_FAQ, PAYMENT_OPTIONS_PATH } from 'src/constants/routePaths';
import { AccountType, Brand } from '../../../types';
import {
  FAQComponentItem,
  FAQComponentItemImage,
  FAQComponentItemList,
  FAQComponentItemNumberedList,
  FAQComponentItemParagraph,
  FAQComponentItemParagraphWithLinks,
  FAQComponentItemParagraphWithTitle,
  FAQComponentItemSubTitle,
  FAQComponentItemTextWithLinks,
  FAQComponentProps,
  FAQComponentSection,
  LinkType,
  NumberedListType,
  resourceKey,
} from './FAQComponent';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
// @ts-ignore
import payByPhomeDiagram from '../../../assets/images/faq/pay_by_phone_diagram.png';
// @ts-ignore
import thirdPartyAuthorizationAudi from '../../../assets/pdfs/faq/ThirdPartyAuthorization-Audi.pdf';
// @ts-ignore
import thirdPartyAuthorizationVW from '../../../assets/pdfs/faq/ThirdPartyAuthorization-VW.pdf';

// @ts-ignore
import wearAndUseAudi from '../../../assets/pdfs/faq/WearAndUse-Audi.pdf';
// @ts-ignore
import wearAndUseVW from '../../../assets/pdfs/faq/WearAndUse-VW.pdf';
// @ts-ignore
import inspectionReportAudi from '../../../assets/pdfs/faq/InspectionReport-Audi.pdf';
// @ts-ignore
import inspectionReportVW from '../../../assets/pdfs/faq/InspectionReport-VW.pdf';
// @ts-ignore
import CaliforniaReleaseOfLiabilityForm from '../../../assets/pdfs/faq/CaliforniaReleaseOfLiability.pdf';

// NOSONAR
const FAQComponentUSA: React.FC<FAQComponentProps> = ({ brand, accountType }: FAQComponentProps) => {
  const styleBrand = brand === Brand.AUDI ? Brand.AUDI : Brand.VW;
  const contactUsUrlVW = 'https://www.vw.com/en/contact.html#volkswagen-credit';
  const contactUsUrlAudi = 'https://www.audiusa.com/us/web/en/support/contact-us.html';
  const messageCenterUrl = CREATE_NEW_MESSAGE_FROM_FAQ;
  const accountSettingsUrl = '/account';
  const otherCommunicationsUrl = '/otherCommunications';
  const locateADealerAudi = 'https://www.audiusa.com/us/web/en/shopping-tools/dealer-search.html';
  const locateADealerVW = 'http://www.vw.com/find-a-dealer';
  const dmvPolicyLink = 'https://www.dmvusa.com/';

  useScrollToElementByHash(brand);
  const showTitlesReleaseDelay = process.env.REACT_APP_TITLE_RELEASE_FLAG === 'true';
  const showDDCFAQ = process.env.REACT_APP_DDC === 'true';

  return (
    <div className='c-faq' role='main'>
      <h1 className='header'>{i18n.t(rk.FAQ_TITLE)}</h1>
      <div>
        {showTitlesReleaseDelay && (
          <FAQComponentItemParagraphWithLinks
            value={i18n.t(rk.FAQ_TITLES_MESSAGES_PARAGRAPH)}
            links={[
              {
                key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                value: messageCenterUrl,
                type: LinkType.NavLink,
              },
            ]}
          />
        )}
        <FAQComponentSection value={i18n.t(rk.FAQ_TITLES)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION1)}>
            {showTitlesReleaseDelay ? (
              <FAQComponentItemParagraphWithLinks
                value={resourceKey('FAQ_TITLES_ANSWER1', styleBrand)}
                links={[
                  {
                    key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                    value: messageCenterUrl,
                    type: LinkType.NavLink,
                  },
                ]}
              />
            ) : (
              <>
                <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER1_PARAGRAPH1)} />
                <FAQComponentItemParagraphWithLinks
                  value={i18n.t(rk.FAQ_TITLES_ANSWER1_PARAGRAPH2)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                      value: messageCenterUrl,
                      type: LinkType.NavLink,
                    },
                  ]}
                />
              </>
            )}
          </FAQComponentItem>
          {showTitlesReleaseDelay && (
            <>
              <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION2)}>
                <FAQComponentItemParagraphWithLinks
                  value={i18n.t(rk.FAQ_TITLES_ANSWER2)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                      value: messageCenterUrl,
                      type: LinkType.NavLink,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION3)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_TITLES_ANSWER3', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION4)}>
                <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER4)} />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION5)}>
                <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER5_PARAGRAPH1)} />
                <FAQComponentItemNumberedList
                  type={NumberedListType.Letter}
                  items={[
                    {
                      value: i18n.t(rk.FAQ_TITLES_ANSWER5_LISTITEM1_TITLE),
                      children: i18n.t(rk.FAQ_TITLES_ANSWER5_LISTITEM1_CONTENT),
                    },
                    {
                      value: i18n.t(rk.FAQ_TITLES_ANSWER5_LISTITEM2_TITLE),
                      children: resourceKey('FAQ_TITLES_ANSWER5_LISTITEM2_CONTENT', styleBrand),
                    },
                    {
                      value: i18n.t(rk.FAQ_TITLES_ANSWER5_LISTITEM3_TITLE),
                      children: resourceKey('FAQ_TITLES_ANSWER5_LISTITEM3_CONTENT', styleBrand),
                    },
                    {
                      value: i18n.t(rk.FAQ_TITLES_ANSWER5_LISTITEM4_TITLE),
                      children: resourceKey('FAQ_TITLES_ANSWER5_LISTITEM4_CONTENT', styleBrand),
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithLinks
                  value={i18n.t(rk.FAQ_TITLES_ANSWER5_PARAGRAPH2)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                      value: messageCenterUrl,
                      type: LinkType.NavLink,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={resourceKey('FAQ_TITLES_QUESTION6', styleBrand)}>
                <FAQComponentItemParagraphWithLinks
                  value={i18n.t(rk.FAQ_TITLES_ANSWER6)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                      value: messageCenterUrl,
                      type: LinkType.NavLink,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION7)}>
                <FAQComponentItemParagraphWithLinks
                  value={i18n.t(rk.FAQ_TITLES_ANSWER7)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_TITLES_MESSAGES_LINK),
                      value: messageCenterUrl,
                      type: LinkType.NavLink,
                    },
                  ]}
                />
              </FAQComponentItem>
            </>
          )}
          <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION8)}>
            {showTitlesReleaseDelay ? (
              <FAQComponentItemParagraph value={resourceKey('FAQ_TITLES_ANSWER8_PARAGRAPH1', styleBrand)} />
            ) : (
              <FAQComponentItemParagraphWithLinks
                value={resourceKey('FAQ_TITLES_ANSWER8_PARAGRAPH1_WITHLINK', styleBrand)}
                links={[
                  {
                    key: i18n.t(rk.FAQ_TITLES_ANSWER8_LINK1),
                    type: LinkType.Nav,
                  },
                ]}
              />
            )}
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER8_PARAGRAPH2)} />
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_REGULAR_MAIL_ADDRESS_PARAGRAPH1)} bold />
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_REGULAR_MAIL_ADDRESS_PARAGRAPH2)} />
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_OVERNIGHT_MAIL_ADDRESS_PARAGRAPH1)} bold />
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_OVERNIGHT_MAIL_ADDRESS_PARAGRAPH2)} />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION9)}>
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER9)} />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION10)}>
            <FAQComponentItemParagraph value={i18n.t(rk.FAQ_TITLES_ANSWER10_PARAGRAPH1)} />
            <FAQComponentItemNumberedList
              type={NumberedListType.Number}
              items={[
                {
                  value: '',
                  children: i18n.t(rk.FAQ_TITLES_ANSWER10_LISTITEM1),
                },
                {
                  value: '',
                  children: resourceKey('FAQ_TITLES_ANSWER10_LISTITEM2', styleBrand),
                },
                {
                  value: '',
                  children: i18n.t(rk.FAQ_TITLES_ANSWER10_LISTITEM3),
                },
              ]}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_TITLES_QUESTION11)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_TITLES_ANSWER11_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={i18n.t(rk.FAQ_TITLES_ANSWER11_PARAGRAPH2)}
              links={[
                {
                  key: i18n.t(rk.FAQ_TITLES_ANSWER11_PARAGRAPH2_LINK),
                  value: dmvPolicyLink,
                  type: LinkType.NewPage,
                },
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>
        <FAQComponentSection value={i18n.t(rk.FAQ_MOVING)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_MOVING_QUESTION1)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_MOVING_ANSWER1', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_MOVING_ANSWER1_LINK),
                  value: accountSettingsUrl,
                  type: LinkType.NavLink,
                },
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_PAYMENTOPTIONS)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION1)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH1', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER1_LINK1),
                  type: LinkType.Nav,
                },
              ]}
            />
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH2', styleBrand)} />
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_TITLE', styleBrand)} />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH3_CONTENT', styleBrand)}
            />
            <FAQComponentItemImage value={payByPhomeDiagram} />
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_TITLE', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT1', styleBrand)}
              links={[
                {
                  key: i18n.t(
                    brand === Brand.AUDI ? rk.FAQ_PAYMENTOPTIONS_ANSWER1_LINK2 : rk.FAQ_PAYMENTOPTIONS_ANSWER1_LINK3
                  ),
                  value: brand === Brand.AUDI ? contactUsUrlAudi : contactUsUrlVW,
                  type: LinkType.NewPage,
                },
              ]}
            />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH4_CONTENT2', styleBrand)}
            />
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_TITLE', styleBrand)} />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH5_CONTENT', styleBrand)}
            />
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_TITLE', styleBrand)} />
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER1_PARAGRAPH6_CONTENT', styleBrand)}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION2)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER2_PARAGRAPH2', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER2_LINK1),
                  type: LinkType.Nav,
                },
              ]}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION3)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER3_PARAGRAPH2', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER3_LINK1),
                  type: LinkType.Nav,
                },
              ]}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION4)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYMENTOPTIONS_ANSWER4_PARAGRAPH1', styleBrand)} />
          </FAQComponentItem>

          <FAQComponentItem value={i18n.t(rk.FAQ_PAYMENTOPTIONS_QUESTION5)}>
            <FAQComponentItemParagraphWithLinks
              value={
                showDDCFAQ
                  ? i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_US)
                  : i18n.t(resourceKey('FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH1_US_DDC_DISABLED', styleBrand))
              }
              links={
                showDDCFAQ
                  ? [
                      {
                        key: i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LINK_US),
                        type: LinkType.NavLink,
                        value: PAYMENT_OPTIONS_PATH,
                      },
                    ]
                  : []
              }
            />
            {showDDCFAQ && (
              <>
                <p>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH2_US)}</p>
                <p>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH3_US)}</p>
                <ul>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST1_ITEM1_US)}</li>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST1_ITEM2_US)}</li>
                </ul>
                <p>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_PARAGRAPH4_US)}</p>
                <ul>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM1_US)}</li>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM2_US)}</li>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM3_US)}</li>
                  <li>{i18n.t(rk.FAQ_PAYMENTOPTIONS_ANSWER5_LIST2_ITEM4_US)}</li>
                </ul>
              </>
            )}
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_PAPERLESSBILLING)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAPERLESSBILLING_QUESTION1)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER1_PARAGRAPH2', styleBrand)} />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAPERLESSBILLING_QUESTION2)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER2_PARAGRAPH2', styleBrand)} />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAPERLESSBILLING_QUESTION3)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER3_PARAGRAPH1', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK1),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK1),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK2),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK2),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK3),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK3),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK4),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER3_LINK4),
                  type: LinkType.Email,
                },
              ]}
            />
          </FAQComponentItem>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAPERLESSBILLING_QUESTION4)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAPERLESSBILLING_ANSWER4_PARAGRAPH1', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK1),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK1),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK2),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK2),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK3),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK3),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK4),
                  value: otherCommunicationsUrl,
                  type: LinkType.NavLink,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK5),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK5),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK6),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK6),
                  type: LinkType.Email,
                },
                {
                  key: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK7),
                  value: i18n.t(rk.FAQ_PAPERLESSBILLING_ANSWER4_LINK7),
                  type: LinkType.Email,
                },
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_PAYOFFQUOTES)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_PAYOFFQUOTES_QUESTION1)}>
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_TITLE', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH1_CONTENT', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYOFFQUOTES_ANSWER1_LINK1),
                  type: LinkType.Nav,
                },
              ]}
            />
            <FAQComponentItemSubTitle value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_TITLE', styleBrand)} />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH2_CONTENT', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_PAYOFFQUOTES_ANSWER1_LINK1),
                  type: LinkType.Nav,
                },
              ]}
            />
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH3', styleBrand)} />
            <FAQComponentItemImage value={payByPhomeDiagram} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_PAYOFFQUOTES_ANSWER1_PARAGRAPH4', styleBrand)} />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_ACCIDENT)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_ACCIDENT_QUESTION1)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH2', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_ACCIDENT_ANSWER1_PARAGRAPH3', styleBrand)} />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_INSURANCEREQUIREMENTS)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_INSURANCEREQUIREMENTS_QUESTION1)}>
            <FAQComponentItemParagraph
              value={resourceKey('FAQ_INSURANCEREQUIREMENTS_ANSWER1_PARAGRAPH1', styleBrand)}
            />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_THIRDPARTYAUTHORIZATION)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_THIRDPARTYAUTHORIZATION_QUESTION1)}>
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_PARAGRAPH1', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_LINK1),
                  value: brand === Brand.AUDI ? thirdPartyAuthorizationAudi : thirdPartyAuthorizationVW,
                  type: LinkType.NewPage,
                },
                {
                  key: i18n.t(rk.FAQ_THIRDPARTYAUTHORIZATION_ANSWER1_LINK2),
                  value: messageCenterUrl,
                  type: LinkType.NavLink,
                },
              ]}
            />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_CONSUMERCREDIT)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_CONSUMERCREDIT_QUESTION1)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_CONSUMERCREDIT_ANSWER1_PARAGRAPH2', styleBrand)} />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_SCRA)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_SCRA_QUESTION1)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_SCRA_ANSWER1_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemList
              items={[
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM1', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM2', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM3', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM4', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM5', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM6', styleBrand),
                resourceKey('FAQ_SCRA_ANSWER1_LISTITEM7', styleBrand),
              ]}
            />
            <FAQComponentItemParagraphWithLinks
              value={resourceKey('FAQ_SCRA_ANSWER1_PARAGRAPH2', styleBrand)}
              links={[
                {
                  key: i18n.t(rk.FAQ_SCRA_ANSWER1_LINK1),
                  value: 'https://legalassistance.law.af.mil/',
                  type: LinkType.NewPage,
                },
              ]}
            />
            <FAQComponentItemParagraph value={resourceKey('FAQ_SCRA_ANSWER1_PARAGRAPH3', styleBrand)} />
          </FAQComponentItem>
        </FAQComponentSection>

        <FAQComponentSection value={i18n.t(rk.FAQ_TRANSFERMYACCOUNT)}>
          <FAQComponentItem value={i18n.t(rk.FAQ_TRANSFERMYACCOUNT_QUESTION1)}>
            <FAQComponentItemParagraph value={resourceKey('FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH1', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH2', styleBrand)} />
            <FAQComponentItemParagraph value={resourceKey('FAQ_TRANSFERMYACCOUNT_ANSWER1_PARAGRAPH3', styleBrand)} />
          </FAQComponentItem>
        </FAQComponentSection>

        {accountType === AccountType.Lease && (
          <>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_GENERAL)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION1)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_GENERAL_ANSWER1_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION2)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH1', styleBrand)} />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH2', styleBrand)} />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_GENERAL_ANSWER2_PARAGRAPH3_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_GENERAL_QUESTION3)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_GENERAL_ANSWER3_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
            </FAQComponentSection>

            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_TURNIN)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNIN_QUESTION1)}>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH1_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH2_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_LISTITEM1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_TURNIN_ANSWER1_LINK1_AUDI
                          : rk.FAQ_LEASE_TURNIN_ANSWER1_LINK1_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_TURNIN_ANSWER1_LINK1_AUDI
                          : rk.FAQ_LEASE_TURNIN_ANSWER1_LINK1_VW
                      ),
                      type: LinkType.Email,
                    },
                  ]}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_LISTITEM2', styleBrand)} />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_LISTITEM3', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_TURNIN_ANSWER1_LINK2),
                      value: i18n.t(brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW),
                      type: LinkType.Pdf,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_TITLE', styleBrand)}
                >
                  <FAQComponentItemTextWithLinks
                    value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH3_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_LEASE_TURNIN_ANSWER1_LINK3),
                        value: i18n.t(brand === Brand.AUDI ? locateADealerAudi : locateADealerVW),
                        type: LinkType.NewPage,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH4', styleBrand)} />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH5_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH6_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH7', styleBrand)} />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_TITLE', styleBrand)}
                >
                  <FAQComponentItemTextWithLinks
                    value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH8_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_LEASE_TURNIN_ANSWER1_LINK4),
                        value: CaliforniaReleaseOfLiabilityForm,
                        type: LinkType.Pdf,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH9', styleBrand)} />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_TURNIN_ANSWER1_PARAGRAPH10', styleBrand)} />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_EARLYLEASETERMINATION)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_EARLYTERMINATION_QUESTION1)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH1', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_EARLYTERMINATION_ANSWER1_PARAGRAPH2', styleBrand)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_PURCHASING)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION1)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_PURCHASING_ANSWER1_LINK1),
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH2', styleBrand)} />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH3_CONTENT', styleBrand)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH4_CONTENT', styleBrand)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH5_CONTENT', styleBrand)}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER1_PARAGRAPH6', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION2)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_PURCHASING_ANSWER2_LINK1),
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH2', styleBrand)} />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER2_PARAGRAPH3_CONTENT', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_PURCHASING_ANSWER2_LINK2),
                      value:
                        brand === Brand.AUDI
                          ? 'https://www.audiusa.com/myaudi/protection-plans'
                          : 'https://purchase.vwdriveeasy.com/',
                      type: LinkType.NewPage,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_PURCHASING_QUESTION3)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_LEASE_PURCHASING_ANSWER3_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_QUESTION1)}>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE', styleBrand)}
                >
                  <FAQComponentItemParagraphWithLinks
                    value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK1),
                        value: 'https://selfschedule.aiminspect.com/',
                        type: LinkType.NewPage,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK2),
                      value: i18n.t(brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW),
                      type: LinkType.Pdf,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE', styleBrand)}
                >
                  <FAQComponentItemParagraphWithLinks
                    value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK1),
                        value: i18n.t(brand === Brand.AUDI ? inspectionReportAudi : inspectionReportVW),
                        type: LinkType.Pdf,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      type: LinkType.Email,
                    },
                    {
                      key: i18n.t(rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK4),
                      value: i18n.t(brand === Brand.AUDI ? locateADealerAudi : locateADealerVW),
                      type: LinkType.NewPage,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5', styleBrand)}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      type: LinkType.Email,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION1)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1', styleBrand)}
                />
                <FAQComponentItemList
                  items={[
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM1', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM2', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM3', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM4', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM5', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM6', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM7', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM8', styleBrand),
                    resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LISTITEM9', styleBrand),
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_TITLE', styleBrand)}
                >
                  <FAQComponentItemTextWithLinks
                    value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_LINK1),
                        value: i18n.t(brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW),
                        type: LinkType.Pdf,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH3', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH4', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH5', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION2)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1),
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_QUESTION3)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_AUDI
                          : rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_AUDI
                          : rk.FAQ_LEASE_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1_VW
                      ),
                      type: LinkType.Email,
                    },
                  ]}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_SELLINGTOTHIRDPARTY)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_SELLINGTOTHIRDPARTY_QUESTION1)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH1', styleBrand)}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_LEASE_SELLINGTOTHIRDPARTY_ANSWER1_PARAGRAPH2_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_LEASE_TITLEANDREGISTRATION)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TITLEANDREGISTRATION_QUESTION1)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TITLEANDREGISTRATION_ANSWER1_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_LEASE_TITLEANDREGISTRATION_QUESTION2)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_LEASE_TITLEANDREGISTRATION_ANSWER2_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
          </>
        )}

        {accountType === AccountType.Balloon && (
          <>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_GENERAL)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_GENERAL_QUESTION1)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER1_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_GENERAL_QUESTION2)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER2_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI ? rk.FAQ_BALLOON_GENERAL_QUESTION3_AUDI : rk.FAQ_BALLOON_GENERAL_QUESTION3_VW
                )}
              >
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH1', styleBrand)} />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_BALLOON_GENERAL_ANSWER3_PARAGRAPH2_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI ? rk.FAQ_BALLOON_GENERAL_QUESTION4_AUDI : rk.FAQ_BALLOON_GENERAL_QUESTION4_VW
                )}
              >
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER4_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_GENERAL_ANSWER4_LINK1_AUDI
                          : rk.FAQ_BALLOON_GENERAL_ANSWER4_LINK1_VW
                      ),
                      value: i18n.t(brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW),
                      type: LinkType.Pdf,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_GENERAL_QUESTION5)}>
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_GENERAL_ANSWER5_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_TURNIN)}>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI ? rk.FAQ_BALLOON_TURNIN_QUESTION1_AUDI : rk.FAQ_BALLOON_TURNIN_QUESTION1_VW
                )}
              >
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER1_PARAGRAPH1', styleBrand)} />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI ? rk.FAQ_BALLOON_TURNIN_QUESTION2_AUDI : rk.FAQ_BALLOON_TURNIN_QUESTION2_VW
                )}
              >
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH1', styleBrand)} />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH2', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNIN_ANSWER2_LINK1),
                      value: 'https://selfschedule.aiminspect.com/',
                      type: LinkType.NewPage,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH3', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNIN_ANSWER2_LINK2),
                      value: brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW,
                      type: LinkType.Pdf,
                    },
                  ]}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH4', styleBrand)} />
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH5', styleBrand)} />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH6_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH7', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNIN_ANSWER2_LINK3),
                      value: brand === Brand.AUDI ? locateADealerAudi : locateADealerVW,
                      type: LinkType.NewPage,
                    },
                  ]}
                />
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH8', styleBrand)} />
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH9', styleBrand)} />
                <FAQComponentItemParagraph value={resourceKey('FAQ_BALLOON_TURNIN_ANSWER2_PARAGRAPH10', styleBrand)} />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_PURCHASING)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PURCHASING_QUESTION1)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH1', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PURCHASING_ANSWER1_PARAGRAPH2', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PURCHASING_QUESTION2)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PURCHASING_ANSWER2_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PURCHASING_QUESTION3)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PURCHASING_ANSWER3_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_PURCHASING_QUESTION4)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_PURCHASING_ANSWER4_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS_QUESTION1)}>
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_TITLE', styleBrand)}
                >
                  <FAQComponentItemTextWithLinks
                    value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH1_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK1),
                        value: 'https://selfschedule.aiminspect.com/',
                        type: LinkType.NewPage,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH2', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK2),
                      value: i18n.t(brand === Brand.AUDI ? wearAndUseAudi : wearAndUseVW),
                      type: LinkType.Pdf,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_TITLE', styleBrand)}
                >
                  <FAQComponentItemTextWithLinks
                    value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH3_CONTENT', styleBrand)}
                    links={[
                      {
                        key: i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK1),
                        value: i18n.t(brand === Brand.AUDI ? inspectionReportAudi : inspectionReportVW),
                        type: LinkType.Pdf,
                      },
                    ]}
                  />
                </FAQComponentItemParagraphWithTitle>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH4', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      type: LinkType.Email,
                    },
                    {
                      key: i18n.t(rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK4),
                      value: i18n.t(brand === Brand.AUDI ? locateADealerAudi : locateADealerVW),
                      type: LinkType.NewPage,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH5', styleBrand)}
                />
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH6', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_AUDI
                          : rk.FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_LINK3_VW
                      ),
                      type: LinkType.Email,
                    },
                  ]}
                />
                <FAQComponentItemParagraphWithTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_TITLE', styleBrand)}
                >
                  {resourceKey('FAQ_BALLOON_VEHICLEINSPECTIONS_ANSWER1_PARAGRAPH7_CONTENT', styleBrand)}
                </FAQComponentItemParagraphWithTitle>
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE)}>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION1)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_LINK1),
                      type: LinkType.Nav,
                    },
                  ]}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER1_PARAGRAPH2', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION2)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_AUDI
                          : rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_VW
                      ),
                      value: i18n.t(
                        brand === Brand.AUDI
                          ? rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_AUDI
                          : rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_LINK1_VW
                      ),
                      type: LinkType.Email,
                    },
                  ]}
                />
                {brand === Brand.VW && (
                  <FAQComponentItemParagraph
                    value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER2_PARAGRAPH2', styleBrand)}
                  />
                )}
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION3)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1),
                      value: i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER3_LINK1),
                      type: LinkType.Email,
                    },
                  ]}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_QUESTION4)}>
                <FAQComponentItemParagraphWithLinks
                  value={resourceKey('FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER4_PARAGRAPH1', styleBrand)}
                  links={[
                    {
                      key: i18n.t(rk.FAQ_BALLOON_TURNINSETTLEMENTINVOICE_ANSWER4_LINK1),
                      value: wearAndUseVW,
                      type: LinkType.Pdf,
                    },
                  ]}
                />
              </FAQComponentItem>
            </FAQComponentSection>
            <FAQComponentSection value={i18n.t(rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION)}>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION1_AUDI
                    : rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION1_VW
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH1', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH2', styleBrand)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH3', styleBrand)}
                />
                <br />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH4_CONTENT', styleBrand)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH5_CONTENT', styleBrand)}
                />
                <FAQComponentItemSubTitle
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_TITLE', styleBrand)}
                />
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER1_PARAGRAPH6_CONTENT', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION2)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER2_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION3_AUDI
                    : rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION3_VW
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER3_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION4_AUDI
                    : rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION4_VW
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER4_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION5)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER5_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem value={i18n.t(rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION6)}>
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER6_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
              <FAQComponentItem
                value={i18n.t(
                  brand === Brand.AUDI
                    ? rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION7_AUDI
                    : rk.FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_QUESTION7_VW
                )}
              >
                <FAQComponentItemParagraph
                  value={resourceKey('FAQ_BALLOON_VEHICLETITLEANDREGISTRATION_ANSWER7_PARAGRAPH1', styleBrand)}
                />
              </FAQComponentItem>
            </FAQComponentSection>
          </>
        )}
      </div>
    </div>
  );
};

export default FAQComponentUSA;
