import React, { useEffect, useState } from 'react';
import MessageDeletionNotice from 'src/views/components/common/message-deletion-notice/MessageDeletionNotice';
import Switch from 'src/views/components/common/Switch';
import { useMessageContext } from 'src/context/MessageContext';
import { MessageTypes } from 'src/constants/componentConstants';
import { postMessage } from 'src/utility/Utilities';
import MessageTextArea from '../../components/common/message-textarea/MessageTextArea';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import { ThreadType, UIMessage } from '../mailbox/IListItem.interface';

const ReplyMessage = ({ parentMessage }: { parentMessage: UIMessage }): JSX.Element => {
  const storedProps = localStorage.getItem('appProps');
  const appProps = storedProps ? JSON.parse(storedProps) : {};
  const [status, setStatus] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { sentMessage } = useMessageContext();

  /*
   * I'm getting the first item from replies and indetifying whether is CSR response.
   * that means you already replied to CSR, now you can't do it again,
   * hence you need to get answer from CSR.
   * */
  useEffect(() => {
    if (sentMessage && sentMessage.id === parentMessage.id) {
      setStatus(false);
      setButtonDisabled(true);
    } else {
      const firstItem = (parentMessage.repliesList ?? []).concat().shift();
      setButtonDisabled(firstItem?.type === ThreadType.REPLY);
    }
  }, [parentMessage]);

  return (
    <Switch value={status} defaultChild='empty placeholder'>
      <Switch.Item case={false}>
        <MessageDeletionNotice />
        {(!sentMessage || sentMessage.type === ThreadType.REPLY) && (
          <div className='sg-component-variant'>
            <button
              data-testid='reply-button'
              className={`c-btn  c-btn--secondary c-btn--icon reply-button ${status ? 'active-button' : ''}`}
              disabled={isButtonDisabled}
              type='button'
              onClick={() => {
                postMessage(appProps.parentDomain, MessageTypes.MESSAGE_CENTER_REPLY_STARTED, '');
                setStatus(true);
              }}
            >
              <i className='c-btn__icon c-icon c-icon--[semantic-contact]' aria-hidden='true' role='img' />
              <span className='c-btn__text'>{i18n.t(rk.REPLY_TEXT)}</span>
            </button>
          </div>
        )}
      </Switch.Item>
      <Switch.Item case>
        <div className='reply-message'>
          <hr className='reply-hr' />
          <MessageTextArea parentMessage={parentMessage} />
        </div>
      </Switch.Item>
    </Switch>
  );
};

export default ReplyMessage;
