import React, { ReactChild, ReactElement, ReactNode, ReactFragment, ReactPortal } from 'react';

/**
 * this function look up if the ReactNode is fragment type and
 * it will return their children, otherwise it will return the same child.
 * @param child React.ReactNode
 * @returns React.ReactNode
 */
export const unwrapFragment = (child: ReactNode): ReactElement | ReactNode => {
  const localchild = child as ReactElement;

  return localchild?.type === React.Fragment ? localchild.props.children : child;
};

/**
 * this factory function(closure) used to look up if a child has the same type and it is returned
 * @param child React.ReactNode
 * @returns React.ReactNode
 */
export const createGetChild =
  (child: ReactNode) =>
  (type: ReactNode): ReactChild | ReactFragment | ReactPortal | undefined => {
    return React.Children.toArray(unwrapFragment(child)).find((_child) => (_child as ReactElement)?.type === type);
  };
