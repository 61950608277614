import React from 'react';
import { INotification } from 'src/context/NotificationContext.interface';
import i18n from 'src/localization/i18n';
import * as rk from 'src/localization/resourceKeys';
import { useAuthContext } from '../../../../context/AuthContext';
import { Brand } from '../../../../types';

interface NotificationProps {
  notification: INotification;
  removeNotification: (id: string) => void;
}

const Notification: React.FC<NotificationProps> = ({ notification, removeNotification }: NotificationProps) => {
  const { id, message, action, style } = notification;
  const { state: authState } = useAuthContext();
  return (
    <article className={`c-toast-notification  c-toast-notification--${style}`} aria-live='polite' role='status'>
      <div className='c-toast-notification__content'>
        <div className='c-toast-notification__body'>
          {message}
          {style === 'error' && (
            <a
              href='#0'
              onClick={() => {
                action();
                removeNotification(id);
              }}
              className={authState.brand === Brand.VW ? 'u-text-white' : ''}
            >
              {i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR_2)}
            </a>
          )}
        </div>
        <button
          type='button'
          title='Close'
          className='c-toast-notification__close'
          onClick={() => {
            removeNotification(id);
          }}
        >
          <span className='u-visually-hidden'>Close</span>
        </button>
      </div>
    </article>
  );
};

export default Notification;
