import React, { useEffect, useState } from 'react';
import { getDocumentEligibilityService } from 'src/services/message-center/get-documents';
import { useAuthContext } from './AuthContext';
import { useLoader } from './LoadingContext';
import { IDocCenterAccountContext, IDocumentEligibility } from './DocCenterAccountContext.interface';

export const DocCenterAccountContext = React.createContext<IDocCenterAccountContext | null>(null);

export const DocCenterAccountProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [documentEligibility, setDocumentEligibility] = useState<IDocumentEligibility>({
    IsSuccess: false,
    IsPaidInFullLetterEligible: false,
    IsTurnInLetterEligible: false,
    IsZeroBalanceLetterEligible: false,
  });
  const [error, setError] = useState<boolean>(false);

  const { state: authState } = useAuthContext();
  const { stopLoader, startLoader, isLoading } = useLoader({
    isLoading: true,
    local: false,
  });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const getDocumentEligibility = async () => {
    try {
      if (!isLoading) startLoader();
      if (!firstLoad) {
        const response = await getDocumentEligibilityService(authState);
        if (response?.data?.GetDocumentEligibility?.IsSuccess) {
          setDocumentEligibility(response?.data?.GetDocumentEligibility);
          setFirstLoad(true);
        } else {
          stopLoader();
          throw new Error();
        }
      }
      stopLoader();
    } catch (e) {
      setError(true);
      // HANDLE API ERROR
      stopLoader();
    }
  };

  const value: IDocCenterAccountContext = {
    documentEligibility,
    error,
    getDocumentEligibility,
  };

  return (
    <>
      <DocCenterAccountContext.Provider value={value}>{children}</DocCenterAccountContext.Provider>
    </>
  );
};
