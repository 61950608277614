import React, { ReactElement, ReactNode } from 'react';

const Switch = ({
  value,
  children,
  defaultChild,
}: {
  defaultChild: ReactNode;
  value: string | number | boolean;
  children: ReactNode;
}): ReactElement | null => {
  if (!children) return <></>;

  const child = (children as ReactElement[]).find((_child) => _child?.props?.case === value) as ReactElement;

  return child ?? defaultChild;
};

Switch.Item = ({ children }: { case: string | number | boolean; children: ReactNode }): ReactElement | null => {
  return children as ReactElement;
};

export default Switch;
