/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogger } from 'browser-bunyan';
// @ts-ignore
import splunkBunyan from 'splunk-bunyan-logger';

const config = {
  token: process.env.REACT_APP_SPLUNK_TOKEN || '909e2756-ff75-48fd-a9ea-88f4c963ff82',
  url: process.env.REACT_APP_SPLUNK_URL || 'https://hec.vwcredit.com',
  port: process.env.REACT_APP_SPLUNK_PORT || 443,
};

const splunkStream = splunkBunyan.createStream(config);

splunkStream.on('error', (err: any, context: any) => {
  console.log('Error in splunk stream ===>', err, 'Context===>', context);
});

// Note: splunkStream must be set to an element in the streams array
const splunkLogger = createLogger({
  name: (process.env.REACT_APP_SOURCE_ID as string) || 'vci-messagecenter-app',
  streams: [splunkStream],
});

export default splunkLogger;
