import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ChildrenType } from 'src/types/generic-types';
// eslint-disable-next-line react/prop-types
const HelmetPersist = ({ children }: { children: ChildrenType }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!!loaded === false) return undefined;

    /**
     * we're going to remove data-react-helmet attribute, because all elements with that attribute are removed/replaced
     * when another <Helmet> component is mounted, I.e: when we work with Porta and dashboard and those are mounted their own Helmet,
     * replaces all tags with `data-react-helmet` from old helmet.
     * see it here. https://github.com/nfl/react-helmet/blob/1b57ddb1524bab195df3eeabafef67768a6d2a15/src/HelmetUtils.js#L425
     */
    document.querySelectorAll('[data-persist]').forEach((element) => element.removeAttribute('data-react-helmet'));

    return undefined;
  }, [loaded]);

  return <Helmet onChangeClientState={() => setLoaded(true)}>{children}</Helmet>;
};

export default HelmetPersist;
