/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import { DocCenterAccountContext } from 'src/context/DocCenterAccountContext';
import { IDocCenterAccountContext } from 'src/context/DocCenterAccountContext.interface';
import {
  getPaidInFullLetterService,
  getZeroBalanceLetterService,
  getTurnInLetterService,
} from 'src/services/message-center/get-documents';
import { useAuthContext } from 'src/context/AuthContext';
import { NotificationContext } from 'src/context/NotificationContext';
import { INotificationContext } from 'src/context/NotificationContext.interface';
import { uuidv4, b64toBlob } from 'src/utility/Utilities';
import globalConstants from '../../../constants/globalConstants';
import { DocCenterContext } from '../../../context/DocCenterContext';
import { IDocumentContext } from '../../../context/DocCenterContext.interface';
import '../../pages/document-center/DocumentCenter.scss';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import Spinner from '../common/spinner/Spinner';
import './Filters.scss';

interface LetterCardProps {
  titleKey: string;
  downloadHandler: () => void;
  status: string;
  authState: {
    brand: string;
  };
  testId: string;
}

const LetterCard = ({ titleKey, downloadHandler, status, authState, testId }: LetterCardProps) => {
  return (
    <article className='c-card letter-card-item'>
      <header className='c-card__header u-p'>
        <div className='c-card__header-items'>
          <div className='c-card__title u-mr letter-title'> {i18n.t(titleKey)} </div>
          <div className='letter-download-btn'>
            <button
              onClick={() => {
                if (status === 'notDownloading') downloadHandler();
              }}
              type='button'
              className='c-link c-download-link'
              id={`${testId}-id`}
            >
              <div className={`status ${authState.brand.toLowerCase()}`}>
                {status === 'notDownloading' && (
                  <i className='c-icon c-icon--[semantic-download]' aria-hidden='true' role='img' />
                )}
                {status === 'downloading' && <Spinner spinnerType='small' ariaLabel />}
                {status === 'error' && (
                  <i
                    className={`c-icon ${
                      authState.brand.toLowerCase() === 'audi' && 'u-border u-round'
                    } c-icon--[semantic-crossed-out]`}
                    aria-hidden='true'
                    role='img'
                  />
                )}
                {status === 'success' && (
                  <i
                    className={`c-icon ${
                      authState.brand.toLowerCase() === 'audi' && 'u-border u-round'
                    } c-icon--[semantic-checkmark]`}
                    aria-hidden='true'
                    role='img'
                  />
                )}
              </div>
            </button>
          </div>
        </div>
      </header>
    </article>
  );
};

interface StatusObject<T> {
  [index: string]: string;
}

const Filters: React.FC = () => {
  const DocumentContext = useContext(DocCenterContext) as IDocumentContext;
  const DocumentEligibilityContext = useContext(DocCenterAccountContext) as IDocCenterAccountContext;
  const [year, setYear] = useState<string>('All');
  const [type, setType] = useState<string>('All');
  const [showLettersCard, setShowLettersCard] = useState<boolean>(true);
  const showPaidInFullLetter = DocumentEligibilityContext?.documentEligibility?.IsPaidInFullLetterEligible;
  const showZeroBalanceLetter = DocumentEligibilityContext?.documentEligibility?.IsZeroBalanceLetterEligible;
  const showTurnInLetter = DocumentEligibilityContext?.documentEligibility?.IsTurnInLetterEligible;
  const [showMostRecentTab, setShowMostRecentTab] = useState<boolean>(false);
  const statusState: StatusObject<object> = {
    PaidInFullLetter: 'notDownloading',
    ZeroBalanceLetter: 'notDownloading',
    TurnInLetter: 'notDownloading',
  };
  const [status, setStatus] = useState(statusState);
  const { state: authState } = useAuthContext();
  const { addNotification, notifications, removeNotification } = useContext(
    NotificationContext
  ) as INotificationContext;
  const [id] = useState<string>(uuidv4());

  useEffect(() => {
    const find = notifications.find((c) => c.id === id);
    let timer: ReturnType<typeof setTimeout>;

    if (!find) {
      Object.keys(status).forEach((key) => {
        if (status[key] === 'error' || status[key] === 'success') {
          setStatus({ ...status, [key]: 'notDownloading' });
        }
      });
    } else {
      timer = setTimeout(() => {
        removeNotification(id);
      }, 5500);
    }

    return () => clearTimeout(timer);
  }, [notifications, status]);

  useEffect(() => {
    DocumentContext.setFilters({
      year,
      documentType: type,
    });
  }, [year, type]);

  const documentTypes = globalConstants.DOCUMENT_MAPPING;
  const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value);
  };
  const handleChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(event.target.value);
  };

  const downloadDocument = async (serviceFn: any, fileName: string) => {
    const handleSuccess = () => {
      const newStatus = { ...status, [fileName]: 'success' };
      setStatus(newStatus);

      addNotification({
        message: i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_SUCCESS),
        style: 'success',
        action: () => true,
        id,
      });
    };

    const handleError = (errorMessage: string) => {
      const newStatus = { ...status, [fileName]: 'error' };
      setStatus(newStatus);

      addNotification({
        message: errorMessage || i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR),
        style: 'error',
        action: () => downloadDocument(serviceFn, fileName),
        id,
      });
    };

    try {
      setStatus({ ...status, [fileName]: 'downloading' });
      const response = await serviceFn(authState);

      if (response.IsSuccess) {
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);

        // Convert to a BLOB of data.
        const blobData = b64toBlob(response.DocumentContent, 'application/pdf');

        // Add the href attribute with the blob data and click it.
        downloadLink.href = window.URL.createObjectURL(blobData);
        downloadLink.setAttribute('download', `${fileName}.pdf`);
        downloadLink.click();

        // Clean up the element we created for the download.
        window.URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);

        handleSuccess();
      } else {
        handleError(i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR));
      }
    } catch (error) {
      console.error('Download error:', error);
      handleError(i18n.t(rk.DOCUMENT_CENTER_NOTIFICATION_ERROR));
    }
  };

  const handlePaidInFullDownload = () => {
    downloadDocument(getPaidInFullLetterService, 'PaidInFullLetter');
  };

  const handleZeroBalanceLetterDownload = () => {
    downloadDocument(getZeroBalanceLetterService, 'ZeroBalanceLetter');
  };

  const handleTurnInLetterDownload = () => {
    downloadDocument(getTurnInLetterService, 'TurnInLetter');
  };

  useEffect(() => {
    if (showZeroBalanceLetter || showPaidInFullLetter || showTurnInLetter) {
      setShowMostRecentTab(true);
    }
  }, [showZeroBalanceLetter, showPaidInFullLetter, showTurnInLetter]);
  return (
    <>
      {showMostRecentTab && (
        <div className='o-layout__item u-mb-small'>
          <article className='c-card letter-container'>
            <header className='c-card__header u-p'>
              <div className='c-card__header-items'>
                <div className='c-card__title letter-container__header'> {i18n.t(rk.DOCUMENT_CENTER_MOST_RECENT)} </div>
                <div id='card-container-toggle-btn' onClick={() => setShowLettersCard(!showLettersCard)}>
                  <i className={`c-icon  c-icon--[semantic-${showLettersCard ? 'collapse' : 'expand'}]`} role='img' />
                </div>
              </div>
            </header>
            {showLettersCard && (
              <div className='c-card__body js-card__body u-pt-none'>
                <div className='card-content'>
                  {showZeroBalanceLetter && (
                    <LetterCard
                      titleKey={rk.DOCUMENT_CENTER_ZERO_BALANCE_LETTER}
                      downloadHandler={handleZeroBalanceLetterDownload}
                      status={status.ZeroBalanceLetter}
                      authState={authState}
                      testId='zero-balance-letter'
                    />
                  )}
                  {showPaidInFullLetter && (
                    <LetterCard
                      titleKey={rk.DOCUMENT_CENTER_PAID_IN_FULL_LETTER}
                      downloadHandler={handlePaidInFullDownload}
                      status={status.PaidInFullLetter}
                      authState={authState}
                      testId='paid-in-full-letter'
                    />
                  )}
                  {showTurnInLetter && (
                    <LetterCard
                      titleKey={rk.DOCUMENT_CENTER_TURN_IN_LETTER}
                      downloadHandler={handleTurnInLetterDownload}
                      status={status.TurnInLetter}
                      authState={authState}
                      testId='turn-in-letter'
                    />
                  )}
                </div>
              </div>
            )}
          </article>
        </div>
      )}
      <div className='o-layout__item u-1/3 u-1/1@xs u-mt-none@xs'>
        <div className='c-input  c-input--select'>
          <select className='c-input__input' onChange={handleChangeType}>
            <option value='All'>{i18n.t(rk.DOCUMENT_CENTER_TYPE_ALL)}</option>
            {Object.values(documentTypes).map((documentType, index) => {
              return (
                /* eslint-disable react/no-array-index-key */
                <option value={documentType} key={index}>
                  {documentType}
                </option>
              );
            })}
          </select>
        </div>
        <p className='u-mt-small select__text'>{i18n.t(rk.DOCUMENT_CENTER_TYPE)}</p>
      </div>
      <div className='o-layout__item u-1/3 u-1/1@xs u-mt-none@xs'>
        <div className='c-input  c-input--select'>
          <select className='c-input__input' onChange={handleChangeYear}>
            <option value='All'>{i18n.t(rk.DOCUMENT_CENTER_YEAR_ALL)}</option>
            {Object.values(DocumentContext.yearIndex).map((yearValue, index) => {
              return (
                /* eslint-disable react/no-array-index-key */
                <option value={yearValue} key={index}>
                  {yearValue}
                </option>
              );
            })}
          </select>
        </div>
        <p className='u-mt-small select__text'>{i18n.t(rk.DOCUMENT_CENTER_YEAR)}</p>
      </div>
    </>
  );
};

export default Filters;
