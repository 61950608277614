/* eslint-disable import/prefer-default-export */
import React, { createContext, useContext } from 'react';
/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A>(
  tag: string
): readonly [() => A, React.Provider<A | undefined>, React.Consumer<A | undefined>] {
  const ctx = createContext<A | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error(`${tag} -- useContext must be inside a Provider with a value`);
    return c;
  }
  return [useCtx, ctx.Provider, ctx.Consumer] as const; // 'as const' makes TypeScript infer a tuple
}
