import axios, { AxiosError, AxiosResponse, AxiosPromise } from 'axios';
import { useEffect } from 'react';

export type HandleResponseType = (res: AxiosResponse) => AxiosResponse;
export type HandleErrorType = (err: AxiosError) => AxiosPromise;

export const BASE_URL = `${process.env.REACT_APP_MESSAGE_CENTER_API_HOST}`;

/**
 * Axios client instance to make requests
 *
 * You will need to get an initialized token to use here for the bearer token
 */
// CONS token
export const apiClient = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export type InterceptorIdType = number | undefined;

/**
 * Custom hook to setup handles for success and fail response of axios request
 * @param handleResponse callback which receive the response and needs to return the same response
 * @param handleError callback which receive the error and needs to reject the same error
 */
export const useAxiosInterceptors = (
  handleResponse: HandleResponseType,
  handleError: HandleErrorType
): { interceptorId: InterceptorIdType } => {
  let interceptorId: InterceptorIdType;
  useEffect(() => {
    interceptorId = apiClient.interceptors.response.use(handleResponse, handleError);
    return () => {
      apiClient.interceptors.response.eject(interceptorId as number);
      interceptorId = undefined;
    };
  }, []);
  return { interceptorId };
};
