import React from 'react';

enum SpinnerTypeEnum {
  'center',
  'fullscreen',
  'small',
}

export interface SpinnerProps {
  children: React.ReactNode;
  ariaLabel: boolean;
  spinnerType: keyof typeof SpinnerTypeEnum;
  id: string;
}

const Spinner = (props: Partial<SpinnerProps>): JSX.Element => {
  const { children, ariaLabel, spinnerType = 'center', id } = props;

  const screen = {
    fullscreen: 'c-spinner--full-page',
    center: 'c-spinner--center',
    small: 'c-spinner--small',
  };

  return (
    <div id={id} className={`c-spinner ${screen[spinnerType]}`} aria-live='polite' aria-busy={ariaLabel}>
      {children}
      <div className='c-spinner__loader'>
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
      </div>
    </div>
  );
};

export default Spinner;
