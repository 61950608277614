import { IAuthState } from 'src/context/AuthContext';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const SEND_MESSAGE_ENDPOINT = '/postmessage';

const saveAttachmentsService = (
  params: { subjectTopicId: number | string; body: File[] },
  auth: IAuthState
): Promise<void> => {
  const dto = new FormData();

  dto.append('subjectTopicId', params.subjectTopicId.toString());

  params.body.forEach((att) => {
    dto.append('body', att);
  });

  return apiClient
    .post(SEND_MESSAGE_ENDPOINT, params, {
      headers: {
        ...buildHeaderParams(auth),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      console.debug('Success calling saveAttachmentsService');
      return response.data;
    });
};

export default saveAttachmentsService;
