import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMessageContext } from 'src/context/MessageContext';
import { UIMessage, ThreadType } from '../IListItem.interface';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';
import ListItemMessage from './list-item-message/ListItemMessage';
import './MailboxNavigator.scss';
import globalConstants from '../../../../constants/globalConstants';

const unreadMessage = (message: UIMessage) => {
  if (message.unread) return message;
  return message.repliesList?.find((mes) => mes.unread);
};

const MailBoxNavigator = ({
  onClickMessage,
  brand,
  messages,
  hasNewSentMessage,
}: {
  onClickMessage: (cardData: UIMessage) => void;
  brand: 'vw6' | 'audi';
  messages: UIMessage[];
  hasNewSentMessage: boolean;
}): JSX.Element => {
  const params = useParams<{ messageId: string }>();
  const messageId = params?.messageId;

  const { updateMessageStatus } = useMessageContext();

  const onClick = (message: UIMessage) => {
    const tmpMessage = unreadMessage(message);

    if (tmpMessage) {
      if (tmpMessage.type === ThreadType.DOCUMENT) {
        const { id } = tmpMessage as UIMessage;
        updateMessageStatus(id as string, true, true);
      } else {
        const { threadId } = tmpMessage as UIMessage;
        updateMessageStatus(threadId as string, true, false);
      }
    }
    onClickMessage(message);
  };

  // Determines the proper label to use depending on the message type.
  // TODO: Make sent message another thread type to check only that.
  const getMessageLabel = (message: UIMessage): string => {
    if (hasNewSentMessage && message.id === 1) {
      return i18n.t(rk.MAILBOX_MESSAGE_AUTHOR_USER);
    }

    if (message.type === ThreadType.WELCOME_MESSAGE) {
      return i18n.t(rk.DEFAULT_MESSAGE_AUTHOR);
    }

    return i18n.t(rk.CSR_AUTHOR_TEXT);
  };

  const localMessages = React.useMemo(() => {
    const isMessageActive = messages.find((mes) => mes.active);

    if (!!isMessageActive === false) {
      return messages.map((mes) => (+messageId === mes.id ? { ...mes, active: true } : { ...mes, active: false }));
    }

    return messages;
  }, [messageId, messages]);

  const getIconClassName = (uiBrand: string): string => {
    return uiBrand.toLowerCase() === globalConstants.AUDI_BRAND
      ? 'c-icon  c-icon--[semantic-edit] c-icon--small'
      : 'c-icon  c-icon--[edit]';
  };

  return (
    <nav className={`${brand.toUpperCase()} mailbox__navigator`} aria-label='inbox navigation'>
      <header className='mailbox__navigator__header'>
        <h1 className='header__title'>{i18n.t(rk.BACK_LINK_MESSAGES_TEXT)}</h1>
        <Link to='new-message' className='c-link' data-testid='new-message-button' aria-label='create a new message'>
          <i className={getIconClassName(brand)} aria-hidden='true' role='img' />
        </Link>
      </header>
      <ul className='mailbox__navigator__messages-list' data-testid='messages-list'>
        {localMessages.map((message) => (
          <ListItemMessage
            key={message.id}
            label={getMessageLabel(message)}
            timestamp={message.timestamp}
            title={message.title}
            description={message.description}
            unread={!message.active && message.unread}
            onClick={() => onClick(message)}
            active={message.active}
            hasAttachment={message.type === ThreadType.DOCUMENT}
            brand={brand}
            type={message.type}
          />
        ))}
      </ul>
    </nav>
  );
};
export default MailBoxNavigator;
