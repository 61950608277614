import React, { useRef, useState } from 'react';
import { ChildrenType } from 'src/types/generic-types';
import { createCtx } from 'src/utility/Helper';

type StateType = { isHidden: boolean; isUpdated: boolean };
type EffectFunction = (state: StateType, action?: Action, showLabel?: string) => void;
type Action = 'CANCEL' | 'OK';

type DialogContextType = {
  state: StateType;
  setUpdate: (value: boolean) => void;
  hideDialog: (action: Action) => void;
  showDialog: (label?: string) => void;
  watchEffect: (callbackFn: EffectFunction) => void;
};

const [useCtx, DialogCtxtProvider] = createCtx<DialogContextType>('DialogContextProvider');

// show label is used to find out where it is showed, to know what behavior to do.
let showLabel: undefined | string;
export const useDialogContext = (): DialogContextType => {
  const { state, showDialog: onShow, hideDialog: onHide, setUpdate } = useCtx();
  const observables = useRef<Map<string, EffectFunction>>(new Map()).current;

  const hideDialog = (action: Action) => {
    Array.from(observables.values()).forEach((fn) => fn({ ...state, isHidden: true }, action, showLabel));
    // reset show label
    showLabel = undefined;
    if (action === 'OK') {
      setUpdate(false);
    }
    onHide(action);
  };

  const showDialog = (label: undefined | string) => {
    showLabel = label;
    Array.from(observables.values()).forEach((fn) => fn({ ...state, isHidden: false }));
    onShow();
  };

  const watchEffect = (callbackFn: EffectFunction) => {
    const alreadyExists = observables.has(callbackFn.toString());

    if (alreadyExists) return;

    observables.set(callbackFn.toString(), callbackFn);
  };

  return { state, showDialog, hideDialog, watchEffect, setUpdate };
};

export const DialogContextProvider = ({ children }: { children: ChildrenType }): JSX.Element => {
  const [state, setState] = useState(() => ({ isHidden: true, isUpdated: false }));

  const hideDialog = () => {
    setState((prev) => ({ ...prev, isHidden: true }));
  };

  const showDialog = () => {
    setState((prev) => ({ ...prev, isHidden: false }));
  };
  /**
   * DISPATCHER used to detects when the user has filled some fields in the form
   * @param value
   */
  const setUpdate = (value: boolean) => {
    setState((prev) => ({ ...prev, isUpdated: value }));
  };

  return (
    <DialogCtxtProvider value={{ state, setUpdate, hideDialog, showDialog, watchEffect: () => undefined }}>
      {children}
    </DialogCtxtProvider>
  );
};
