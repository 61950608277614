import React from 'react';
import globalConstants from 'src/constants/globalConstants';
import { useConfirmationNotificationContext } from '../../../../context/ConfirmationNotificationContext';
import BannerNotification from '../banner-notification/BannerNotification';
import { getSourceId } from '../../../../utility/Utilities';

interface ConfirmationNotificationProps {
  onCloseCallback: () => void;
}

/**
 * This is just a wrapper which displays the current confirmation in the Content
 * you could use addConfirmationNotification function to display any confirmation message using from useConfirmationNotification
 */
const ConfirmationNotification = ({ onCloseCallback }: ConfirmationNotificationProps): JSX.Element => {
  const { confirmation, removeConfirmationNotification } = useConfirmationNotificationContext();
  const { message = '' } = confirmation || {};
  const source = getSourceId();

  return (
    <div
      className={`confirmation-notification ${confirmation ? '' : 'hide-banner'} ${
        source === globalConstants.VCI_PORTAL_APP_SOURCE ? 'header-size' : ''
      }`}
    >
      <BannerNotification
        horizontalSpacing={false}
        onClose={() => {
          // after the notification is closed removing the confirmation object in context
          onCloseCallback();
          removeConfirmationNotification();
        }}
        isOpen={!!confirmation}
        closeIcon
        notificationStyle='success'
      >
        {message}
      </BannerNotification>
    </div>
  );
};

export default ConfirmationNotification;
