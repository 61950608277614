import React from 'react';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';

const MessageDeletionNotice = (): JSX.Element => {
  return (
    <div className='message-deletion-container'>
      <hr />
      <p className='deletion-notice'>{i18n.t(rk.MESSAGE_DELETION_NOTICE)}</p>
    </div>
  );
};

export default MessageDeletionNotice;
