/**
 * Exchange a token to a long lived B2B token
 *
 * @param url the endpoint for the initialize
 * @param token the original token
 * @param accountNumber the account number to use to initialize the token
 * @returns {Promise<{data: T, status: number}|{data: *, status: number}>}
 */
const initializeToken = async function initializeToken(
  url: string | null,
  token: string,
  accountNumber: string
): Promise<{ data: Record<string, unknown>; status: number }> {
  if (!token) {
    throw new Error('initializeToken: missing token');
  }
  try {
    const targetUrl = url || `${process.env.REACT_APP_TOKEN_MGMT_API_AWS_HOST}/initializetoken`;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        userToken: 'true',
        accountNumber,
      },
      body: null,
    };
    const response = await fetch(targetUrl, options);

    if (!response.ok) {
      console.error(`Error calling initializeToken=${response.statusText}`);
      throw Error(response.statusText);
    } else {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
  } catch (e) {
    console.error(`Error calling initializeToken=${JSON.stringify(e)}`);
    throw e;
  }
};
export default initializeToken;
