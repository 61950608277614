import React, { useEffect, useContext } from 'react';
import { Routes as Switch, Route, useLocation, Navigate } from 'react-router-dom';
import { changeRoute, getAccountType } from 'src/utility/Utilities';
import { DialogContextProvider } from 'src/context/DialogContext';
import { AccountContext } from 'src/context/AccountContext';
import { IAccountContext } from 'src/context/AccountContext.interface';
import { get, set } from 'src/services/session-service';
import { DOCUMENT_PATH, FAQ_PATH, INBOX_PATH } from 'src/constants/routePaths';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';
import './MessageCenter.scss';
import useViewport from '../../hooks/ViewPort';
import { mobileScreenWidth } from '../../constants/globalConstants';
import SideBar from '../components/common/sidebar-nav/AsideBar';
import TopBar from '../components/common/topbar-nav/TopBar';
import MailBox from './mailbox/MailBox';
import { Error } from '../components/common/errors/Error';
import NewMessage from './new-message/NewMessage';
import FAQ from './faq/FAQ';
import DocumentCenter from './document-center/DocumentCenter';
import { AccountType, Brand, Country, UIBrand } from '../../types';

const HomeComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAppProps = (updateKey: string, updateValue: any) => {
    const storedProps = localStorage.getItem('appProps');
    const storedPropsObj = storedProps ? JSON.parse(storedProps) : {};
    storedPropsObj[updateKey] = updateValue;
    localStorage.setItem('appProps', JSON.stringify(storedPropsObj));
  };

  const dispatchSubmitAction = (ev: React.FormEvent<HTMLFormElement>) => {
    const accountNumber = ev.currentTarget.accountNumber.value;
    const partyId = ev.currentTarget.partyId.value;
    const token = ev.currentTarget.token.value;
    const country = ev.currentTarget.country.value;
    const isvalidated = [accountNumber, partyId, token].reduce((prev, current) => prev && !!current, true);
    const appProps = JSON.parse(get('appProps'));
    const externalId = accountNumber;
    const sourceId = 'vci-owner-portal';

    if (appProps.country !== country) {
      appProps.country = country;
    }

    if (appProps.country === 'Canada') {
      appProps.languageCode = 'en-CA';
    }

    if (!appProps.sourceId) {
      appProps.source = sourceId;
    }

    if (!isvalidated) {
      ev.preventDefault();
    }
    localStorage.setItem(
      'authProps',
      JSON.stringify({ accountNumber, partyId, token, initializedToken: true, country, externalId })
    );
    localStorage.setItem('appProps', JSON.stringify(appProps));
  };

  return (
    <>
      <Error />
      <header className='message-center-site message-center-header'>
        <h1>{i18n.t(rk.MAIN_PAGE_TITLE)}</h1>
      </header>
      <form onSubmit={dispatchSubmitAction}>
        <label htmlFor='accountNumber'>
          accountNumber
          <input type='number' name='accountNumber' placeholder='Type account Number' />
        </label>
        <br />
        <label htmlFor='partyId'>
          partyId
          <input type='number' name='partyId' placeholder='Type partyId' />
        </label>
        <br />
        <label htmlFor='token'>
          token
          <input type='text' name='token' placeholder='Type token' />
        </label>
        <br />
        <br />
        <p>country:</p>
        <label htmlFor='USA'>
          USA
          <input type='radio' id='USA' name='country' value='USA' defaultChecked />
        </label>
        <br />
        <label htmlFor='Canada'>
          Canada
          <input type='radio' id='Canada' name='country' value='Canada' />
        </label>
        <br />

        <button type='submit'>submit</button>
      </form>
      <br />
      <div className='brands-btn-container' role='main'>
        <button
          className='c-btn'
          type='button'
          data-testid='audi-theme-btn'
          onClick={() => {
            updateAppProps('brand', UIBrand.AUDI);
            window.location.reload();
          }}
        >
          <span className='c-btn__text'>Audi</span>
        </button>
        <button
          type='button'
          className='c-btn'
          disabled
          onClick={() => {
            updateAppProps('brand', UIBrand.VW);
            window.location.reload();
          }}
        >
          <span className='c-btn__text'>VW</span>
        </button>
        <button
          type='button'
          className='c-btn c-btn--secondary'
          data-testid='vw6-theme-btn'
          onClick={() => {
            updateAppProps('brand', UIBrand.VW6);
            window.location.reload();
          }}
        >
          <span className='c-btn__text'>VW6</span>
        </button>
      </div>
    </>
  );
};

type RouteProps = {
  country: Country;
  brand: Brand;
  accountType: AccountType;
  mobile: boolean;
};

const InboxRouter = ({ mobile, brand }: { brand: Brand; mobile: boolean }) => {
  return (
    <Switch>
      <Route path='/' element={<MailBox brand={brand} mobile={mobile} />}>
        <Route path=':messageId' element={<MailBox brand={brand} mobile={mobile} />} />
      </Route>
      <Route path='new-message' element={<NewMessage mobile={mobile} />} />
      <Route path='*' element={<h1>Message Center - Inbox | Route Not Found</h1>} />
    </Switch>
  );
};

/**
 * Routes
 * - Root path - Display Login Test harness or links to `inbox` in case REACT_APP_LOGIN_TEST_HARNESS is false
 * - FAQs
 * - inbox
 * - `*` it means the route does not exist e.g (/message-center/message-center, etc.)
 *
 * @param param0
 * @returns
 */
const Routes = ({ country, brand, accountType, mobile }: RouteProps) => {
  return (
    <Switch>
      <Route
        path='/'
        element={process.env.REACT_APP_LOGIN_TEST_HARNESS === 'true' ? <HomeComponent /> : <Navigate to={INBOX_PATH} />}
      />
      <Route
        path={FAQ_PATH}
        element={<FAQ country={country} brand={brand} accountType={accountType} mobile={mobile} />}
      />
      <Route path={DOCUMENT_PATH} element={<DocumentCenter brand={brand} />} />

      <Route path='inbox/*' element={<InboxRouter mobile={mobile} brand={brand} />} />
      <Route path='*' element={<h1>Message Center | Route Not Found</h1>} />
    </Switch>
  );
};

const MessageCenter = (): JSX.Element => {
  const storedProps = localStorage.getItem('appProps');
  const storedPropsObj = storedProps ? JSON.parse(storedProps) : {};
  const { width } = useViewport();
  const location = useLocation();
  const brand = storedPropsObj?.brand;
  const country = storedPropsObj?.country;
  const storedAccountType = getAccountType();
  let accountType = storedAccountType ? (storedAccountType as AccountType) : AccountType.Default;
  const accountContext = useContext(AccountContext) as IAccountContext;
  const accounts = accountContext?.accounts;
  if (!storedAccountType) {
    accountType = accounts?.Finance?.DetailedAccountType as AccountType;
  }

  useEffect(() => {
    // if `state.root` exists, that means the you was redirected to this app
    // from a different app(dashboard, portal), you need to set the root value
    // because `root` is the pathname where you came and is needed if you want to get back.
    const appProps = JSON.parse(get('appProps') ?? '{}');
    const rootPath: string | undefined = location.state?.root ?? appProps.rootPath;

    if (rootPath) {
      set('appProps', JSON.stringify({ ...appProps, rootPath }));
      window.rootPath = rootPath;
    }

    changeRoute(location);
  }, [location]);

  const desktopUI = () => {
    return (
      <div className='desktop-ui'>
        <div className='desktop-ui__sidebar'>
          <SideBar brand={brand} country={country} />
        </div>
        <div className='desktop-ui__content'>
          <Routes country={country} brand={brand} accountType={accountType} mobile={false} />
        </div>
      </div>
    );
  };

  const mobileUI = () => {
    return (
      <div className='mobile-ui'>
        <div className='mobile-ui__topbar'>
          <TopBar brand={brand} country={country} />
        </div>
        <div className='mobile-ui__content c-tabs__content-wrapper'>
          <Routes country={country} brand={brand} accountType={accountType} mobile />
        </div>
      </div>
    );
  };

  return (
    <div data-testid={`message-center-${brand}`} className='mesage-center-app-inner-container'>
      <DialogContextProvider>{width <= mobileScreenWidth ? mobileUI() : desktopUI()}</DialogContextProvider>
    </div>
  );
};

export default MessageCenter;
