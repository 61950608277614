/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import { IDocumentContext } from 'src/context/DocCenterContext.interface';
import { DocCenterContext } from 'src/context/DocCenterContext';
import { NotificationProvider } from 'src/context/NotificationContext';
import DocumentsTable from 'src/views/components/table/DocumentsTable';
import { DocCenterAccountContext } from 'src/context/DocCenterAccountContext';
import { IDocCenterAccountContext } from 'src/context/DocCenterAccountContext.interface';
import { getSourceId, generateSiteLoadEvent } from 'src/utility/Utilities';
import { AccountContext } from 'src/context/AccountContext';
import { IAccountContext } from 'src/context/AccountContext.interface';
import { useMessageContext } from 'src/context/MessageContext';
import { ISubjectMappingResponse } from 'src/context/MessageContext.interface';
import Filters from '../../components/document-center/Filters';
import './DocumentCenter.scss';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import { Brand } from '../../../types';
import globalConstants from '../../../constants/globalConstants';

const DocumentCenter: React.FC<{ brand: Brand }> = (props) => {
  const { brand } = props;
  // Parent encompassing children
  const { error, getDocumentsList } = useContext(DocCenterContext) as IDocumentContext;
  const { getDocumentEligibility } = useContext(DocCenterAccountContext) as IDocCenterAccountContext;
  const isMyAudiApp =
    getSourceId() === globalConstants.MY_AUDI_MOBILE_SOURCE &&
    process.env.REACT_APP_HIDE_DOCUMENT_CENTER_MYAUDIAPP === 'true';

  const fetchDocuments = async () => {
    await getDocumentEligibility();
    await getDocumentsList();
  };

  const accountContext = useContext(AccountContext) as IAccountContext;
  const accounts = accountContext?.accounts;
  const { messages, subjectMappings, newmessageCount } = useMessageContext();

  useEffect(() => {
    console.info('User visited Document Center landing page');
    if (!isMyAudiApp) fetchDocuments();
  }, []);

  useEffect(() => {
    if (
      accounts &&
      accounts.AccountId !== '' &&
      messages.messages.length >= 0 &&
      process.env.REACT_APP_ADOBE_ANALYTICS_FLAG === 'true'
    ) {
      generateSiteLoadEvent(accounts, subjectMappings as ISubjectMappingResponse, messages, newmessageCount as number);
    }
  }, [accounts, messages.messages]);

  return (
    <>
      <NotificationProvider>
        <div className={`o-layout u-pv u-ph ${brand?.toLowerCase()}`}>
          <div className='o-layout__item u-1/1'>
            <h3 className='header__title'>{i18n.t(rk.DOCUMENT_CENTER_TITLE)}</h3>
          </div>
          {isMyAudiApp ? (
            <div className='o-layout__item u-1/1 u-mt-none'>
              <div className='c-notification__container'>
                <div className='c-notification  c-notification--info'>
                  <div className='c-notification__outer-wrap'>
                    <div className='c-notification__content'>
                      <p className='c-notification__text'>
                        <b>{i18n.t(rk.DOCUMENT_CENTER_MYAUDI_CONTENT_ONE)}</b>
                        {i18n.t(rk.DOCUMENT_CENTER_MYAUDI_CONTENT_TWO)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : !error ? (
            <>
              <Filters />
              <DocumentsTable />
            </>
          ) : (
            <div className='o-layout__item u-1/1 u-mt-none'>
              <div className='c-notification__container js-notification-container'>
                <div className='c-notification  c-notification--error'>
                  <div className='c-notification__outer-wrap'>
                    <div className='c-notification__inner-wrap'>
                      <i className='c-notification__status-icon' aria-hidden='true' role='img' />
                      <div className='c-notification__content'>
                        <h3 className='c-notification__title'>{i18n.t(rk.DOCUMENT_CENTER_ERROR_TITLE)}</h3>
                        <p className='c-notification__text'>{i18n.t(rk.DOCUMENT_CENTER_ERROR_CONTENT)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </NotificationProvider>
    </>
  );
};

export default DocumentCenter;
