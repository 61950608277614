import { IMessageAttachments } from '../../../context/MessageContext.interface';
// When is the first time you create a message, it will come with thread type as 0
//   - You need to wait to get a response from  CSR (customer support representative)
// When CSR answer you, the message will come with thread type as 2
//   - Now you can reply <Just once>
// Whether you replied, this message will be saved with thread type as 1

export enum ThreadType {
  ORIGINAL = 0,
  REPLY = 1,
  CSR = 2,
  DOCUMENT = 771652,
  WELCOME_MESSAGE = 464779,
}

export interface IListItem {
  label: string;
  timestamp: string;
  unread?: boolean;
  title: string;
  description: string;
  active?: boolean;
  onClick: (payload: IListItem) => void;
  hasAttachment: boolean;
  brand: string;
  type: ThreadType;
}

export interface UIMessage {
  id: string | number;
  threadId: string | number;
  type: ThreadType;
  timestamp: string;
  unread?: boolean;
  title: string;
  description: string;
  active?: boolean;
  repliesList?: UIMessage[];
  attachments: IMessageAttachments[];
  hasAttachment?: boolean;
  source: string;
}
