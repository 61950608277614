import { IAuthState } from 'src/context/AuthContext';
import { getIViewSource } from 'src/utility/Utilities';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const SEND_MESSAGE_ENDPOINT = '/postmessage';

const sendMessage = (params: FormData, auth: IAuthState): Promise<void> => {
  return apiClient
    .post(SEND_MESSAGE_ENDPOINT, params, {
      headers: {
        ...buildHeaderParams(auth),
        'Content-Type': 'multipart/form-data',
        idp: 'messagecenter',
        sub: auth.externalId ? auth.externalId : 'messagecenter',
        iviewsource: getIViewSource(auth),
      },
    })
    .then((response) => {
      console.debug('Success calling sendMessage');
      return response.data;
    });
};

export default sendMessage;
