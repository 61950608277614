/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { Link } from 'react-router-dom';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import { AccountType, Brand, Country } from '../../../types';

export interface FAQComponentProps {
  brand: Brand;
  accountType: AccountType;
}

export interface FAQComponentChildProps {
  value: string;
  children: React.ReactNode;
}

export interface FAQComponentItemChildProps {
  value: string;
  // eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
  bold?: boolean;
}

export interface FAQComponentListProps {
  items: string[];
}

export interface FAQComponentNumberedListProps {
  type: NumberedListType;
  items: FAQComponentChildProps[];
}

export interface FAQComponentChildListProps {
  items: React.ReactNode[];
}
export const LinkType = {
  Nav: 'Nav',
  NavLink: 'NavLink',
  Link: 'Link',
  Pdf: 'Pdf',
  Email: 'Email',
  NewPage: 'NewPage',
} as const;

export enum NumberedListType {
  Number = 'Number',
  Letter = 'Letter',
}

export type FAQComponentItemLink =
  | {
      type: keyof typeof LinkType;
      key: string;
      value?: string;
    }
  | {
      type: keyof typeof LinkType.NavLink;
      key: string;
      value: string;
    };

export interface FAQComponentWithLinksProps {
  value: string;
  links: FAQComponentItemLink[];
}

const formattedText = (value: string, links: FAQComponentItemLink[]) => {
  let formattedParagraph = reactStringReplace(value);
  links.forEach((element) => {
    if (element.type === LinkType.NavLink) {
      const path = element.value as string;
      const aLabel = `${element.key}`;
      formattedParagraph = reactStringReplace(formattedParagraph, `<${element.key}>`, () => (
        <Link data-testid={`${element.key} back-link`} key={element.key} className='u-base-link' to={path}>
          {aLabel}
        </Link>
      ));
    } else if (element.type === LinkType.Nav) {
      const aLabel = `${element.key}`;
      // window rootPath is set in dashboard app, when browser refreshes it is got from localstorage
      // see more in message-center component
      const path = window?.rootPath ?? '..';
      // usually this *element* doesn't have value so I need to remove it
      // note: Fix types because NavLink must a have value and Nav doesn't
      formattedParagraph = reactStringReplace(formattedParagraph, `<${element.key}>`, () => (
        <Link data-testid={`${element.key} back-link`} key={element.key} className='u-base-link' to={path}>
          {aLabel}
        </Link>
      ));
    } else if (element.type === LinkType.Email) {
      formattedParagraph = reactStringReplace(formattedParagraph, `<${element.key}>`, () => (
        <a href={`mailto:${element.value}`} rel='noopener noreferrer' key={element.key}>
          {element.key}
        </a>
      ));
    } else {
      formattedParagraph = reactStringReplace(formattedParagraph, `<${element.key}>`, () => (
        <a
          href={element.value}
          rel='noopener noreferrer'
          target={element.type === LinkType.NewPage ? '_blank' : '_self'}
          key={element.key}
        >
          {element.key}
        </a>
      ));
    }
  });
  return formattedParagraph;
};

export const FAQComponentSection: React.FC<FAQComponentChildProps> = ({ value, children }: FAQComponentChildProps) => {
  return (
    <div id={value.split(' ')[0]}>
      <h2>{value}</h2>
      <Accordion>{children}</Accordion>
    </div>
  );
};

export const FAQComponentItem: React.FC<FAQComponentChildProps> = ({ value, children }: FAQComponentChildProps) => {
  return <Accordion.Item title={value}>{children}</Accordion.Item>;
};

export const FAQComponentItemParagraph: React.FC<FAQComponentItemChildProps> = ({
  value,
  bold,
}: FAQComponentItemChildProps) => {
  if (bold) {
    return (
      <p>
        <b>{value}</b>
      </p>
    );
  }

  return <p>{value}</p>;
};

export const FAQComponentItemSubTitle: React.FC<FAQComponentItemChildProps> = ({
  value,
}: FAQComponentItemChildProps) => {
  return <h3>{value}</h3>;
};

export const FAQComponentItemParagraphWithTitle: React.FC<FAQComponentChildProps> = ({
  value,
  children,
}: FAQComponentChildProps) => {
  return (
    <p>
      <strong>{value}</strong>
      {children}
    </p>
  );
};

export const FAQComponentItemImage: React.FC<FAQComponentItemChildProps> = ({ value }: FAQComponentItemChildProps) => {
  return (
    <div className='img-container'>
      <img src={value} alt='' />
    </div>
  );
};

export const FAQComponentItemList: React.FC<FAQComponentListProps> = ({ items }: FAQComponentListProps) => {
  return (
    <ul>
      {items.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};

export const FAQComponentItemNumberedList: React.FC<FAQComponentNumberedListProps> = ({
  type,
  items,
}: FAQComponentNumberedListProps) => {
  return (
    <div>
      {items.map((item, index) => {
        const itemId = item.value || item.children?.toString() || index;
        return (
          <p key={itemId}>
            <span className='numbered-list-item'>
              {`${type === NumberedListType.Number ? index + 1 : String.fromCharCode(index + 97)}.     `}
            </span>
            <span className='numbered-list-item'>
              {item.value && <b>{item.value}</b>} {item.children}
            </span>
          </p>
        );
      })}
    </div>
  );
};

export const FAQComponentItemChildList: React.FC<FAQComponentChildListProps> = ({
  items,
}: FAQComponentChildListProps) => {
  return (
    <ul>
      {items.map((item, index) => (
        /* eslint-disable react/no-array-index-key */
        // NOSONAR
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};
export const FAQComponentItemParagraphWithLinks: React.FC<FAQComponentWithLinksProps> = ({
  value,
  links,
}: FAQComponentWithLinksProps) => {
  const formattedParagraph = formattedText(value, links);
  return <p>{formattedParagraph}</p>;
};

export const FAQComponentItemTextWithLinks: React.FC<FAQComponentWithLinksProps> = ({
  value,
  links,
}: FAQComponentWithLinksProps) => {
  const formattedParagraph = formattedText(value, links);
  return <span>{formattedParagraph}</span>;
};

export const resourceKey = (key: string, brand: Brand, country: Country = Country.USA) => {
  return i18n.t(
    // @ts-ignore
    rk[`${key}_${brand.toUpperCase()}${country === Country.Canada ? `_${Country.Canada.toUpperCase()}` : ''}`]
  );
};
