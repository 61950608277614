import { IAuthState } from 'src/context/AuthContext';
import { ISubjectMappingResponse } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const SUBJECT_MAPPING_ENDPOINT = '/subjectmapping';
/**
 * Get subject mappings for messages & documents.
 * @returns
 * @param auth
 */
export const getSubjectMappings = (auth: IAuthState): Promise<ISubjectMappingResponse> => {
  return apiClient
    .get(SUBJECT_MAPPING_ENDPOINT, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getSubjectMappings');
      return response.data;
    });
};

export default getSubjectMappings;
