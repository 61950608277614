import React, { MouseEventHandler } from 'react';

interface ModalProps {
  modalHidden: boolean;
  modalClosed: MouseEventHandler<HTMLButtonElement>;
  modalExit: MouseEventHandler<HTMLButtonElement>;
  modalIcon: string;
  title: string;
  content: string;
  cancelButtonText: string;
  okButtonText: string;
}

const Modal: React.FC<ModalProps> = ({
  modalHidden,
  modalClosed,
  modalExit,
  modalIcon,
  title,
  content,
  cancelButtonText,
  okButtonText,
}: ModalProps) => {
  return (
    <div data-testid='confirmation-modal' className='c-modal c-modal--info' aria-hidden={modalHidden} id='modal'>
      <div className='c-modal__overlay' tabIndex={-1} />

      <div className='confirmation-modal c-modal__wrapper' role='dialog' aria-labelledby='modal-title'>
        <div className='c-modal__header'>
          <div className='c-modal__title__wrapper'>
            <i className={`c-icon ${modalIcon}`} />
            <h2 className='c-modal__title' id='modal-title'>
              {title}
            </h2>
          </div>
        </div>

        <div className='c-modal__content' role='document'>
          <p>{content}</p>
        </div>

        <div className='c-modal__footer'>
          <div className='c-modal__actions'>
            <div className='c-modal__actions__item cancel-btn-container'>
              <button
                type='button'
                className='c-btn  c-btn--secondary'
                onClick={modalClosed}
                aria-label='Close this dialog window.'
              >
                <span className='cancel c-btn__text'>{cancelButtonText}</span>
              </button>
            </div>
            <div className='c-modal__actions__item send-btn-container'>
              <button
                type='button'
                className='c-btn js-modal-autofocus'
                data-testid='modal-confirmation-button'
                onClick={modalExit}
                aria-label='Yes cancel'
              >
                <span className='send c-btn__text'>{okButtonText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
