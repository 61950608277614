import { IAuthState } from 'src/context/AuthContext';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const MESSAGE_STATUS_ENDPOINT = '/messagestatus';
// using uppercase convention to identify when you are using a http service.
/**
 * Update message status from `delivered` to `seen`(read)
 * @param messageId
 * @param isRead
 * @param isDocument
 * @param auth
 * @returns
 */

const updateMessageStatus = (
  messageId: string | number,
  isRead: boolean,
  isDocument: boolean,
  auth: IAuthState
): Promise<void> => {
  return apiClient
    .put(
      `${MESSAGE_STATUS_ENDPOINT}/${messageId}`,
      { isread: isRead, isDocument },
      {
        headers: buildHeaderParams(auth),
      }
    )
    .then((response) => {
      console.debug('Success calling updateMessageStatus');
      return response.data;
    });
};

export default updateMessageStatus;
