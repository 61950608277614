import { IAuthState } from 'src/context/AuthContext';
import { NewmessageCountResponse } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const NEWMESSAGE_COUNT_ENDPOINT = '/newmessagecount';

const getNewMessageCount = (auth: IAuthState): Promise<NewmessageCountResponse> => {
  return apiClient
    .get(NEWMESSAGE_COUNT_ENDPOINT, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getNewMessageCount');
      return response?.data;
    });
};

export default getNewMessageCount;
