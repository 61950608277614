const AudiConstants = {
  trackerBackgroundColor: '#d5d6d6',
  trackerGradientStartColor: '#000',
  trackerGradientEndColor: '#000',
  defaultImageUrl: 'https://cdn.bronson.vwfs.tools/audi/v/7.11.0/img/logo.svg',
  SSOLinkUs: `${process.env.REACT_APP_CI_SSO_LINK_HOST_AUDI_US}/authentication/autologin`,
  SSOLinkCa: `${process.env.REACT_APP_CI_SSO_LINK_HOST_AUDI_CA}/authentication/autologin`,
  messageCenterLinkUs: `${process.env.REACT_APP_ACCOUNT_AUTHENTICATION_HOST_AUDI_US}/CustomerSupport/MessageCenter`,
  messageCenterLinkCa: `${process.env.REACT_APP_ACCOUNT_AUTHENTICATION_HOST_AUDI_CA}/CustomerSupport/MessageCenter`,
  tncLinkAutopay: `${process.env.REACT_APP_TNC_HOST_AUDI_US}/audi/autopay`,
  tncLinkACH: `${process.env.REACT_APP_TNC_HOST_AUDI_US}/audi/ach`,
  accountTypeLease: 'Lease',
  accountTypeLoan: 'Loan',
  accountTypePremier: 'Premier Purchase',
  audiTelephone: '+18882372834',
  paymentSource: 'myAudi',
  inActiveAccountShowIcon: false,
};

export default AudiConstants;
