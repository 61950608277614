import { useState, useEffect } from 'react';

interface ViewPort {
  width: number;
}

const useViewport = (): ViewPort => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return the width so we can use it in our dashboard.jsx component
  return { width };
};

export default useViewport;
