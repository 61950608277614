import React, { ReactElement, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import globalConstants, { mediumScreenWidth, mobileScreenWidth } from '../../../../constants/globalConstants';
import useViewport from '../../../../hooks/ViewPort';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';
import delay from '../../../../utility/delay';
import Switch from '../Switch';
import UploadFileInput from '../upload-file-input/UploadFileInput';
import attachmentIcon from '../../../../assets/icons/attachment.svg';
import backIcon from '../../../../assets/icons/back-button.svg';
import closeIcon from '../../../../assets/icons/close-button.svg';
import closeWhiteIcon from '../../../../assets/icons/close-button-white.svg';

function FailedCloseBrandIcon({
  onClick,
  brand = '',
  width,
}: {
  onClick: () => void;
  brand: string;
  width: number;
}): ReactElement {
  let iconClassName = '';
  let image;
  if (brand === globalConstants.AUDI_BRAND) {
    iconClassName = 'system-cancel';
  } else if (brand === globalConstants.VW_BRAND && width < mobileScreenWidth) {
    image = <img src={closeWhiteIcon} alt='cancel icon' />;
  } else {
    image = <img src={backIcon} alt='cancel icon' />;
  }
  return (
    <button
      data-testid='close-brand-button'
      type='button'
      aria-label='cancel button'
      className={`c-icon  c-icon--[${iconClassName}] u-fr`}
      onClick={onClick}
    >
      {iconClassName ? null : image}
    </button>
  );
}

function SuccessCloseBrandIcon({ onClick, brand = '' }: { onClick: () => void; brand: string }): ReactElement {
  const iconClassName = brand === globalConstants.AUDI_BRAND ? 'system-cancel' : '';
  return (
    <button
      aria-label='remove attachment'
      type='button'
      className={`c-upload-item__interaction-icon c-icon c-icon--[${iconClassName}] c-icon--small`}
      onClick={onClick}
    >
      {iconClassName ? null : <img src={closeIcon} alt='cancel icon' />}
    </button>
  );
}

const getFileSize = (size: number) => {
  const fileSize = Math.round(size / 1024);
  return fileSize;
};

export interface IFile extends File {
  id: string;
}

const isEncodedFile = (file: File): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileResult = (reader.result ?? '') as string;
      resolve(fileResult.indexOf('/Encrypt') !== -1);
    };

    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsText(file);
  });

const sizeAcceptation = (file: File): Promise<boolean> =>
  new Promise((resolve) => {
    const mb = file.size / (1000 * 1000);
    resolve(mb < 10);
  });

const createFileAcceptation =
  (exts: Array<string>) =>
  (file: File): Promise<boolean> =>
    new Promise((resolve) => {
      // replace all content and just stay with extension
      const ext = file.name.replace(/.+\./, '');

      resolve(exts.includes(ext.toLowerCase()));
    });

const mapFile = (file: File): IFile => {
  return {
    ...file,
    id: uuid(),
    size: file.size,
    type: file.type,
    name: file.name,
  };
};

interface UploadProps {
  id: string;
  onChange: (blobFiles: File[], mappedFiles: IFile[]) => void;
  // eslint-disable-next-line react/require-default-props
  onDelete?: (mappedFile: IFile) => void;
  brand: string;
  malwareDetected: boolean;
  setMalwareDetected: Dispatch<SetStateAction<boolean>>;
}

export const Upload = ({
  id,
  onChange,
  onDelete = () => null,
  brand,
  malwareDetected,
  setMalwareDetected,
}: UploadProps): React.ReactElement => {
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [files, setFiles] = useState<IFile[]>([]);
  const [status, setStatus] = useState<'success' | 'failed' | 'loading'>(malwareDetected ? 'failed' : 'success');
  const [notMalware, setNotMalware] = useState(false);
  const { width } = useViewport();
  useEffect(() => {
    if (malwareDetected) {
      setStatus('failed');
    } else {
      setStatus('success');
    }
  }, [malwareDetected]);
  const onChangeFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setStatus('loading');

    const rawFiles = (ev.target.files ?? []) as File[];

    // this happens sometimes when you press cancel button
    if (!rawFiles.length) return setStatus('success');

    // if the file received does not have any ext, the promise will be resolve a error.
    const fileAcceptation = createFileAcceptation(['jpg', 'jpeg', 'pdf', 'png', 'bmp', 'tif', 'tiff']);

    const checkTotalSize = (file: File): Promise<boolean> =>
      new Promise((resolve) => {
        const currentFileSize = getFileSize(file.size);
        const totalSize = totalFilesSize + currentFileSize;
        resolve(totalSize < 10000);
      });

    return (
      Promise.resolve()
        // the below promise will verify if the file has ext allowed
        .then(() => fileAcceptation(rawFiles[0]))
        .then((extAccepted) => {
          if (!extAccepted) {
            throw new Error(extAccepted.toString());
          }
          return extAccepted;
        })
        // the below promise will verify if the file has size allowed
        .then(() => sizeAcceptation(rawFiles[0]))
        .then((isAccepted) => {
          if (!isAccepted) throw new Error(isAccepted.toString());
          return isAccepted;
        })
        .then(() => checkTotalSize(rawFiles[0]))
        .then((totalSizeAccepted) => {
          if (!totalSizeAccepted) throw new Error();
          return totalSizeAccepted;
        })
        // the below promise will verify if the file is not a protected file.
        .then(() => isEncodedFile(rawFiles[0]))
        .then((isEncoded) => {
          if (isEncoded) throw new Error(isEncoded.toString());
          const mappedFiles = Array.from(rawFiles).map(mapFile);
          onChange(rawFiles, mappedFiles);
          return mappedFiles;
        })

        //  if the constraints are passed, you will concat old files with the new one.
        .then((_files) => {
          setFiles((prevFiles) => [...prevFiles, ..._files]);
          setTotalFilesSize(totalFilesSize + getFileSize(rawFiles[0].size));
        })

        .then(() => {
          setStatus('success');
          setMalwareDetected(false);
          setNotMalware(false);
        })
        .catch(() => {
          setNotMalware(true);
          setStatus('failed');
        })
        // the below process will reset the input value, in order to it does not affect
        // the following uploads.
        .then(() => {
          const inputEl: HTMLInputElement | null = document.querySelector('.c-upload__input');
          if (inputEl) {
            inputEl.value = '';
          }
        })
    );
  };

  const onDeleteFile = (file: IFile) => {
    setTotalFilesSize(totalFilesSize - getFileSize(file.size));
    setFiles((prevFiles) => prevFiles.filter((fil) => fil.id !== file.id));
    onDelete?.(file);
  };
  const failedAlertIcon =
    brand === globalConstants.AUDI_BRAND
      ? `c-icon  c-icon--[caution]  c-icon--small`
      : `c-icon  c-icon--[alert]  c-icon--medium`;
  return (
    <div className='file-upload-container'>
      <div className='c-upload-container__field'>
        <Switch defaultChild='' value={status}>
          <Switch.Item case='success'>
            <UploadFileInput id={id} onChange={onChangeFile}>
              {width > mediumScreenWidth ? (
                <>
                  <UploadFileInput.Title>{i18n.t(rk.UPLOAD_FILE_DRAG_AND_DROP_TEXT)}</UploadFileInput.Title>
                  <UploadFileInput.Icon hidden />
                  <UploadFileInput.Description>{i18n.t(rk.UPLOAD_FILE_OR_TEXT)}</UploadFileInput.Description>
                  <UploadFileInput.Button>
                    <span role='button' className='c-btn margin-auto'>
                      <span className='c-btn__text'>{i18n.t(rk.UPLOAD_FILE_ADD_ATTACHMENTS_TEXT)}</span>
                    </span>
                  </UploadFileInput.Button>
                  {/* <UploadFileInput.Tooltip>
                    <p className='u-m-none'>
                      {i18n.t(rk.UPLOAD_FILE_ALLOWED_FILE_TYPES_TEXT)}
                      <TooltipIcon content={i18n.t(rk.UPLOAD_TOOLTIP_CONTENT)} data-langid='UPLOAD_TOOLTIP_CONTENT'>
                        <i className='c-icon  c-icon--[semantic-info]  c-icon--small u-ml-xsmall' />
                      </TooltipIcon>
                    </p>
                  </UploadFileInput.Tooltip> */}
                </>
              ) : (
                <>
                  {/* <UploadFileInput.Icon hidden />
                  <UploadFileInput.Description>
                    <p className='u-m-none'>
                      {i18n.t(rk.UPLOAD_FILE_ADD_ATTACHMENTS_TEXT)}
                      <TooltipIcon content={i18n.t(rk.UPLOAD_TOOLTIP_CONTENT)} data-langid='UPLOAD_TOOLTIP_CONTENT'>
                        <i className='c-icon  c-icon--[semantic-info]  c-icon--small u-ml-xsmall' />
                      </TooltipIcon>
                    </p>
                  </UploadFileInput.Description> */}
                </>
              )}
            </UploadFileInput>
          </Switch.Item>
          <Switch.Item case='failed'>
            <UploadFileInput id={id} disabled onChange={undefined}>
              <UploadFileInput.Icon>
                <FailedCloseBrandIcon
                  width={width}
                  brand={brand}
                  onClick={() => delay(300).then(() => setStatus('success'))}
                />
              </UploadFileInput.Icon>
              <UploadFileInput.Title>
                <p className='u-text-alert failed-upload-title' data-langid='UPLOAD_FAILED_MESSAGE'>
                  <i className={failedAlertIcon} /> {i18n.t(rk.UPLOAD_FAILED_MESSAGE)}
                </p>
              </UploadFileInput.Title>
              <UploadFileInput.Description
                status={status}
                data-testid='failed-description'
                data-langid={
                  malwareDetected && !notMalware ? 'UPLOAD_FAILED_TOOLTIP_CONTENT' : 'UPLOAD_TOOLTIP_CONTENT'
                }
              >
                {malwareDetected && !notMalware
                  ? i18n.t(rk.UPLOAD_FAILED_TOOLTIP_CONTENT)
                  : i18n.t(rk.UPLOAD_TOOLTIP_CONTENT)}
              </UploadFileInput.Description>
            </UploadFileInput>
          </Switch.Item>
          <Switch.Item case='loading'>
            <UploadFileInput id={id} disabled onChange={undefined} loading />
          </Switch.Item>
        </Switch>
      </div>
      <ul className='c-upload-container__itemlist'>
        {files.map((file) => (
          <li className='c-upload-container__item' key={file.id}>
            <div className='c-upload-item'>
              {brand === globalConstants.AUDI_BRAND ? (
                <i className='c-upload-item__icon  c-icon  c-icon--[semantic-file]' aria-hidden='true' role='img' />
              ) : (
                <img className='c-upload-item__icon' src={attachmentIcon} alt='attachment icon' />
              )}
              <div className='c-upload-item__content'>
                <div className='c-upload-item__title-row'>
                  <p className='c-upload-item__title'>{`${file.name} (${getFileSize(file.size)}KB)`}</p>
                  <div className='c-upload-item__interaction-icons'>
                    <SuccessCloseBrandIcon brand={brand} onClick={() => onDeleteFile(file)} />
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Upload;
