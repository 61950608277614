/* eslint-disable react/require-default-props */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MAIN_PATH } from 'src/constants/routePaths';
import ActiveIcon from './ActiveIcon';
import globalConstants from '../../../../constants/globalConstants';

interface LinkItemProps {
  label: string;
  to: string;
  active?: boolean;
  badge?: number | string;
  brand: string;
  navPosition?: string;
  onClick?: () => void;
  'data-testid'?: string;
}

const LinkItem: React.FC<LinkItemProps> = ({
  label,
  to,
  active = false,
  badge = '',
  brand,
  navPosition = globalConstants.NAVBAR_POSITION_SIDE,
  onClick = () => {},
  'data-testid': dataTestId,
}: LinkItemProps) => {
  const badgeIsNumber = !!+badge;
  let isActive = '';

  if (active && navPosition === globalConstants.NAVBAR_POSITION_TOP) {
    isActive = 'is-active';
  } else if (active) {
    isActive = 'c-link_activated';
  }

  const location = useLocation();
  // first element should be empty always since pathname always starts with '/'
  const [, ENTRY_PATH] = location.pathname.split('/');

  // pointing directly to absolute path is required because, app could be used by Portal, Zoid or Dashboard
  const ABSOLUTE_PATH = (() => {
    // if path is the same it means it is running as standalone, required to pass e2e tests
    if (ENTRY_PATH === MAIN_PATH) return `/${MAIN_PATH}/${to}` as const;

    return `/${ENTRY_PATH}/${MAIN_PATH}/${to}` as const;
  })();

  return navPosition === globalConstants.NAVBAR_POSITION_SIDE ? (
    <li className='asidebar-nav__item'>
      <div className='asidebar-nav__item__label'>
        <Link to={ABSOLUTE_PATH} className={`c-link ${isActive}`} data-testid={dataTestId}>
          {brand === 'audi' && <ActiveIcon active={active} />}
          <span className='c-link__body  '>{label}</span>
        </Link>

        {badgeIsNumber ? (
          <span className='c-badge__wrapper'>
            <span className='c-badge  c-badge--notification'>{badge}</span>
          </span>
        ) : (
          ''
        )}
      </div>
      <hr />
    </li>
  ) : (
    <li>
      <button type='button' className={`c-tabs__nav-link ${isActive}`} onClick={onClick}>
        <Link to={ABSOLUTE_PATH} className='c-link' data-testid={dataTestId}>
          <span className='c-link__body'>{label}</span>
        </Link>

        {badgeIsNumber && brand === 'vw6' ? (
          <span className='c-badge__wrapper'>
            <span className='c-badge  c-badge--notification'>{badge}</span>
          </span>
        ) : (
          ''
        )}
      </button>
    </li>
  );
};

export default LinkItem;
