import { IAuthState } from 'src/context/AuthContext';
import { Attachment } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

/* eslint-disable @typescript-eslint/no-var-requires */
const Url = require('url-parse');

export const BATCH_LETTER_ENDPOINT = `${process.env.REACT_APP_MESSAGE_CENTER_API_HOST}/getBatchLetters`;

/**
 * Get batch letters
 * @param auth
 * @returns
 */

const getBatchLetters = (originalBody: string, auth: IAuthState): Promise<Attachment> => {
  const attachment = {} as Attachment;

  //    parsing uri from the message body of the batch document
  const uri = Url(originalBody);
  const body = {
    hostname: uri.hostname,
    path: uri.pathname + uri.query,
  };
  return apiClient
    .post(`${BATCH_LETTER_ENDPOINT}`, body, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getBatchLetters');
      attachment.data = response.data;
      return attachment;
    });
};

export default getBatchLetters;
