/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { v4 as uuid } from 'uuid';
import './App.scss';

import { get, set } from './services/session-service';

import { constants, updateConstants } from './constants';
import globalConstants from './constants/globalConstants';
import { ErrorContextProvider } from './context/ErrorContext';
import { MessageContextProvider } from './context/MessageContext';
import { AuthContextProvider } from './context/AuthContext';
import { LoadingContextProvider } from './context/LoadingContext';
import { ConfirmationNotificationContextProvider } from './context/ConfirmationNotificationContext';
import { DocProvider } from './context/DocCenterContext';
import buildAppProps from './utility/buildAppProps';
import { MessageTypes } from './constants/componentConstants';
import { postMessage, updateAuthProps } from './utility/Utilities';
import HelmetPersist from './views/components/common/HelmetPersist';
import i18n from './localization/i18n';
import Site from './views/pages/Site';
import { AccountProvider } from './context/AccountContext';
import { DocCenterAccountProvider } from './context/DocCenterAccountContext';

const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : globalConstants.DEFAULT_BRAND;

if (process.env.REACT_APP_ENV !== 'prod' && process.env.REACT_APP_ENV !== 'cons') {
  const envSetup = {
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_ENV: process.env.REACT_APP_ENV,
    REACT_APP_BRAND: brand,
    xprops: (window as any).xprops,
    appProps: get('appProps'),
  };
  console.debug('Index.js - React app env ===>', envSetup);
}
declare global {
  interface Window {
    xprops: Record<string, unknown> | undefined;
    // this have to be a pathname string, to indentify
    // from wich is app is coming to message center
    rootPath: string;
    adobeDataLayer: Array<any>;
  }
}

const App = (): JSX.Element => {
  updateAuthProps((window as any)?.xprops);
  const appProps = buildAppProps((window as any)?.xprops);
  // if the correlationId is missing, initialize it when hitting the app
  if (!appProps.correlationId) {
    appProps.correlationId = uuid();
  }
  set('appProps', JSON.stringify(appProps));
  updateConstants(appProps.brand ? appProps.brand.toLowerCase() : '');

  if (appProps?.languageCode && !constants.VALID_LANG.find((l: string) => l === appProps?.languageCode)) {
    console.debug('appProps language code===>', appProps?.languageCode, 'valid lang===?', constants.VALID_LANG);
    // post message to parent
    const data = { error: `'${appProps.languageCode}' language not supported` };
    postMessage(appProps.parentDomain, MessageTypes.LANGUAGE_ERROR, data);
  } else if (appProps?.languageCode) {
    // Means we have a valid language code and the key exists on appProps.
    i18n.changeLanguage(appProps.languageCode);
  }

  const localBrand = appProps.brand;
  if (!constants.VALID_BRANDS.find((b: string) => b === localBrand.toLowerCase())) {
    const data = { error: `'${appProps.brand}' brand not supported` };
    postMessage(appProps.parentDomain, MessageTypes.BRAND_ERROR, data);
    return <></>;
  }

  console.debug(appProps);

  return (
    <div className='message-center-app-container'>
      {/* we need this script persisting, because when the Dashboard is running into the Portal App, 
        the Helmet removes all react-helmet scripts loaded before */}
      <HelmetPersist>
        <link
          rel='stylesheet'
          href={`${
            appProps.isMicrofrontend
              ? process.env.REACT_APP_FEATURE_APP_HOST
              : process.env.REACT_APP_FEATURE_APP_CSS_HOST
          }/styles/${localBrand.toLowerCase()}.css`}
          type='text/css'
          data-persist='true'
          data-styles='message-center'
        />
      </HelmetPersist>
      <AuthContextProvider value={JSON.parse(get('authProps'))} consumerProps={appProps}>
        <ErrorContextProvider>
          <LoadingContextProvider>
            <MessageContextProvider>
              <DocProvider>
                <DocCenterAccountProvider>
                  <AccountProvider>
                    <ConfirmationNotificationContextProvider>
                      <Site />
                    </ConfirmationNotificationContextProvider>
                  </AccountProvider>
                </DocCenterAccountProvider>
              </DocProvider>
            </MessageContextProvider>
          </LoadingContextProvider>
        </ErrorContextProvider>
      </AuthContextProvider>
    </div>
  );
};

export default App;
