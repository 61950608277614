import React from 'react';
import { MessageTypes } from 'src/constants/componentConstants';
import i18n from 'src/localization/i18n';
import * as rk from '../../../../../localization/resourceKeys';
import { useMessageContext } from '../../../../../context/MessageContext';
import { b64toBlob, getParentDomain, postMessage, getSourceId } from '../../../../../utility/Utilities';
import './AttachmentDownloadButton.scss';
import { useLoader } from '../../../../../context/LoadingContext';
import globalConstants from '../../../../../constants/globalConstants';

/**
 * Attachment Download Button Props
 *
 * @interface AttachmentDownloadButtonProps
 * @typedef {AttachmentDownloadButtonProps}
 */
interface AttachmentDownloadButtonProps {
  attachmentId: string;
  fileName: string;
  isBatchLetter: boolean;
  originalBody: string;
  scoped?: boolean;
}

const AttachmentDownloadButton = ({
  attachmentId,
  fileName = '',
  isBatchLetter,
  originalBody,
  scoped,
}: Partial<AttachmentDownloadButtonProps>): JSX.Element => {
  const { getAttachments, getBatchLetters } = useMessageContext();
  const { stopLoader, startLoader, isLoading } = useLoader({
    isLoading: false,
    local: scoped,
    key: 'MessageContextProvider',
  });
  const isMyAudiApp =
    getSourceId() === globalConstants.MY_AUDI_MOBILE_SOURCE &&
    process.env.REACT_APP_HIDE_DOCUMENT_CENTER_MYAUDIAPP === 'true';

  const generateDownload = (blobData: Blob): void => {
    // Create an anchor element to trigger the download.
    const element = document.createElement('a');
    element.style.display = 'none';
    document.body.appendChild(element);

    element.href = window.URL.createObjectURL(blobData);
    element.setAttribute('download', fileName);
    element.click();

    // Clean up the element we created for the download.
    window.URL.revokeObjectURL(element.href);
    document.body.removeChild(element);
  };

  const onAttachmentButtonClick = async () => {
    console.debug('Attachment download button clicked');
    if (!isLoading) startLoader();

    if (isBatchLetter) {
      try {
        postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_TRIGGERED, {
          attachmentId,
        });
        const response = await getBatchLetters(originalBody);
        if (response && response.data) {
          postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_SUCCESS, {
            attachmentId,
          });
          const blobData = b64toBlob(response.data, 'application/pdf');
          generateDownload(blobData);
          stopLoader();
        }
      } catch (error) {
        postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_BATCH_LETTER_DOWNLOAD_ERROR, {
          attachmentId,
        });
        console.error(`Error in getBatchLetters=${JSON.stringify(error)}`);
        stopLoader();
      }
    }

    if (!isBatchLetter && attachmentId) {
      try {
        postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_TRIGGERED, {
          attachmentId,
        });

        const response = await getAttachments(attachmentId);

        if (response && response.attachment && response.attachment.data) {
          // If an attachment data is available (base 64 encoded string) we need a Blob object.

          postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_SUCCESS, {
            attachmentId,
          });
          const blobData = b64toBlob(response.attachment.data, 'application/pdf');
          generateDownload(blobData);
          stopLoader();
        }
      } catch (error) {
        postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_BUTTON_DOCUMENT_DOWNLOAD_ERROR, {
          attachmentId,
        });
        console.error(`Error in getAttachments=${JSON.stringify(error)}`);
        stopLoader();
      }
    }
  };

  return (
    <>
      <button
        data-testid='attachment-download-btn'
        type='button'
        className='c-btn  c-btn--secondary c-btn--icon attachment-download-btn'
        onClick={onAttachmentButtonClick}
        disabled={isMyAudiApp}
      >
        <i className='c-btn__icon c-icon c-icon--[semantic-download]' aria-hidden='true' role='img' />
        <span className='c-btn__text'>{fileName}</span>
      </button>
      {isMyAudiApp && (
        <div
          id='myAudiApp-notification-container'
          data-testid='myAudiApp-notification-container'
          className='c-notification__container'
        >
          <div className='c-notification  c-notification--info'>
            <div className='c-notification__outer-wrap'>
              <div className='c-notification__content'>
                <p className='c-notification__text'>
                  <b>{i18n.t(rk.DOCUMENT_CENTER_MYAUDI_CONTENT_ONE)}</b>
                  {i18n.t(rk.DOCUMENT_CENTER_MYAUDI_CONTENT_TWO)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentDownloadButton;
