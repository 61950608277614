import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Brand } from 'src/types';

/**
 * Hook to get the base path without params like :messageId
 * @returns string
 */
export const useBasePath = (): string => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();
  return Object.values(params).reduce((path, param) => path.replace(`/${param}`, ''), location.pathname);
};

/**
 * Hook to get messageId param from router as number or null if not exists
 * @returns messageId as number or null
 */
export const useMessageIdParam = (): null | number => {
  const { messageId = '' } = useParams<{ messageId: string }>();
  const messageIdInt = parseInt(messageId, 10);
  if (!Number.isNaN(messageIdInt)) {
    return messageIdInt;
  }
  return null;
};

/**
 * Hook to get the hash from url and scroll into that section if exists with brand specifications
 */
export const useScrollToElementByHash = (brand: Brand): void => {
  const { hash } = useLocation();
  const [hashWithNoQueryString] = hash.split('?');
  const scrollToElement = useCallback(
    (query: string) => {
      try {
        const target = document.querySelector(query);
        if (target) {
          // keeping the same logic for scrollIntoView
          target.scrollIntoView(
            brand === Brand.AUDI
              ? {
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                }
              : {
                  behavior: 'smooth',
                  block: 'nearest',
                }
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    [brand]
  );
  useEffect(() => {
    if (hashWithNoQueryString) {
      let query = hashWithNoQueryString; // this will be include # at the beginning
      // keeping the same logic what we had before
      if (query === '#General') {
        query = brand === Brand.AUDI ? '#General .c-accordion__header:nth-child(5)' : query;
      }
      scrollToElement(query);
    }
  }, [hashWithNoQueryString, scrollToElement]);
};
