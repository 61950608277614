/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { deviceDetect } from 'react-device-detect';
import splunkLogger from './splunkLogger';
import {
  getCorrelationId,
  getCountry,
  getBrand,
  getAccountNumber,
  getPartyId,
  getSourceId,
  getExternalId,
  getTokenExpTime,
} from '../utility/Utilities';

// TODO - need better environment determinator
// TODO - Set source on global

function createLogEntry(msg) {
  const exp = getTokenExpTime();
  return {
    // Pass the values like country, account number, sourceAPP for the xprops
    message: msg,
    application: `${process.env.REACT_APP_SOURCE_ID || 'vci-messagecenter-app'}`,
    correlationId: getCorrelationId(),
    dateTime: new Date(),
    environment: process.env.NODE_ENV,
    reactEnvironment: process.env.REACT_APP_ENV,
    accountNumber: getAccountNumber(),
    partyId: getPartyId(),
    country: getCountry(),
    sourceId: getSourceId(),
    externalId: getExternalId(),
    brand: getBrand(),
    deviceInfo: deviceDetect(undefined),
    isSessionTimeOut: exp ? new Date(Date.now()) > exp : true,
  };
}

const debug = (...msg) => {
  if ((console as any).useConsole) {
    if ((console as any).robustLogging) {
      (console as any).oldDebug(createLogEntry(msg));
    } else {
      (console as any).oldDebug(msg);
    }
  } else {
    splunkLogger.debug(createLogEntry(msg), '');
  }
};

const log = (...msg) => {
  if ((console as any).useConsole) {
    if ((console as any).robustLogging) {
      (console as any).oldLog(createLogEntry(msg));
    } else {
      (console as any).oldLog(msg);
    }
  } else {
    splunkLogger.info(createLogEntry(msg), '');
  }
};

const info = (...msg) => {
  if ((console as any).useConsole) {
    if ((console as any).robustLogging) {
      (console as any).oldInfo(createLogEntry(msg));
    } else {
      (console as any).oldInfo(msg);
    }
  } else {
    splunkLogger.info(createLogEntry(msg), '');
  }
};

const warn = (...msg) => {
  if ((console as any).useConsole) {
    if ((console as any).robustLogging) {
      (console as any).oldWarn(createLogEntry(msg));
    } else {
      (console as any).oldWarn(msg);
    }
  } else {
    splunkLogger.warn(createLogEntry(msg), '');
  }
};

const error = (msg, originalError) => {
  if ((console as any).useConsole) {
    if ((console as any).robustLogging) {
      (console as any).oldError(createLogEntry(msg));
    } else {
      (console as any).oldError(msg);
    }
  } else {
    const logEntry: any = createLogEntry(msg);
    logEntry.originalError = originalError;
    splunkLogger.error(logEntry);
  }
};

/**
 * Log the link action
 *
 * @param logger - console.log, console.info, console.warn, console.error
 * @param link the link to open
 * @param accountNumber the user account number
 * @param externalId the external id
 */
const logLink = (logger, link, accountNumber, externalId) => {
  logger(`Initiate link=${link} for accountNumber=${accountNumber} and externalId=${externalId}`);
};

/**
 * Log an action
 *
 * @param logger - console.log, console.info, console.warn, console.error
 * @param action the action taken
 * @param accountNumber the user account number
 * @param externalId the external id
 */
const logAction = (logger, action, accountNumber, externalId) => {
  logger(`Initiate action=${action} for accountNumber=${accountNumber} and externalId=${externalId}`);
};

/**
 * Initialize the logger
 *
 * @param useConsole when true the console log will be used. Otherwise splunk
 * @param shownLogLevels the log levels to log (debug, log info, warn, error)
 * @param robustLogging when true and when useConsole = true this will make robust logs like splunk logs
 */
const initializeLogger = (useConsole: boolean, shownLogLevels: any, robustLogging: boolean) => {
  // when robust console logging is present the logs to console will simulate splunk
  (console as any).robustLogging = robustLogging;
  (console as any).useConsole = useConsole;
  if (!useConsole) {
    // save old console methods before override
    (console as any).oldDebug = console.debug;
    console.debug = debug;
    (console as any).oldLog = console.log;
    console.log = log;
    (console as any).oldInfo = console.info;
    console.info = info;
    (console as any).oldWarn = console.warn;
    console.warn = warn;
    (console as any).oldError = console.error;
    console.error = error;
  }

  if (shownLogLevels) {
    if (shownLogLevels.indexOf('debug') < 0) {
      console.debug = () => {};
    }
    if (shownLogLevels.indexOf('log') < 0) {
      console.log = () => {};
    }
    if (shownLogLevels.indexOf('info') < 0) {
      console.info = () => {};
    }
    if (shownLogLevels.indexOf('warn') < 0) {
      console.warn = () => {};
    }
    if (shownLogLevels.indexOf('error') < 0) {
      console.error = () => {};
    }
  }
};

const handler = (event) => {
  if (event.data.type === 'logging' && event.origin === window?.location?.origin) {
    if (event.data.console && console.oldDebug) {
      console.debug = console.oldDebug;
      console.log = console.oldLog;
      console.info = console.oldInfo;
      console.warn = console.oldWarn;
      console.error = console.oldError;
    }
    initializeLogger(event.data.console, event.data.levels, event.data.robustLogging === true);
  }
};
window.addEventListener('message', handler, false);

export { splunkLogger, logLink, logAction, initializeLogger };
