/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import globalConstants from 'src/constants/globalConstants';
import * as RoutePaths from 'src/constants/routePaths';
import { IAuthState } from 'src/context/AuthContext';
import { ThreadType, UIMessage } from 'src/views/pages/mailbox/IListItem.interface';
import i18n from 'src/localization/i18n';
import * as rk from 'src/localization/resourceKeys';
import { Brand, Country, IViewSource } from 'src/types';
import { MessageTypes } from 'src/constants/componentConstants';
import { IAccounts } from 'src/context/AccountContext.interface';
import { mapMessagesToInbox, mapSubjectToMessages } from 'src/views/pages/mailbox/MailBox';
import { IMessageResponse, ISubjectMappingResponse } from 'src/context/MessageContext.interface';
import { DOCUMENT_PATH, FAQ_PATH, INBOX_PATH } from 'src/constants/routePaths';
import { get, set } from '../services/session-service';

/**
 * Get param from a url
 *
 * @param paramName name of param to fetch
 * @returns {string[] | string | null | undefined}
 */
export const getUrlParam = (paramName: string): string => {
  const params = queryString.parse(window.location.search);
  return params[paramName] as string;
};

/**
 * Get brand from valid values
 *
 * @param value the value to check
 * @param validValues array of valid brands
 * @returns {*} brand or null if invalid
 */
export const getBrandFromValues = (value: string, validValues: Array<string>): string | undefined => {
  return validValues.find((element) => element === value);
};

/**
 * Get the file's data as a base64 encoded string.
 * @param file <File>
 * @returns {Promise<string | ArrayBuffer | null>}
 */
export const readFile = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = reject;
  });
};

/**
 * Convert Base 64 encoded string to a Blob object with content type.
 *
 * @param {string} b64Data
 * @param {string} [contentType='']
 * @param {number} [sliceSize=512]
 * @returns {Blob}
 */
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/**
 * Get the correlationId from props
 *
 * @returns {string | undefined} the correlationId
 */
export const getCorrelationId = (): string | undefined => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).correlationId;
  }
  return undefined;
};

/**
 * Get the country from props
 *
 * @returns {string | undefined}
 */
export const getCountry = (): string | undefined => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).country;
  }
  return undefined;
};

/**
 * Get the language from props
 *
 * @returns {string | undefined}
 */
export const getLanguage = (): string | undefined => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).languageCode;
  }
  return undefined;
};

/**
 * Translate a bronson brand set in appProps into generic brand for API calls
 *
 * @param brand - Audi | VW | VW6
 * @returns {string|*}
 */
export const getBrandFromUIBrand = (brand: string): string => {
  if (brand.toLowerCase() === globalConstants.VW_BRAND) return 'VW';
  return brand;
};

/**
 * Get the brand from props
 *
 * @returns {string | undefined}
 */
export const getBrand = (): string | undefined => {
  const appProps = get('appProps');
  if (appProps) {
    return getBrandFromUIBrand(JSON.parse(appProps).brand);
  }
  return undefined;
};

/**
 * Get the account number from authprops
 * @returns {undefined|*}
 */
export const getAccountNumber = (): string | undefined => {
  let accountNumber;
  const authProps = get('authProps');

  if (authProps) {
    accountNumber = JSON.parse(authProps).accountNumber;
  }

  return accountNumber;
};

/**
 * Get partyId from auth properties
 *
 * @returns {number|string|undefined}
 */
export const getPartyId = (): number | string | undefined => {
  const authProps = get('authProps');
  if (authProps) {
    return JSON.parse(authProps).partyId;
  }
  return undefined;
};

/**
 * Get externalId passed to component. When used with brand sites the externalId
 * is the unique identifier used by brand sites to track a user
 *
 * @returns {*}
 */
export const getExternalId = (): string | unknown => {
  return window.xprops ? window.xprops.externalId : null;
};

/**
 * Get sourceId passed to component
 *
 * @returns {*}
 */
export const getSourceId = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).source;
  }
  return undefined;
};

/**
 * Get token expiration from toke in auth props
 * @returns {number|undefined}
 */
export const getTokenExpTime = (): number | Date | undefined => {
  const authPropsString = get('authProps');
  if (!authPropsString) {
    return undefined;
  }

  const authProps = JSON.parse(authPropsString);
  if (authProps?.token) {
    const { exp }: { exp: number } = jwtDecode(authProps?.token);
    return new Date(exp * 1000);
  }
  return undefined;
};

/**
 * Create a uuid4
 *
 * @returns {string} the uuid
 */
/* eslint  no-bitwise: 0 */
export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Get the handleEvent function from app props
 *
 * @returns {undefined|*}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEventHandler = (): undefined | any => {
  return window.xprops ? window.xprops.handleEvent : undefined;
};

/**
 * Post a message to event handler method if it exists, otherwise use standard window.postmessage
 *
 * @param parentDomain: string | null - target parent domain
 * @param type: string - the type of message
 * @param data: any - the data to pass
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postMessage = (parentDomain: string | null, type: string, data: any): void => {
  console.info(`postMessage`, {
    parentDomain,
    source: globalConstants.SOURCE_ID,
    correlationId: getCorrelationId(),
    type,
    data,
  });
  const handler = getEventHandler();
  if (handler) {
    handler(type, globalConstants.SOURCE_ID, data);
    return;
  }
  if (parentDomain) {
    window.parent.postMessage(
      {
        type,
        source: globalConstants.SOURCE_ID,
        correlationId: getCorrelationId(),
        data,
      },
      parentDomain
    );
  }
};

/**
 * Get the parentdomain from props
 *
 * @returns {string | null} the parentDomain | null
 */
export const getParentDomain = (): string | null => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).parentDomain;
  }
  return null;
};

/**
 * Change route, log it and potentially business event depending on hash path
 *
 * @param location
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeRoute = (location: any): void => {
  const data = {
    accountNumber: getAccountNumber(),
  };
  const pathname = location?.pathname ?? '';

  const path = `${location.pathname}${location.hash}`;
  console.debug(`Location changed to ====> ${path}`);
  // only log /message-center/inbox to info for spunk
  // TODO - we need to change what spunk is looking for....
  // if (path === RoutePaths.MESSAGE_CENTER_INBOX_PATH) {
  //   console.info(`Location changed to ====> ${path}`);
  // }

  switch (true) {
    case pathname.endsWith(RoutePaths.MESSAGE_CENTER_INBOX_PATH):
      console.info(`Location changed to ====> ${path}`);
      break;

    case pathname.endsWith(RoutePaths.MESSAGE_CENTER_FAQ_PATH):
      postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_FAQ_VISIT, data);
      break;

    case pathname.endsWith(RoutePaths.MESSAGE_CENTER_CREATE_NEW_MESSAGE):
      postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_STARTED, data);
      break;

    default:
      break;
  }
};

/**
 * Determine source based on the brand a*nd country
 *
 * @param brand
 * @param country
 */

export const getIViewSource = (auth: IAuthState): string => {
  const brand = auth.brand || null;
  const country = auth.country || null;
  let source = IViewSource.default;
  if (brand && country) {
    if (brand === Brand.AUDI) {
      if (country === Country.USA) source = IViewSource.myAudi;
      else source = IViewSource.AFPortal;
    } else if (brand === Brand.VW) {
      if (country === Country.USA) source = IViewSource.VWCPortal;
      else source = IViewSource.VFPortal;
    }
  }
  return source;
};

/**
 * Update the account type in authprops
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAuthProps = (xprops: any): void => {
  let accountType;
  if (xprops?.account?.accountProductType?.detailedAccountType)
    accountType = xprops?.account?.accountProductType?.detailedAccountType;

  const authProps = JSON.parse(get('authProps'));
  if (accountType && !authProps?.accountType) {
    authProps.accountType = accountType;
  }
  set('authProps', JSON.stringify({ ...authProps }));
};

/**
 * Get the account type from authprops
 * @returns {string|undefined}
 */
export const getAccountType = (): string | undefined => {
  let accountType;
  const authProps = get('authProps');
  if (authProps) {
    accountType = JSON.parse(authProps).accountType;
  }
  return accountType;
};

export const generateWelcomeMessage = (): UIMessage => {
  const welcomeMessage = {
    id: 2,
    threadId: 2,
    type: ThreadType.WELCOME_MESSAGE,
    timestamp: '',
    unread: false,
    title: i18n.t(rk.DEFAULT_MESSAGE_SUBJECT),
    description: `${i18n.t(rk.DEFAULT_MESSAGE_GREETING)} ${
      getBrand()?.toLocaleLowerCase() === globalConstants.AUDI_BRAND
        ? i18n.t(rk.DEFAULT_MESSAGE_WELCOME_MSG_AUDI)
        : i18n.t(rk.DEFAULT_MESSAGE_WELCOME_MSG_VW)
    } ${i18n.t(rk.DEFAULT_MESSAGE_PARAGRAPH1)} <ol><li>${
      getBrand()?.toLocaleLowerCase() === globalConstants.AUDI_BRAND
        ? i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_ONE_AUDI)
        : i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_ONE_VW)
    }</li><li>${i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_TWO)}</li><li>${i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_THREE)}</li>   
    ${
      getCountry() === globalConstants.DEFAULT_COUNTRY ? `<li>${i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_FOUR)} </li>` : ''
    }<li>${i18n.t(rk.DEFAULT_MESSAGE_LIST_ITEM_FIVE)}</li></ol> ${i18n.t(rk.DEFAULT_MESSAGE_PARAGRAPH2)}`,
    active: false,
    attachments: [],
    source: '',
  };

  return welcomeMessage;
};

/**
 * Create the page load event.
 * @returns {any}
 */
export const generateSiteLoadEvent = (
  account: IAccounts,
  subjectMappings: ISubjectMappingResponse,
  messages: IMessageResponse,
  newMessageCount: number
) => {
  const inboxMessages = mapMessagesToInbox(mapSubjectToMessages(subjectMappings, messages.messages), null, null);
  // Measure page load time using Performance API
  const loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;

  // eslint-disable-next-line no-unused-vars
  const siteLoadEvent = {
    event: 'PAGE_LOAD',
    page: {
      instanceId: {
        // defines which environment user is on ('QA' or 'Production')
        environment:
          process.env.REACT_APP_ENV !== globalConstants.PROD_ENVIRONMENT
            ? globalConstants.QA_ENVIRONMENT
            : globalConstants.PROD_ENVIRONMENT_LONG,
      },
      info: {
        pageTitle: document.getElementsByTagName('title')[0]?.innerHTML,
        pageSubtitle: '', // first <h> element (if non pii)
        hostName: window.location.hostname, // URL Domain
        pagePath: window.location.pathname, // URL Page path
        urlQueryParameter: window.location.search, // URL Query Param (don't include PII)
        urlHashFragment: window.location.hash, // URL Hash Fragment
        pageLoadTime: loadTime, // Time it took for page to load
        pageID: '', // Numerical ID of page, empty since we don't have one.
        version: '', // Version of the page, empty since we don't have one.
        language: getLanguage(), // Language the Page is in
        currency:
          getCountry() === globalConstants.DEFAULT_COUNTRY
            ? globalConstants.USD_CURRENCY
            : globalConstants.CANADIAN_CURRENCY,
        market: getCountry(), // respective market ('US' or 'CA')
        brand: getBrand(), // Page Brand ('VW' or 'AUDI')
        sourceId: getSourceId(),
      },
      category: {
        primaryCategory:
          getBrand() === globalConstants.VW_BRAND
            ? globalConstants.COMPANY_VOLKSWAGEN_FINANCIAL_SERVICES
            : globalConstants.COMPANY_AUDI_FINANCIAL_SERVICES,
        subCategory: 'Owner Portal', // sub-category of page, empthy since we don't have one.
        pageType: 'Help Center', // type of page, empty for now?
      },
    },
    user: {
      testAccount: process.env.REACT_APP_ENV !== globalConstants.PROD_ENVIRONMENT, // either true for false
      partyId: '', // user's party ID - MUST BE SCRAMBLED/HASHED
      mcvid: '', // marketing cloud user ID to be stored in case of iframes
      accountId: account.AccountId, // user's account ID - MUST BE SCRAMBLED/HASHED
      partyRoleType: account.Customer.PartyRoleType, // User's account type - 'Contract Holder' or other type
      paymentAccountType: account.Finance.AccountType, // Type of payment system - Lease, loan, balloon*, etc
      logInState: true, // Either TRUE-logged in or FALSE-not logged in
      location: '', // Since we don't collect this data.
    },
    messages: {
      newMessageCount, // Amount of new/unread messages - populates on homepage/message center
      totalMessageCount: inboxMessages.length,
    },
  };

  // Make sure the data layer exists in the window object.
  if (window.adobeDataLayer && process.env.REACT_APP_ADOBE_ANALYTICS_FLAG === 'true') {
    if (
      window.location.pathname.includes(INBOX_PATH) ||
      window.location.pathname.includes(FAQ_PATH) ||
      window.location.pathname.includes(DOCUMENT_PATH)
    ) {
      window.adobeDataLayer.push(siteLoadEvent);
    }
  }
};
