/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { ChildrenType } from '../types/generic-types';
import { createCtx } from '../utility/Helper';

interface ErrorContextType {
  error: ErrorType | null;
  addError: (err: ErrorType) => void;
  removeError: () => void;
}

export interface ErrorType {
  messageCode: string; // code of the message. Should be code to look up content
  title: string; // the title error to display in banner notification
  message: string; // the message detail that might be sent in logs
  aError?: Error; // the error
  errorOrigin?: string; // the origin or the error (i.e. a flow, a page, a component)
  // this is just we need so far, it could be more extensive in the future
}

interface ErrorContextProviderProps {
  children: ChildrenType;
}

export const [useErrorContext, ErrorCtxProvider] = createCtx<ErrorContextType>('ErrorCtxProvider');

export const ErrorContextProvider: React.FC<ErrorContextProviderProps> = (props): JSX.Element => {
  const { children } = props;
  const [error, setError] = useState<ErrorType | null>(null);

  const addError = (err: ErrorType): void => {
    setError(err);
  };

  const removeError = (): void => {
    setError(null);
  };

  const contextValue = {
    error,
    addError: useCallback((err: ErrorType) => addError(err), []),
    removeError: useCallback(() => removeError(), []),
  };
  return <ErrorCtxProvider value={contextValue}>{children}</ErrorCtxProvider>;
};
