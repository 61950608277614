import React from 'react';
import MessageTextArea from '../../components/common/message-textarea/MessageTextArea';

interface NewMessageProps {
  // eslint-disable-next-line react/require-default-props
  mobile?: boolean;
}

const NewMessage = ({ mobile = false }: NewMessageProps): JSX.Element => {
  return (
    <div className='new-message-container' role='main'>
      <MessageTextArea mobile={mobile} />
    </div>
  );
};

export default NewMessage;
