/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './MailBox.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { compareDesc } from 'date-fns';
import globalConstants from 'src/constants/globalConstants';
import { LINK_TO_PARENT_PATH, MESSAGE_CENTER_INBOX_PATH } from 'src/constants/routePaths';
import { AccountContext } from 'src/context/AccountContext';
import { IAccountContext } from 'src/context/AccountContext.interface';
import MailBoxNavigator from './mailbox-navigator/MailboxNavigator';
import MailBoxPreviewer from './mailbox-previewer/MailBoxPreviewer';
import { ThreadType, UIMessage } from './IListItem.interface';
import { useMessageContext } from '../../../context/MessageContext';
import { IMessage, ISubjectMappingResponse } from '../../../context/MessageContext.interface';
import { useMessageIdParam } from '../../../hooks/Path';
import { useConfirmationNotificationContext } from '../../../context/ConfirmationNotificationContext';
import { useErrorContext } from '../../../context/ErrorContext';
import { Error } from '../../components/common/errors/Error';
import ConfirmationNotification from '../../components/common/confirmation-notification/ConfirmationNotification';
import { MessageTypes } from '../../../constants/componentConstants';
import { postMessage, generateWelcomeMessage, generateSiteLoadEvent } from '../../../utility/Utilities';

const getSubjectLabel = (message: IMessage, subjectMappings: ISubjectMappingResponse | undefined) => {
  if (
    message.source &&
    message.source.toLowerCase() !== 'livelink' &&
    message.source.toLowerCase() !== 'docuploadapi'
  ) {
    return subjectMappings?.documentSubjectMapping.find(
      (documentSubjectMapping) => message.attachments[0].type === documentSubjectMapping.id
    )?.description;
  }
  return subjectMappings?.messageSubjectMapping?.find((subject) => message.subjectTopicId === subject.id)?.description;
};

const getDocumentLabel = (message: IMessage, subjectMappings: ISubjectMappingResponse | undefined) => {
  if (message.source && message.source.toLowerCase() !== 'livelink') {
    //  TODO: check if we should show a subject or the document name (subject) on the UI
    // if (message.source.toLowerCase() === 'livelink') {
    //   return message.subject;
    // }
    return subjectMappings?.documentSubjectMapping.find(
      (documentSubjectMapping) => message.attachments[0].type === documentSubjectMapping.id
    )?.description;
  }
  return subjectMappings?.messageSubjectMapping?.find((subject) => message.id === subject.id)?.description;
};

export function mapSubjectToMessages(
  subjectMappings: ISubjectMappingResponse | undefined,
  messages: IMessage[] = []
): IMessage[] {
  return messages.map((message) => {
    return {
      ...message,
      subject: message.subject ? message.subject : getSubjectLabel(message, subjectMappings),
      body: getDocumentLabel(message, subjectMappings) ?? message.body,
    };
  });
}

function convertMessage(message: IMessage): UIMessage {
  const description = (message.body ?? '').replaceAll('\n', '&lt;br&gt;');

  return {
    id: message.id,
    threadId: message.threadId,
    type: message.threadType ?? ThreadType.DOCUMENT,
    unread: !message.isread,
    title: message.subject ?? '',
    timestamp: message.timestamp,
    description,
    active: false,
    repliesList: [],
    attachments: message.attachments ? message.attachments : [],
    source: message.source,
  };
}

/**
 * Get all raw messages and struct them by main message and its replies.
 * @param messages
 * @returns
 */
const mapMessageStructure = (messages: IMessage[]): UIMessage[] => {
  if (!messages || messages.length === 0) {
    return [];
  }
  // create all base messages
  const inboxArray = messages
    .filter(
      (item: IMessage) =>
        item.threadType === ThreadType.ORIGINAL ||
        item.threadType === undefined ||
        item.threadType === ThreadType.WELCOME_MESSAGE
    )
    .map(convertMessage);

  const inboxWithNestedReplies = inboxArray.map(({ ...item }) => {
    return {
      ...item,
      repliesList: messages
        .filter(
          (message) =>
            message.id === item.id && message.threadType !== ThreadType.ORIGINAL && message.threadType !== undefined
        )
        .map(convertMessage)
        .sort((first, second) => compareDesc(new Date(first.timestamp), new Date(second.timestamp))),
    };
  });

  // only return items that have replies because only they show on inbox
  return inboxWithNestedReplies.filter(
    (item) => item.repliesList?.length || item.type === ThreadType.DOCUMENT || item.type === ThreadType.WELCOME_MESSAGE
  );
};

/**
 It is created because we just need a stack with the last CSR reply from each thread.
 To avoid over complexity within Mailbox navigator component and send just the necessary.
 * @param messages the array of messages
 * @param sentMessage the sent message
 * @param welcomeMessage the welcome message to display
 * @returns UIMessage[] the complete message list
 */
export function mapMessagesToInbox(
  messages: IMessage[],
  sentMessage: UIMessage | null,
  welcomeMessage: UIMessage | null
) {
  const messagesStructuredWithReplies = mapMessageStructure(messages);

  // I will get just messages with CSR response
  const csrReplies = messagesStructuredWithReplies.reduce((prev, current): UIMessage[] => {
    let csrMessage = null;

    if (current.type === ThreadType.DOCUMENT) {
      csrMessage = current;
    } else {
      csrMessage = current.repliesList?.find((mes) => mes.type === ThreadType.CSR);
    }

    return csrMessage ? prev.concat(csrMessage) : prev;
  }, [] as UIMessage[]);

  // we need messages sorted by date.
  csrReplies.sort((first, second) => compareDesc(new Date(first.timestamp), new Date(second.timestamp)));

  if (sentMessage && sentMessage.id === 1) {
    csrReplies.unshift(sentMessage);
  }
  // Add here the welcome message.
  csrReplies.push(welcomeMessage as UIMessage);
  return csrReplies;
}

function MailBox({ brand, mobile }: { brand: 'vw6' | 'audi'; mobile: boolean }): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const accountContext = useContext(AccountContext) as IAccountContext;
  const accounts = accountContext?.accounts;
  const [messageData, setMessageData] = useState<UIMessage | null>(null);
  const { messages, sentMessage, setNewSentMessage, refreshMessages, subjectMappings, newmessageCount } =
    useMessageContext();
  const { removeConfirmationNotification } = useConfirmationNotificationContext();
  const { removeError } = useErrorContext();
  const genWelcomeMessage: UIMessage = generateWelcomeMessage();
  const inboxMessage = useMemo(
    () => mapMessagesToInbox(mapSubjectToMessages(subjectMappings, messages.messages), sentMessage, genWelcomeMessage),
    [messages, sentMessage, genWelcomeMessage, subjectMappings]
  );

  const messagesStructuredWithReplies = useMemo(
    () => mapMessageStructure(mapSubjectToMessages(subjectMappings, messages.messages)),
    [messages.messages, sentMessage, generateWelcomeMessage(), subjectMappings]
  );

  const messageId = useMessageIdParam();
  const storedProps = localStorage.getItem('appProps');
  const appProps = storedProps ? JSON.parse(storedProps) : {};

  const onClickMessage = (message: UIMessage) => {
    const { id } = message;

    const rootMessage = messagesStructuredWithReplies.find((mes) => mes.id === id);

    if (rootMessage) {
      setMessageData({ ...(rootMessage ?? {}), active: true });
    } else if (message.type === ThreadType.WELCOME_MESSAGE) {
      setMessageData({ ...(message ?? {}), active: true });
    }

    postMessage(appProps.parentDomain, MessageTypes.MESSAGE_CENTER_MESSAGE_MAILBOX_MESSAGE_SELECTED, { messageId: id });
    navigate(`${id}`);
  };

  /**
   * Find a message with `msgId` and is set to display the previewer.
   * @param msgId
   */
  const findMessageAndSelect = (msgId: number) => {
    const messageFound = messagesStructuredWithReplies.find((message) => message.id === msgId);

    if (messageFound) {
      setMessageData({ ...messageFound, active: true });
    } else if (sentMessage?.id === msgId) {
      // sent message is the mocked message, it is not set in the array
      setMessageData({ ...sentMessage, active: true });
    } else if (msgId === genWelcomeMessage.id) {
      setMessageData({ ...genWelcomeMessage, active: true });
    } else {
      // if message is not found with msgId go back to /inbox page
      navigate(LINK_TO_PARENT_PATH);
    }
  };

  useEffect(() => {
    // When a new message is created `sentMessage` is set and redirected to
    // `MESSAGE_CENTER_INBOX_PATH/:id` path, hence we need to display previewer and
    // set url with its message id.
    if (messageId) {
      // if you are here it means you going to search your message in messages stack
      // and open previewer with that message data.
      findMessageAndSelect(messageId);
    }

    if (location.pathname.endsWith(MESSAGE_CENTER_INBOX_PATH)) {
      // it used to hide message previewer when url has not messageId
      setMessageData(null);
    }
  }, [location, messages]);

  useEffect(() => {
    return () => {
      removeConfirmationNotification();
      setMessageData(null);
      setNewSentMessage(null);
      removeError();
    };
  }, []);

  useEffect(() => {
    if (
      accounts &&
      accounts.AccountId !== '' &&
      messages.messages.length >= 0 &&
      process.env.REACT_APP_ADOBE_ANALYTICS_FLAG === 'true'
    ) {
      generateSiteLoadEvent(accounts, subjectMappings as ISubjectMappingResponse, messages, newmessageCount as number);
    }
  }, [accounts, messages.messages]);

  const onConfirmationClose = () => {
    postMessage(appProps.parentDomain, MessageTypes.MESSAGE_CENTER_MESSAGE_MAILBOX_CONFIRMATION_MESSAGE_CLOSED, '');
    if (sentMessage) {
      setMessageData(null); // Reset message data.
      setNewSentMessage(null);
      inboxMessage.shift();
      refreshMessages();
      navigate(LINK_TO_PARENT_PATH); // Redirect to Mailbox without preview open.
    }
  };

  if (mobile) {
    return (
      <>
        <ConfirmationNotification onCloseCallback={onConfirmationClose} />
        <main className='mailbox-container'>
          {!messageData && (
            <div className='mailbox-error-container'>
              <Error />
            </div>
          )}
          {!messageData && (
            <MailBoxNavigator
              onClickMessage={onClickMessage}
              brand={brand}
              messages={inboxMessage}
              hasNewSentMessage={sentMessage !== null}
            />
          )}
          {messageData && <MailBoxPreviewer messageData={messageData} brand={brand} />}
        </main>
      </>
    );
  }

  return (
    <>
      <main className='mailbox-container'>
        <MailBoxNavigator
          onClickMessage={onClickMessage}
          brand={brand}
          messages={inboxMessage}
          hasNewSentMessage={sentMessage !== null}
        />
        {brand === globalConstants.AUDI_BRAND ? (
          <>
            {!messageData && (
              <div className='mailbox-error-container'>
                <Error />
              </div>
            )}
            <ConfirmationNotification onCloseCallback={onConfirmationClose} />
            {messageId !== null && messageData && <MailBoxPreviewer messageData={messageData} brand={brand} />}
          </>
        ) : (
          <div className='mailbox-previewer-container'>
            {!messageData && (
              <div className='mailbox-error-container'>
                <Error />
              </div>
            )}
            <ConfirmationNotification onCloseCallback={onConfirmationClose} />
            {messageId !== null && messageData && <MailBoxPreviewer messageData={messageData} brand={brand} />}
          </div>
        )}
      </main>
    </>
  );
}

export default MailBox;
