import React, { useCallback, useState } from 'react';
import { ChildrenType } from '../types/generic-types';
import { createCtx } from '../utility/Helper';

export interface ConfirmationNotificationContextType {
  confirmation: ConfirmationType | null;
  addConfirmationNotification: (confirmation: ConfirmationType) => void;
  removeConfirmationNotification: () => void;
}

export const [useConfirmationNotificationContext, ConfirmationNotificationCtxProvider] =
  createCtx<ConfirmationNotificationContextType>('ConfirmationNotificationCtxProvider');

export interface ConfirmationNotificationContextProviderProps {
  children: ChildrenType;
}

export interface ConfirmationType {
  message: string;
}

export const ConfirmationNotificationContextProvider: React.FC<ConfirmationNotificationContextProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}): JSX.Element => {
  const [confirmation, setConfirmation] = useState<ConfirmationType | null>(null);

  const addConfirmationNotification = useCallback((c: ConfirmationType) => {
    setConfirmation(c);
  }, []);

  const removeConfirmationNotification = useCallback(() => {
    setConfirmation(null);
  }, []);

  return (
    <ConfirmationNotificationCtxProvider
      value={{ confirmation, addConfirmationNotification, removeConfirmationNotification }}
    >
      {children}
    </ConfirmationNotificationCtxProvider>
  );
};
