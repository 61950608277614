import { IAuthState } from 'src/context/AuthContext';
import { IMessage } from '../../context/MessageContext.interface';
import { apiClient } from '../../utility/Axios';
import { buildHeaderParams } from './service-utilities';

export const CONVERSATION_ENDPOINT = '/conversation';
/**
 * Get conversation by message id
 * @param messageId string
 * @param auth
 * @returns
 */
const getConversation = (messageId: string | number, auth: IAuthState): Promise<IMessage[]> => {
  return apiClient
    .get(`${CONVERSATION_ENDPOINT}/${messageId}`, {
      headers: buildHeaderParams(auth),
    })
    .then((response) => {
      console.debug('Success calling getConversation');
      return response.data;
    });
};

export default getConversation;
