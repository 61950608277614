/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: Verify eslint error "Curly braces are unnecessary here"
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { ThreadType, UIMessage } from 'src/views/pages/mailbox/IListItem.interface';
import { IMessageSubjectLine, ISubjectMappingResponse } from 'src/context/MessageContext.interface';
import { useDialogContext } from 'src/context/DialogContext';
import { Select } from '@vwfs-bronson/bronson-react';
import { MessageTypes } from 'src/constants/componentConstants';
import {
  postMessage,
  getCountry,
  getAccountNumber,
  getParentDomain,
  generateSiteLoadEvent,
} from 'src/utility/Utilities';
import DOMPurify from 'dompurify';
import { LINK_TO_PARENT_PATH } from 'src/constants/routePaths';
import { AccountContext } from 'src/context/AccountContext';
import { IAccountContext } from 'src/context/AccountContext.interface';
import { Error } from '../errors/Error';
import { useErrorContext } from '../../../../context/ErrorContext';
import WordCountTextArea from '../textarea-word-counter/WordCountTextArea';
import UploadFile, { IFile } from '../upload-file/UploadFile';
import Modal from '../confirmation-modal/ModalConfirmation';
import globalConstants from '../../../../constants/globalConstants';
import i18n from '../../../../localization/i18n';
import * as rk from '../../../../localization/resourceKeys';
import { useMessageContext } from '../../../../context/MessageContext';
import { useConfirmationNotificationContext } from '../../../../context/ConfirmationNotificationContext';

// @ts-ignore
import eSignPDFAudi from '../../../../assets/pdfs/eSignDisclosureandConsent-Audi.pdf';
// @ts-ignore
import eSignPDFVW from '../../../../assets/pdfs/eSignDisclosureandConsent-VW.pdf';

interface MessageTextAreaProps {
  mobile: boolean;
  parentMessage: UIMessage;
  successCallback: () => void;
}

const PLACEHOLDER_BRAND = {
  [globalConstants.AUDI_BRAND]: i18n.t(rk.NEW_MESSAGE_DESCRIPTION_PLACEHOLDER_TEXT_AUDI),
  [globalConstants.VW_BRAND]: i18n.t(rk.NEW_MESSAGE_DESCRIPTION_PLACEHOLDER_TEXT_VW6),
};

const getAssetsByBrand = (brand: string) => {
  const modalIcon = brand === globalConstants.AUDI_BRAND ? 'c-icon--[caution]' : 'c-icon--[semantic-warning]';
  const eSignPDFURL = brand === globalConstants.AUDI_BRAND ? eSignPDFAudi : eSignPDFVW;
  return { modalIcon, eSignPDFURL };
};

const getValidationSchema = (replymessage: boolean, countryCanada: boolean) => {
  return Yup.object({
    subjectId: replymessage ? Yup.number() : Yup.number().required(i18n.t(rk.VALIDATION_SUBJECT_REQUIRED)),
    body: Yup.string()
      .trim()
      .min(1, i18n.t(rk.VALIDATION_BODY_NOTEMPTY))
      .max(5000, i18n.t(rk.VALIDATION_BODY_MAX_CHARACTERS))
      .required(i18n.t(rk.VALIDATION_BODY_REQUIRED)),
    eSignConsent: countryCanada
      ? Yup.boolean()
      : Yup.boolean()
          .oneOf([true], i18n.t(rk.VALIDATION_ESIGN_AGREEMENT))
          .required(i18n.t(rk.VALIDATION_ESIGN_AGREEMENT)),
  });
};

const hasErrorByField = (field: string, touched: FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
  return !!(touched[field] && errors[field]);
};

enum MessageStatus {
  ERROR = 'error',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
  ABORTED = 'aborted',
  CANCELLED = 'cancelled',
}

const getMessageByType = (type: MessageStatus, replymessage: boolean) => {
  switch (type) {
    case MessageStatus.ERROR:
      return replymessage ? MessageTypes.MESSAGE_CENTER_REPLY_ERROR : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_ERROR;
    case MessageStatus.SUBMITTED:
      return replymessage
        ? MessageTypes.MESSAGE_CENTER_REPLY_SUBMITTED
        : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_SUBMITTED;
    case MessageStatus.COMPLETED:
      return replymessage
        ? MessageTypes.MESSAGE_CENTER_REPLY_COMPLETED
        : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_COMPLETED;
    case MessageStatus.ABORTED:
      return replymessage ? MessageTypes.MESSAGE_CENTER_REPLY_ABORTED : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_ABORTED;
    case MessageStatus.CANCELLED:
      return replymessage
        ? MessageTypes.MESSAGE_CENTER_REPLY_CANCELLED
        : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_CANCELLED;
    default:
      return '';
  }
};
const MessageTextArea = ({ mobile = false, parentMessage }: Partial<MessageTextAreaProps>): React.ReactElement => {
  const sendMessageRunning = useRef<boolean>(false);
  const replymessage = !!parentMessage;
  const [malwareDetected, setMalwareDetected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { removeError } = useErrorContext();
  const {
    sendMessage,
    subjectMappings,
    getSubjectLines,
    setNewSentMessage,
    refreshMessages,
    messages,
    newmessageCount,
  } = useMessageContext();
  const { addConfirmationNotification } = useConfirmationNotificationContext();

  const storedProps = localStorage.getItem('appProps');
  const appProps = storedProps ? JSON.parse(storedProps) : {};
  const { modalIcon, eSignPDFURL } = getAssetsByBrand(appProps?.brand?.toLowerCase());

  const placeholderText = PLACEHOLDER_BRAND[appProps?.brand?.toLowerCase()] || '';
  const countryCanada = getCountry() !== globalConstants.DEFAULT_COUNTRY;

  // State variables
  const { state: dialogState, setUpdate, hideDialog, showDialog, watchEffect: dialogEffect } = useDialogContext();
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const subjectMappingRetries = useRef(0);

  const attachments = useRef(new Map()).current;
  const [subjectLines, setSubjectLines] = useState<IMessageSubjectLine[]>();
  const accountContext = useContext(AccountContext) as IAccountContext;
  const accounts = accountContext?.accounts;

  // Handle the lack of subject ID for replies (UI Message type)
  const findMessageSubjectID = (parentMessageID: number | undefined): number => {
    const filteredMessage = messages.messages.find((message: { id: number }) => message.id === parentMessageID);
    if (filteredMessage) {
      return filteredMessage.subjectTopicId;
    }

    return 0;
  };

  const onChangeFiles = (blobFiles: File[], mappedFiles: IFile[]) => {
    const [blobfile] = blobFiles;
    const [mappedFile] = mappedFiles;

    attachments.set(mappedFile.id, blobfile);
  };

  const onDeleteFiles = (mappedFile: IFile) => {
    attachments.delete(mappedFile.id);
  };

  const addSentConfirmation = () => {
    const message = i18n.t(rk.MESSAGE_SENT_CONFIRMATION_NOTIFICATION_CONTENT);
    addConfirmationNotification({ message });
  };

  const navigateToInbox = function navigateToInbox<T>(id: T) {
    navigate(`${LINK_TO_PARENT_PATH}/${id}`);
  };

  const navigateToMessage = () => {
    navigate(location.pathname);
  };

  const generateSentMessage = (subject: string | undefined, body: string, reply = false): UIMessage => {
    const dateNow = new Date();
    const nowUTC = Date.UTC(
      dateNow.getUTCFullYear(),
      dateNow.getUTCMonth(),
      dateNow.getUTCDate(),
      dateNow.getUTCHours(),
      dateNow.getUTCMinutes(),
      dateNow.getUTCSeconds()
    );

    return {
      id: parentMessage?.id ? parentMessage.id : 1,
      threadId: 1,
      type: reply ? ThreadType.REPLY : ThreadType.ORIGINAL,
      timestamp: new Date(nowUTC).toISOString(),
      unread: false,
      title: subject ?? '',
      // Sanitize content to prevent XSS attacks or JS injection
      description: DOMPurify.sanitize(body),
      active: true,
      attachments: [],
      source: '',
    };
  };

  const formik = useFormik({
    initialValues: {
      subject: '',
      subjectId: '',
      body: '',
      // if country is from canada by default eSignConsent will be true since checkbook will not show otherwise will be false
      eSignConsent: !!countryCanada,
    },
    validationSchema: getValidationSchema(replymessage, countryCanada),
    onSubmit: async (values, { setSubmitting }) => {
      setMalwareDetected(false);
      setSendButtonDisabled(true); // Make sure the button is disabled.
      postMessage(appProps.parentDomain, getMessageByType(MessageStatus.SUBMITTED, replymessage), '');
      const selectedSubject = subjectLines?.filter((a) => a.id === +values.subjectId)[0];
      const sanitezedContent = DOMPurify.sanitize(values.body).trim();

      // if size is major than 0 it means we have attachment to upload.
      const formData = new FormData();
      if (values.body) {
        // Sanitize content to prevent XSS attacks or JS injection
        formData.append('body', sanitezedContent);
      }
      if (replymessage) {
        // add messageId
        formData.append('messageId', `${parentMessage?.id}`);
        // get the threadId of the message you are actually replying to. This is a hack
        const lastThreadId = parentMessage?.repliesList?.pop()?.threadId;
        formData.append('threadId', `${lastThreadId}`);
        formData.append('subject', `${parentMessage?.title}`);
        // @ts-ignore
        formData.append('subjectTopicId', `${findMessageSubjectID(+parentMessage.id)}`);
      } else if (values.subjectId) {
        formData.append('subjectTopicId', values.subjectId);
        formData.append('subject', selectedSubject?.description ?? '');
      }

      console.debug(attachments);
      if (attachments.size) {
        postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_UPLOAD_ATTEMPTED, {
          accountNumber: getAccountNumber(),
        });
        Array.from(attachments.values()).forEach((file) => {
          formData.append('file', file, file.name);
        });
      }

      // Toggle the flag on, in case user moves out of the page.
      sendMessageRunning.current = true;

      sendMessage(formData)
        .then(() => {
          addSentConfirmation();
          setSubmitting(false);
          const generatedNewMessage = generateSentMessage(
            selectedSubject?.description ?? parentMessage?.title,
            values.body,
            replymessage
          );
          setNewSentMessage(generatedNewMessage);
          setSendButtonDisabled(false);

          postMessage(appProps.parentDomain, getMessageByType(MessageStatus.COMPLETED, replymessage), '');

          if (attachments.size) {
            postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_UPLOAD_SUCCESSFUL, {
              accountNumber: getAccountNumber(),
            });
          }
          if (replymessage) {
            refreshMessages();
            navigateToMessage();
          } else {
            navigateToInbox(generatedNewMessage.id);
          }
          setUpdate(false);
          sendMessageRunning.current = false;
        })
        .catch((error) => {
          sendMessageRunning.current = false;
          console.error(`Error in sendMessage=${JSON.stringify(error)}`);
          const malware = error?.aError?.response?.data === 'Malware detected';
          setMalwareDetected(malware);

          if (malware) {
            postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_UPLOAD_MALWARE_DETECTED, {
              accountNumber: getAccountNumber(),
            });
            removeError();
          } else if (attachments.size > 0) {
            postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_UPLOAD_ERROR, {
              accountNumber: getAccountNumber(),
            });
          }

          postMessage(getParentDomain(), getMessageByType(MessageStatus.ERROR, replymessage), {
            accountNumber: getAccountNumber(),
          });

          setSubmitting(false);
          setSendButtonDisabled(false);
        });
    },
  });

  /* Check if we need to enable the send button according 
  to where we are showing this component. It shows under
  reply message and create new message */
  useEffect(() => {
    if (replymessage && formik.values.eSignConsent && formik.values.body.trim()) {
      setSendButtonDisabled(false);
    } else if (formik.values.eSignConsent && formik.values.subjectId && formik.values.body.trim()) {
      setSendButtonDisabled(false);
    } else if (formik.values.subjectId && formik.values.body.trim() && countryCanada) {
      setSendButtonDisabled(false);
    } else {
      setSendButtonDisabled(true);
    }
  }, [formik.values.eSignConsent, formik.values.subjectId, formik.values.body]);

  /* In case we show an error, remove error on component unmount.
  Also using a ref to not let the retry for subjectMappings go on forever.
  Gets subject lines for the select component + filter & sort */
  useEffect(() => {
    if (
      subjectMappings &&
      Array.isArray(subjectMappings.messageSubjectMapping) &&
      subjectMappings.messageSubjectMapping.length > 0
    ) {
      const filteredSubjectLines = subjectMappings.messageSubjectMapping
        .filter(
          (subjectLine) =>
            subjectLine.culture === appProps?.languageCode.toLowerCase() &&
            subjectLine.status === globalConstants.MESSAGE_CENTER.SUBJECT_MAPPING_STATUS_ACTIVE
        )
        .sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });

      setSubjectLines(filteredSubjectLines);
    } else if (subjectMappingRetries.current <= 0) {
      subjectMappingRetries.current += 1;
      getSubjectLines();
    }

    return () => {
      removeError();
    };
  }, [subjectMappings]);

  // This hook will try to get us more information if the user submits
  // but left the page before completion.
  useEffect(() => {
    return () => {
      // If the process of sending has not yet finished.
      if (sendMessageRunning.current) {
        if (attachments.size > 0) {
          postMessage(getParentDomain(), MessageTypes.MESSAGE_CENTER_ATTACHMENT_UPLOAD_USER_ABORTED, {});
        }

        postMessage(getParentDomain(), getMessageByType(MessageStatus.ABORTED, replymessage), {});
      }
    };
  }, [sendMessageRunning]);

  useEffect(() => {
    if (
      accounts &&
      accounts.AccountId !== '' &&
      messages.messages.length >= 0 &&
      process.env.REACT_APP_ADOBE_ANALYTICS_FLAG === 'true'
    ) {
      generateSiteLoadEvent(accounts, subjectMappings as ISubjectMappingResponse, messages, newmessageCount as number);
    }
  }, [accounts, messages.messages]);

  // Modal exit functions.
  const modalClosed = () => hideDialog('CANCEL');
  const modalExit = () => hideDialog('OK');

  const goToHomeAction = () => {
    // if replymessage is false it means is new-message view.
    if (replymessage === false && mobile) {
      navigate(LINK_TO_PARENT_PATH);
      return;
    }

    if (window.rootPath) {
      navigate(window.rootPath);
      return;
    }

    navigate(-1);
  };

  dialogEffect((state, hideAction: string, showLabel: string) => {
    // if hideAction and showLabel dont exist, that means `showDialog` was dispatched
    // and we dont want to do anything special
    if (!hideAction && !showLabel) return;
    // if action is CANCEL dont do nothing
    if (hideAction === 'CANCEL') return;
    // if action is BACK_TO_HOME_ACTION, it means `back to home` link was dispatched
    // and the user press OK action
    if (showLabel === 'BACK_TO_HOME_ACTION') {
      goToHomeAction();
      return;
    }
    postMessage(appProps.parentDomain, getMessageByType(MessageStatus.CANCELLED, replymessage), '');
    navigate(LINK_TO_PARENT_PATH);
  });

  const { values } = formik;
  useEffect(() => {
    if (values.subjectId || values.body || attachments.size) {
      setUpdate(true);
    } else setUpdate(false);
  }, [values.body, values.subjectId]);

  // Cancel button click action.
  const onCancelButtonClick = () => {
    if (dialogState.isUpdated) {
      showDialog();
    } else {
      postMessage(
        appProps.parentDomain,
        replymessage ? MessageTypes.MESSAGE_CENTER_REPLY_CANCELLED : MessageTypes.MESSAGE_CENTER_NEW_MESSAGE_CANCELLED,
        ''
      );
      navigate(LINK_TO_PARENT_PATH);
    }
  };

  return (
    <>
      {/* Top Message Bar */}
      {!replymessage && (
        <div className='title-bar c-notification__container'>
          <div className='c-notification  c-notification--info'>
            <div className='c-notification__outer-wrap'>
              <div className='c-notification__inner-wrap'>
                <div className='c-notification__content'>
                  <h1 className='c-notification__text'>{i18n.t(rk.NEW_MESSSAGE_TITLE_TEXT)}</h1>
                </div>
                <button
                  type='button'
                  className='c-notification__close'
                  onClick={onCancelButtonClick}
                  aria-label='close'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <form data-testid='new-message-form' className='new-message-form' onSubmit={formik.handleSubmit}>
        <fieldset className='o-fieldset'>
          {replymessage ? (
            ''
          ) : (
            <>
              <Error />
              <Select
                aria-label='subject'
                id='subjectId'
                name='subjectId'
                testId='subjectId'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subjectId}
                error={hasErrorByField('subjectId', formik.touched, formik.errors)}
              >
                <Select.Item disabled value='' key='default'>
                  {i18n.t(rk.NEW_MESSSAGE_SUBJECT_DEFAULT_ITEM_TEXT)}
                </Select.Item>
                {subjectLines?.map(({ id, description }) => (
                  <Select.Item key={id.toString()} value={id.toString()}>
                    {description}
                  </Select.Item>
                ))}
              </Select>

              {hasErrorByField('subjectId', formik.touched, formik.errors) && (
                <p className='c-error-message'>{formik.errors.subjectId}</p>
              )}
            </>
          )}
          <WordCountTextArea
            id='body'
            data-testid='body'
            characterLimit={5000}
            placeholder={replymessage ? i18n.t(rk.REPLY_TEXT) : placeholderText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.body}
            hasError={hasErrorByField('body', formik.touched, formik.errors)}
          >
            <UploadFile
              id='file'
              onChange={onChangeFiles}
              onDelete={onDeleteFiles}
              brand={appProps?.brand?.toLowerCase()}
              malwareDetected={malwareDetected}
              setMalwareDetected={setMalwareDetected}
            />
          </WordCountTextArea>
          {hasErrorByField('body', formik.touched, formik.errors) && (
            <p className='c-error-message'>{formik.errors.body}</p>
          )}

          {/* Checkbox */}
          {!countryCanada ? (
            <div className='o-fieldset__row checkbox'>
              <div className='c-form-field'>
                <div className='c-form-field__box'>
                  <div className='o-inline-group'>
                    <div className='o-inline-group__item'>
                      <label className='c-checkbox' htmlFor='eSignConsent'>
                        <input
                          data-testid='consent-agreement'
                          id='eSignConsent'
                          name='eSignConsent'
                          className='c-checkbox__input'
                          type='checkbox'
                          onChange={formik.handleChange}
                        />
                        <span
                          className={`c-checkbox__label ${
                            hasErrorByField('eSignConsent', formik.touched, formik.errors) ? 'u-color-red' : ''
                          }`}
                        >
                          {i18n.t(rk.ESIGN_AGREEMENT_TEXT_PART_ONE)}
                          <a href={eSignPDFURL} target='_blank' rel='noopener noreferrer'>
                            {i18n.t(rk.ESIGN_AGREEMENT_PDF_LINK)}
                          </a>
                          {i18n.t(rk.ESIGN_AGREEMENT_TEXT_PART_TWO)}
                          <b>{i18n.t(rk.ESIGN_AGREEMENT_TEXT_NOTE)}</b>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {hasErrorByField('eSignConsent', formik.touched, formik.errors) && (
                <p className='c-error-message'>{formik.errors.eSignConsent}</p>
              )}
            </div>
          ) : (
            ''
          )}
        </fieldset>

        {/* Buttons */}
        {/* TODO: Change for bronson-react button components */}
        <div className='o-fieldset new-message-buttons'>
          <div className='o-fieldset__row'>
            <div className='o-button-container  o-button-container--nav'>
              <button
                data-testid='send-message-cancel-button'
                className='c-btn c-btn--secondary o-button-container__button'
                type='button'
                onClick={onCancelButtonClick}
              >
                <span className='c-btn__text'>{i18n.t(rk.NEW_MESSAGE_CLOSE_BUTTON_TEXT)}</span>
              </button>
              <button
                type='submit'
                className='c-btn  o-button-container__button'
                disabled={sendButtonDisabled}
                aria-disabled={sendButtonDisabled}
              >
                <span className='c-btn__text'>{i18n.t(rk.NEW_MESSAGE_SEND_BUTTON_TEXT)}</span>
              </button>
            </div>
          </div>
        </div>

        <Modal
          modalHidden={dialogState.isHidden}
          modalClosed={modalClosed}
          modalExit={modalExit}
          modalIcon={modalIcon}
          title={i18n.t(rk.NEW_MESSAGE_MODAL_TITLE)}
          content={i18n.t(rk.NEW_MESSAGE_MODAL_CONTENT)}
          cancelButtonText={i18n.t(rk.NEW_MESSAGE_MODAL_CANCEL_BUTTON_TEXT)}
          okButtonText={i18n.t(rk.NEW_MESSAGE_MODAL_OK_BUTTON_TEXT)}
        />
      </form>
    </>
  );
};

export default MessageTextArea;
